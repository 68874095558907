import Constant from "../constants"

const initialState = {
    newUserDetails: {},
    newUseError: {
        ERROR: "",
    },
    userShouldRedirect: false,
    registering: false,
    privacyPolicySection: [],
}
export default function SignupReducer(state = initialState, action) {
    switch (action.type) {
    case Constant.FETCH_SIGNUP_REQUEST:
        return {
            ...state,
            userShouldRedirect: false,
            registering: true,
        }
    case Constant.FETCH_SIGNUP_SUCCESS:
        return {
            ...state,
            newUserDetails: action.payload,
            userShouldRedirect: true,
            newUseError: {
                ERROR: "",
            },
            registering: false,
        }
    case Constant.FETCH_SIGNUP_ERROR:
        return {
            ...state,
            newUseError: { ERROR: action.payload },
            userShouldRedirect: false,
            registering: false,
        }
    case Constant.FETCH_PRIVACY_POLICY_SUCCESS:
        return {
            ...state,
            privacyPolicySection: action.payload,
        }
    case Constant.CLEAR_SIGN_UP_DATA:
        return {
            ...state,
            newUserDetails: {},
            newUseError: {
                ERROR: "",
            },
            userShouldRedirect: false,
            registering: false,
        }
    default:
        return state
    }
}
