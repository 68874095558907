import { addCookie } from "../../common/tool/util"
import Constant from "../constants"
import { get } from "../../common/api"

export const getStoreData = () => dispatch => {
    dispatch({
        type: Constant.GET_STOREDATA_REQUEST,
    })
    return get("/common/getStoreData", { isBrowserCache: true })
        .then(data => {
            if (data.ERROR) {
                dispatch({
                    type: Constant.GET_CART_ERROR,
                    payload: data,
                })
            } else {
                dispatch({
                    type: Constant.GET_STOREDATA_SUCCESS,
                    payload: data,
                })
            }
        })
        .catch(err => {
            console.log(err)
        })
}
export const setTransition = payload => dispatch => dispatch({
    type: Constant.SET_TRANSITION,
    payload,
})
export const setCurrency = payload => dispatch => {
    dispatch({
        type: Constant.SET_CURRENCY,
        payload,
    })
}
export const setCurrencyCookie = (payload, callback) => dispatch => {
    dispatch({
        type: Constant.SET_CURRENCY_COOKIE,
        payload,
    })
    get(`/site/changeCurrency?currency=${payload}`)
        .then(data => {
            if (!data.ERROR) {
                addCookie("currency", payload, 24 * 60 * 60 * 1000, false)
                callback()
            } else {
                alert(data.ERROR)
            }
        })
        .catch(err => {
            console.log(err)
        })
}

/**
* Tracking FB Pixel
* submit data to FB Pixel
*
* @export
* @param {Object} payload
* eg:
*   {
*        content_ids: [""],
*        content_type: "",
*        value: 0,
*   }
* @param {array} content_ids
* @param {String} content_type
* @param {Number} value
*/
export const trackFBPixel = payload => (dispatch, getState) => {
    dispatch({
        type: Constant.TRACK_FBPIXEL,
    })
    const currency = getState().CommonReducers.selectedCurrency.code
    if (typeof window !== "undefined") {
        window.fbq("track", payload.action, {
            content_ids: payload.contentIds,
            content_type: payload.contentType,
            value: payload.value,
            currency,
        })
    }
}

export default {
    getStoreData,
    setCurrency,
    setCurrencyCookie,
    trackFBPixel,
}
