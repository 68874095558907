const T = require("i18n-react").default

let envConfig
// select environment config
if (process.env.NODE_ENV === "production") {
    const cemConfig = require("./templatecem.json")
    if (Object.keys(cemConfig).length > 0) {
        envConfig = cemConfig
    } else {
        envConfig = require("./template.json")
    }
} else {
    envConfig = require("./local.json")
}
const configs = Object.assign(envConfig, { port: process.env.PORT || envConfig.port })

/**
 * Set the local text
 */
// eslint-disable-next-line no-undef
if (process.env.DEFINE_PLUGIN_LANGUAGE_ID === "2") {
    T.setTexts(require("./language/es.json"))
} else {
    T.setTexts(require("./language/en.json"))
}

module.exports = configs
