import Constant from "../constants"
import { get } from "../../common/api"

export const getMeta = (page = "") => dispatch => {
    dispatch({
        type: Constant.FETCH_META_DETAILS,
        payload: page,
    })
    let param = {}
    if (page !== "") {
        param = { page }
    }
    param.isBrowserCache = true
    return get("/common/getPageMetaInformation", param)
        .then(data => {
            dispatch({
                type: Constant.FETCH_META_DETAILS_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.error(err)
        })
}

/**
 * Get meta data for help page
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getMetaHelp = () => getMeta("help")

/**
 * Get meta data for home page
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getMetaHome = () => getMeta("home")

/**
 * Reset meta data
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const reset = safe => dispatch => {
    dispatch({ type: Constant.META_DETAILS_RESET, payload: { safe } })
}

export default {
    getMeta,
    reset,
}
