import Constant from "../constants"
import { get, getForwardHeadersFromContext } from "../../common/api"

const initialData = (url, currency = "", clientIP = "", ctx = null) => (dispatch, getState) => new Promise((resolve, reject) => {
    const initData = getState().InitData
    if (initData.isFetching) {
        dispatch({
            type: Constant.INIT_DATA_CANCELED,
        })
        return resolve()
    }
    if (initData && Object.keys(initData).length <= 1) { // because added 1 flag inside this object
        const params = {}
        if (currency) {
            params.currency = currency
        }
        if (clientIP) {
            // eslint-disable-next-line no-underscore-dangle
            params._REMOTE_ADDR = clientIP
        }
        const headers = getForwardHeadersFromContext(ctx)
        const baseRequests = [
            get("/product/getProductTypes", params, headers, ctx),
            get("/product/getProductActivityType", { ...params, desc: 1 }, headers),
            get("/common/getExchangeRates", { ...params, _version: 2 }, headers),
            get("/common/getConfigurations", { ...params, _version: 2 }, headers),
            // get("/common/getMainMenuDestinations", params, headers),
            get("/common/getStoreData", params),
        ]
        return Promise.all(baseRequests).then(data => {
            const query = url || (typeof window !== "undefined" && window.location.search)
            if (/ol=1/.test(query)) {
                data[3].algolia.status = false
            }
            const configurations = data[3] || ""
            if (
                configurations !== ""
                    && configurations.promocode_ribbon !== ""
            ) {
                // eslint-disable-next-line camelcase
                configurations.promocode_ribbon = JSON.parse(configurations.promocode_ribbon)
            } else {
                // eslint-disable-next-line camelcase
                configurations.promocode_ribbon = {}
            }
            const payload = {
                // check for default data types for following
                productTypes: data[0] || "",
                ParentProductActivity: data[1] || "",
                exchangeRates: data[2] || "",
                configurations,
            }
            dispatch({
                type: Constant.INIT_DATA,
                payload,
            })
            dispatch({
                type: Constant.SET_CURRENCY,
                payload: configurations.selected_currency,
            })
            // dispatch({
            //     type: Constant.MENU_DESTINATIONS_DATA_SUCCESS,
            //     payload: data[4],
            // })
            dispatch({
                type: Constant.GET_STOREDATA_SUCCESS,
                payload: data[4],
            })
            return resolve()
        }).catch(() => {
            const payload = {
                // check for default data types for following
                productTypes: [],
                ParentProductActivity: [],
                exchangeRates: {},
                configurations: {
                    algolia: {
                        status: false,
                    },
                },
            }
            dispatch({
                type: Constant.INIT_DATA,
                payload,
            })
            dispatch({
                type: Constant.SET_CURRENCY,
                payload: {
                    sign: "$ USD",
                    code: "USD",
                    symbol: "$",
                },
            })
            return reject()
        })
    }
    return resolve()
})

export default initialData
