// import { _remove, _reverse } from "../../common/tool/util"
import Constant from "../constants"
import { arrayAddUniqueRecent } from "../../common/tool/filter"

// recent serach
export function setRecentSearch(newVal) {
    let recentSearch
    return (dispatch, getState) => {
        if (newVal === null || newVal === "" || newVal === undefined) {
            dispatch({
                type: Constant.RECENT_SEARCH_ERROR,
            })
        }
        recentSearch = getState().SearchReducer.recentSearch
        recentSearch = arrayAddUniqueRecent(recentSearch, newVal)
        if (recentSearch.length > 5) {
            recentSearch.map((item, index) => {
                if (index > 4) {
                    recentSearch.splice(index, 1)
                }
            })
        }
        // console.log("recentSearch", recentSearch)
        // _remove(recentSearch, (n, i) => i >= 5)
        if (typeof localStorage !== "undefined") {
            localStorage.setItem("SearchData", JSON.stringify({ recentSearch }))
        }
        dispatch({
            type: Constant.RECENT_SEARCH_SUCCESS,
            payload: recentSearch,
        })
    }
}

export const getRecentSearch = () => {
    let recentSearch
    if (localStorage.getItem("SearchData") === null) {
        recentSearch = []
    } else {
        recentSearch = localStorage.getItem("SearchData")
        recentSearch = JSON.parse(recentSearch)
        recentSearch = recentSearch.recentSearch
    }
    return dispatch => dispatch({
        type: Constant.RECENT_SEARCH_SUCCESS,
        payload: recentSearch,
    })
}

const LocalstorageAction = {
    setRecentSearch,
    getRecentSearch,
}
export default LocalstorageAction
