import T from "i18n-react"
import local from "./local.json"
import template from "./template.json"
import templatecem from "./templatecem.json"


let envConfig

// select environment config
if (process.env.NODE_ENV === "production") {
    if (Object.keys(templatecem).length !== 0) {
        envConfig = templatecem
    } else {
        envConfig = template
    }
} else {
    envConfig = local
}
const configs = Object.assign(envConfig, {
    port: process.env.PORT || envConfig.port,
})


// FIXME: Following if block should be removed when merging to master
// it's some temporary fix to work on es site
if (
    parseInt(configs.language.id, 10) === 2
    && configs.env.prod === "false"
    && configs.env.qa === "true"
) {
    configs.google.tagManager.id = "GTM-NNFP5S"
}

/**
 * Export the local configuration as default
 */
export default configs

/**
 * Retrieve HTTP url from HTTPS api url
 *
 * @return string
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getMobileAPIHTTPUrl = () => configs.mobileAPI.url.replace(/^https:\/\//, "http://")

/**
 * Check deployed environment is production
 *
 * @return boolean
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
// eslint-disable-next-line no-undef
export const isProd = process.env.DEFINE_PLUGIN_ENV_PROD === "true" && process.env.DEFINE_PLUGIN_ENV_QA === "false"

/**
 * Check deployed environment is qa
 *
 * @return boolean
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
// eslint-disable-next-line no-undef
export const isQA = process.env.DEFINE_PLUGIN_ENV_PROD === "false" && process.env.DEFINE_PLUGIN_ENV_QA === "true"

/**
 * isT4F store deployed?
 *
 * @return boolean
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
// eslint-disable-next-line no-undef
export const isT4F = process.env.DEFINE_PLUGIN_LANGUAGE_ID === "1"

/**
 * isT4Fes store deployed?
 *
 * @return boolean
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
// eslint-disable-next-line no-undef
export const isT4Fes = process.env.DEFINE_PLUGIN_LANGUAGE_ID === "2"

/**
 * Set the local text
 */
// eslint-disable-next-line no-undef
if (process.env.DEFINE_PLUGIN_LANGUAGE_ID === "2") {
    T.setTexts(require("./language/es.json"))
} else {
    T.setTexts(require("./language/en.json"))
}

export const googleFontLink = "https://fonts.googleapis.com/css?family=Montserrat&display=swap"
export const blogLink = isT4Fes ? "https://blog.tours4fun.es" : "https://blog.tours4fun.com"
/**
 * Translate a text
 *
 * @param {string} key   Translation key
 * @param {string} parms Additional parameters
 *
 * @return {string}
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const t = (key, parms) => T.translate(key, parms)
export const EntityFilterGroup = {
    0: [0, 1, 3, 5, 6, 7, 10, 11],
    1: [1, 2, 3, 4, 5, 6, 7, 10, 11],
    2: [1, 2, 4, 3, 6, 7, 5],
    3: [9, 8, 12],
    4: [5, 6, 7, 10, 11],
    all: [0, 1, 2, 3, 4, 5, 6, 7, 10, 11],
    nearByMe: [13, 1, 2, 4, 3, 7, 0, 10, 11, 5, 6],
}

export const filterList = [{
    title: t("Activity Type"),
    objectVar: "prodcutTypes",
    dataKey: "name",
    keyValue: "product_type_id", // 0
    apiName: "product_type_name",
}, {
    title: t("Departure City"),
    objectVar: "startCities",
    dataKey: "name",
    keyValue: "tour_city_id", // 1
    apiName: "depart_from",
}, {
    title: t("End City"),
    objectVar: "endCities",
    dataKey: "name",
    keyValue: "tour_city_id", // 2
    apiName: "depart_end",
}, {
    title: t("Attractions"),
    objectVar: "attractions",
    dataKey: "name",
    keyValue: "seo_url", // 3
    apiName: "attractions",
}, {
    title: t("Durations"),
    objectVar: "duration",
    dataKey: "text",
    keyValue: "id", // 4
    apiName: "duration",
}, {
    title: t("Languages"),
    objectVar: "operatorLanguage",
    dataKey: "name",
    keyValue: "id", // 5
    apiName: "operate_languages",
}, {
    title: t("Special Offer"),
    objectVar: "promotions",
    dataKey: "text",
    keyValue: "", // 6
    apiName: "sale",
}, {
    title: t("Price"),
    objectVar: "pricing",
    dataKey: "text",
    keyValue: "id", // 7
    apiName: "price",
}, {
    title: t("Most booked areas"),
    objectVar: "locations",
    dataKey: "name",
    keyValue: "id", // 8
    apiName: "hotel_location_name",
}, {
    title: t("Stars"),
    objectVar: "star",
    dataKey: "value",
    keyValue: "value", // 9
    apiName: "star",
}, {
    title: t("Departure Months"),
    objectVar: "operationMonths",
    dataKey: "name",
    keyValue: "tour_city_id", // 10
    apiName: "departure",
}, {
    title: t("Departure Date"),
    objectVar: "operationDate",
    dataKey: "",
    keyValue: "", // 11
    apiName: "departure",
}, {
    title: t("Hotel Name"),
    objectVar: "hotel_name",
    dataKey: "",
    keyValue: "", // 12
    apiName: "hotel_name",
}, {
    title: t("Categories"),
    objectVar: "product_entity_type",
    dataKey: "",
    keyValue: "", // 13
    apiName: "product_entity_type",
}]
