import { asyncScriptLoad } from "./util"

/* eslint-disable no-underscore-dangle,no-multi-assign */
if (typeof window !== "undefined") {
    window._sift = window._sift || []
}

/**
 * Load siftscience assets
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export function load() {
    asyncScriptLoad("https://cdn.siftscience.com/s.js", "sift-science")
}

/**
 * Set user ID for the session
 *
 * @param {string} email user email
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export function setUserId(email) {
    if (email && email !== "" && !email.match("@tours4fun")) {
        window._sift.push(["_setUserId", email.toLowerCase()])
    }
}
/**
 * Set session ID
 *
 * @param {string} account   Siftscience account id
 * @param {string} sessionId Session ID
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export function setSessionId(account, sessionId) {
    window._sift.push(["_setAccount", account])
    window._sift.push(["_setSessionId", sessionId])
}

/**
 * Track page view
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export function trackPageview() {
    window._sift.push(["_trackPageview"])
}
