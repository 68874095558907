import Constant from "../constants"

export default function HomeReducer(state = {
    banners: [],
    popularDestinations: [],
    recentData: [],
    promotions: null,
    experienceData: [],
}, action) {
    switch (action.type) {
    case Constant.FETCH_HOME_SUCCESS:
        return {
            ...state,
            banners: action.payload[0],
            popularDestinations: action.payload[1],
        }
    case Constant.FETCH_HOME_ERROR:
        return {
            ...state,
            error: true,
        }
    case Constant.FETCH_HOME_BANNERS_SUCCESS:
        return {
            ...state,
            banners: action.payload,
        }
    case Constant.FETCH_HOME_EXPERIENCE_DATA_SUCCESS:
        return {
            ...state,
            experienceData: action.payload,
        }
    case Constant.FETCH_HOME_PROMOTIONS_SUCCESS:
        return {
            ...state,
            promotions: action.payload,
        }
    case Constant.FETCH_HOME_POPULAR_DESTINATIONS_SUCCESS:
        return {
            ...state,
            popularDestinations: action.payload,
        }
    case Constant.FETCH_HOME_RECENTLY_VIEWED_SUCCESS:
        return {
            ...state,
            recentData: action.payload,
        }
    default:
        return state
    }
}
