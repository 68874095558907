// common

// home page
export const getHomeBanners = {
    path: "/common/getHomeBanners",
    params: { _v: 4, _img_size: 300 },
}

export const getPopularDestinations = {
    path: "/tourCity/getPopularDestinations",
    params: { _v: 1 },
}

export const getPromotionBanners = {
    path: "/common/getPromotionBanners",
    params: { size: "325x75" },
}

export const getNewsletterSubscription = {
    path: "/newsletter/subscribe",
}
// product
export const getProductInfo = {
    path: "/product/getProductInfo",
    params: {},
}

export const getProductTypes = {
    path: "/product/getProductTypes",
    params: {},
}

export const getProductActivityType = {
    path: "/product/getProductActivityType?desc=1",
    params: {},
}

export const getExchangeRates = {
    path: "/common/getExchangeRates",
    params: { _version: 2 },
}
export const getExperienceTourTags = {
    path: "/tourTag/get",
    params: {
        category: "experience",
        isBrowserCache: true,
    },
}

export const getConfigurations = {
    path: "/common/getConfigurations",
    params: {
        _version: 2,
    },
}

// easyBook
export const getStartDatePicker = {
    path: "/easyBookNew/getStartDatePicker",
    params: {
        _version: 2,
    },
}

export const getMainMenuDestinations = {
    path: "/common/getMainMenuDestinations",
    params: {},
}
