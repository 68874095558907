import loadable from "@loadable/component"

export const Header = loadable(() => import("./header"))
export const Footer = loadable(() => import("./footer"))
export const Banner = loadable(() => import("./banner"))
export const Search = loadable(() => import("./search"))
export const Menu = loadable(() => import("./menu"))
export const ToursItem = loadable(() => import("./tour-item"))
export const Upward = loadable(() => import("./upward-arrow"))
export const ShareToggle = loadable(() => import("./share-toggle"))
export const NotFound = loadable(() => import("./not-found"))
export const SmallGroup = loadable(() => import("./small-group-popup"))
export const InstantPopup = loadable(() => import("./instant-popup"))
export const ProductStar = loadable(() => import("./product-star"))
export const TourSlider = loadable(() => import("./tour-slider"))
export const CartComponent = loadable(() => import("./cart-component"))
export const HeadSEOTags = loadable(() => import("./HeadSEOTags"))
