const configs = require("../../../config/require")

if (process.env.NODE_ENV === "production") {
    if (configs.env.prod === "true" && configs.env.qa === "false") {
        module.exports = require("./store.live")
    } else {
        module.exports = require("./store.prod")
    }
} else {
    module.exports = require("./store.dev")
}
