import Constant from "../constants"

const initialState = {
    MetaPage: "",
    MetaData: {
        title: "",
        description: "",
        keywords: "",
    },
    FilterData: [],
    PopularDestinations: [],
    MetaError: {
        ERROR: "",
    },
    SSR: false, // this flag is to avoid reset SSR meta data at client side on load
}
export default function MetaReducers(state = initialState, action) {
    switch (action.type) {
    case Constant.FETCH_META_DETAILS:
        return {
            ...state,
            MetaPage: action.payload,
        }
    case Constant.FETCH_META_DETAILS_SUCCESS: {
        return {
            ...state,
            MetaError: { ERROR: "" },
            MetaData: action.payload,
            FilterData: action.filterData,
            PopularDestinations: action.popularDestinations,
            SSR: typeof window === "undefined",
        }
    }
    case Constant.FETCH_META_DETAILS_ERROR:
        return {
            ...state,
            MetaError: action.payload,
        }
    case Constant.META_DETAILS_RESET: {
        if (action.payload && action.payload.safe && state.SSR) {
            return {
                ...state,
                SSR: false,
            }
        }
        return { ...initialState }
    }
    default:
        return state
    }
}
