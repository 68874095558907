import React from "react"
import { connect } from "react-redux"
/**
 * Use Router, Because history.listen doesn't fire correctly for BrowserRouter
 * @see https://stackoverflow.com/a/44096163/625144
 */
import {
    Router,
    Switch,
    Route,
} from "react-router-dom"
import {
    init as sentryInit,
    captureException as sentryCaptureException,
} from "@sentry/browser"
import routes from "../../config/routes"
import { setTransition, setCurrency } from "../redux/actions/CommonActions"
import {
    setFilterAccordingUrl,
    setFilter,
    searchToursByKeyword,
    searchToursNearBy,
} from "../redux/actions/ToursActions"
import { initLogin } from "../redux/actions/LoginActions"
import initialData from "../redux/actions/InitActions"
import {
    debounce,
    _startsWith,
    _trim,
    _isEmpty,
} from "../common/tool/util"
import { mapOldPathToNew } from "../common/tool/Formatter"
import config, { isProd } from "../../config"
import { setUserId } from "../common/tool/siftscience"
import "./router.less"

let restrictedAttribute = ""

/**
 * searchToursDebounce is for getting data according url
 * @param {[Object]} props is Component's properties
 */

const searchToursDebounce = debounce(props => {
    let isSameUrl = false
    if (typeof window !== "undefined"
    && typeof window.lastLocation !== "undefined"
    && window.lastLocation.length > 2) {
        const lastLocation = window.lastLocation.length - 1
        if (
            !(
                _startsWith(window.lastLocation[lastLocation - 1], "/popular")
                || _startsWith(window.lastLocation[lastLocation - 1], "/tours")
            )
        ) {
            isSameUrl = _trim(window.lastLocation[lastLocation - 2], "/") === _trim(window.lastLocation[lastLocation], "/")
            const lastTwoUrls = []
            for (let i = window.lastLocation.length - 1; i >= 0; i -= 1) {
                if ((
                    _startsWith(window.lastLocation[i], "/tours")
                    || _startsWith(window.lastLocation[i], "/popular")
                ) && (
                    lastTwoUrls.length < 2
                )) {
                    lastTwoUrls.push(_trim(window.lastLocation[i], "/"))
                }
            }
            /**
             * checked with last 2 urls because when user visit listing page and
             * go on product details or any thing else and when it back it will prevent
             * api calling again
             */
            if (
                !isSameUrl && lastTwoUrls.length > 1
                && lastTwoUrls[0] === lastTwoUrls[1]
            ) {
                isSameUrl = true
            }
        }
    }
    if (!isSameUrl) {
        props.setFilterAccordingUrl(window.location.pathname, filter => {
            props.setFilter(filter)
            if (_startsWith(window.location.pathname, "/popular")) {
                restrictedAttribute = "tour_tag"
            }
            if (_startsWith(window.location.pathname, "/tours/near-me")) {
                props.searchToursNearBy()
            } else {
                props.searchToursByKeyword(restrictedAttribute, window.location.pathname)
            }
        })
    }
}, 500)

class ClientRoute extends React.Component {
    constructor(props) {
        super(props)
        // why this bind? @see https://reactjs.org/docs/handling-events.html
        this.popstateEventListener = this.popstateEventListener.bind(this)
    }

    componentDidMount() {
        if (typeof window !== "undefined" && isProd) {
            const sentryConfig = {
                dsn: config.sentry.dsn,
                environment: "production",
                sampleRate: 0.5,
            }
            if (!_isEmpty(config.release)) {
                sentryConfig.release = "m-t4f@" + config.release
            }
            sentryInit(sentryConfig)
        }
        window.addEventListener("popstate", this.popstateEventListener)
        this.props.initLogin()
        this.props.initialData()

        // patch for old site's paths to new
        if (window.location.hash.startsWith("#!/")) {
            const hashPath = window.location.hash.replace(/^#!/, "")
            mapOldPathToNew(hashPath)
                .then(newPath => {
                    this.props.browserHistory.replace(newPath)
                })
                .catch(() => {})
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.authenticated !== this.props.loginData.authenticated
            && this.props.loginData.authenticated === true
        ) {
            // trigger events for login
            setUserId(this.props.loginData.userDetails.email)
        }
    }

    componentWillUnmount() {
        window.removeEventListener("popstate", this.popstateEventListener)
    }

    componentDidCatch(error) {
        // send errors to Sentry.io
        const id = sentryCaptureException(error)
        console.log("Error ID", id)
    }

    popstateEventListener() {
        this.props.setTransition("normal")
        if (
            window.location.pathname.startsWith("/tours/")
            || window.location.pathname.startsWith("/popular/")
        ) {
            searchToursDebounce(this.props)
        }
    }

    render() {
        return (
            <Router history={this.props.browserHistory}>
                <Switch>
                    {
                        routes.map((route, k) => (
                            <Route
                                location={location}
                                key={`${location.key}_${k}`}
                                {...route}
                            />
                        ))
                    }
                </Switch>
            </Router>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.InitData,
        ...state.CommonReducers,
        loginData: state.LoginReducer,
        initData: state.InitData,
        transitionName: state.CommonReducers.transitionName,
    }
}
export default connect(
    mapStateToProps,
    {
        setTransition,
        setCurrency,
        setFilterAccordingUrl,
        setFilter,
        searchToursByKeyword,
        searchToursNearBy,
        initLogin,
        initialData,
    },
)(ClientRoute)
