import Constant from "../constants"
import { get } from "../../common/api"
import getWithAbort, { postWithAbort, abortPendingApi } from "../../common/api/extraFunction"
import {
    offlineRetrive,
    addToBookingStore,
    _isEmpty,
    addDaysToDate,
    getLastDateKey,
    offlineStore,
} from "../../common/tool/util"
import { stringToDate, dateToString } from "../../common/tool/dateUtils"
import { getStartDatePicker } from "../../../config/requests"
import { trackFBPixel } from "./CommonActions"
import { t } from "../../../config"

/**
 * Execute custome error from clientside
 *
 * @param {string} message error message
 *
 * @author Pratik B<pratikb.bipl@gmail.com>
 */

export const easyBookException = message => {
    abortPendingApi()
    return (
        dispatch => dispatch({
            type: Constant.EASY_BOOK_PRICE_UPDATE,
            payload: {
                ERROR: message,
            },
        })
    )
}

// let isProfileUpdate = true
const addToCart = (params, dispatch, getState, bookData) => {
    if (bookData && bookData.noUpdated) {
        const type = Constant.EASY_BOOK_SUCCESS
        const payload = {}
        payload.easyBookData = bookData
        payload.easyBookData.noUpdated = false
        dispatch({
            type,
            payload,
        })
        return false
    }
    const fetchType = params.calculate_only && params.calculate_only === 1
        ? Constant.EASY_BOOK_PRICE_CALCULATING
        : Constant.EASY_BOOK_PRICE_FETCHING
    dispatch({
        type: fetchType,
        payload: {
            calculate_only: params.calculate_only ? params.calculate_only : 0,
        },
    })
    const attributesOption = {}
    const sessionData = offlineRetrive("bookingOption", true)
    const retrivedData = sessionData && sessionData[params.product_id]
    const easyBookDataPicked = retrivedData || (bookData && bookData.data.picked)
    const pickedKeys = Object.keys(easyBookDataPicked)
    const productType = bookData
        ? bookData.data.product_type
        : getState().ProductReducers.easyBookData.data.product_type

    if (easyBookDataPicked.attribute_203) {
        if (easyBookDataPicked.attribute_203[0] === "859") {
            easyBookDataPicked.attribute_trigger_date_key = "ticket_pick_up_date"
            easyBookDataPicked.attribute_trigger_date_key_name = "ticket_pick_up_date"
        } else if (
            easyBookDataPicked.attribute_203[0] === "860"
            || easyBookDataPicked.attribute_203[0] === "21107"
        ) {
            easyBookDataPicked.attribute_trigger_date_key = "priority_mail_ticket_needed_date"
            easyBookDataPicked.attribute_trigger_date_key_name = "priority_mail_ticket_needed_date"
        }
    }
    if (params.start_date && retrivedData && params.start_date !== retrivedData.start_date) {
        const dateTrigger = retrivedData.transportation_date_trigger
        retrivedData.start_date = params.start_date
        if (dateTrigger && stringToDate(params.start_date) >= stringToDate(dateTrigger[0])) {
            retrivedData.transportation_date_trigger_name = [t("Please select pick up date")]
            delete retrivedData.transportation_date_trigger
        }
        addToBookingStore("bookingOption", retrivedData)
    }

    pickedKeys.forEach(k => {
        if (k.indexOf("attribute") > -1 && k.indexOf("_name") === -1) {
            attributesOption[k.replace("attribute", "id")] = easyBookDataPicked[k]
        }
    })

    const bookInfos = {
        products_id: params.product_id,
        availabletourdate: easyBookDataPicked.start_date + "::##!!!",
        room_total: easyBookDataPicked.price_options ? 0 : easyBookDataPicked.room_total,
        room_adult_total: easyBookDataPicked.room_adult_total,
        room_child_total: easyBookDataPicked.room_child_total,
        room_type: easyBookDataPicked.room_type === "roomPicker"
            ? ""
            : easyBookDataPicked.room_type,
        attributes_option: attributesOption,
        h_e_id: easyBookDataPicked.h_e_id,
        hotel_pick_up_location: easyBookDataPicked.hotel_pick_up_location,
        pick_up_location: Array.isArray(easyBookDataPicked.location)
            ? easyBookDataPicked.location[0]
            : easyBookDataPicked.location,
        show_time: easyBookDataPicked.show_time,
        show_time_id: easyBookDataPicked.show_time_id,
        price_options: easyBookDataPicked.price_options,
        checkout_date: easyBookDataPicked.checkout_date,
    }

    Object.keys(easyBookDataPicked).map(Attrkey => {
        if (/^booking_option_.+$/.test(Attrkey)) {
            if (!bookInfos.booking_options) {
                bookInfos.booking_options = {}
            }
            const regxp = new RegExp("booking_option_(.+)")
            const matches = regxp.exec(Attrkey)
            if (matches && matches.length > 1) {
                const tmpKey = matches[1]
                bookInfos.booking_options[tmpKey] = easyBookDataPicked[Attrkey]
            }
        }
    })

    if (easyBookDataPicked.location_departure_time_name) {
        bookInfos.papillo_dept_time = easyBookDataPicked.location_departure_time_name[0]
    }
    // transportation product
    if (Number(productType) === 4) {
        bookInfos.round_trip = easyBookDataPicked.transportation
        if (Number(easyBookDataPicked.transportation)
            && easyBookDataPicked.transportation_date_trigger
        ) {
            bookInfos.availabletourdatereturn = easyBookDataPicked.transportation_date_trigger[0]
            if (easyBookDataPicked.return_drop_off_location) {
                bookInfos
                    .return_papillo_dropoff_loca = easyBookDataPicked.return_drop_off_location.text
            }
            if (easyBookDataPicked.return_pick_up_location) {
                bookInfos.return_papillo_dept_loca = easyBookDataPicked.return_pick_up_location.text
            }
        } else {
            bookInfos.round_trip = "0"
        }
    }
    // meeting point inupt from user
    if (easyBookDataPicked.papillo_dept_loca) {
        bookInfos.papillo_dept_loca = easyBookDataPicked.papillo_dept_loca
        bookInfos.papillo_dept_loca_input = "1"
    }
    // return meeting point input form user - @FIX_ME when ttd merge need to remove duplicate code
    if (!_isEmpty(easyBookDataPicked.return_pick_up_location)) {
        if (
            !Array.isArray(easyBookDataPicked.return_pick_up_location)
            && typeof easyBookDataPicked.return_pick_up_location === "string"
        ) {
            bookInfos.return_papillo_dept_loca = easyBookDataPicked.return_pick_up_location
        } else if (Array.isArray(easyBookDataPicked.return_pick_up_location)) {
            bookInfos.return_pick_up_location = easyBookDataPicked.return_pick_up_location[0]
        } else if (typeof easyBookDataPicked.return_pick_up_location === "object") {
            bookInfos.return_pick_up_location = easyBookDataPicked.return_pick_up_location.id
        }
    }
    if (typeof easyBookDataPicked.hotel_pickup !== "undefined" && easyBookDataPicked.hotel_pickup) {
        if (parseInt(easyBookDataPicked.hotel_pickup[0], 10) === 1) {
            bookInfos.papillo_dept_loca = ""
        }
    }
    if (easyBookDataPicked.hotel_pickup_name) {
        bookInfos.hotel_pickup_name = easyBookDataPicked.hotel_pickup_name[0]
    }
    // add drop off
    if (easyBookDataPicked.location_drop_off) {
        bookInfos.papillo_dropoff_loca = easyBookDataPicked.location_drop_off.text
    }
    // add pickup
    if (easyBookDataPicked.location_pick_up) {
        let papilloDeptLoca = easyBookDataPicked.location_pick_up.text
        if (typeof easyBookDataPicked.location_pick_up.id !== "undefined" && easyBookDataPicked.location_pick_up.id) {
            papilloDeptLoca = easyBookDataPicked.location_pick_up.id
        }
        bookInfos.papillo_dept_loca = papilloDeptLoca
    }
    // add duration
    if (easyBookDataPicked.duration) {
        bookInfos.duration = easyBookDataPicked.duration.id
    }
    // delivery type
    const attrdatekey = easyBookDataPicked.attribute_trigger_date_key
    if (attrdatekey && easyBookDataPicked.ticket_pick_up_date) {
        bookInfos[attrdatekey] = easyBookDataPicked.ticket_pick_up_date
    }
    const currency = getState().CommonReducers.selectedCurrency.code
    let url = `/cart/addCompleteCart?_v=2&auto_correct=1&currency=${currency}&product_id=${bookInfos.products_id}`
    if (params.calculate_only) {
        url += "&calculate_only=1"
    }
    if (bookData.data.isTTDProduct) {
        if (easyBookDataPicked.duration) {
            bookInfos.duration = easyBookDataPicked.duration
        }
        if (easyBookDataPicked.duration_type) {
            bookInfos.duration_type = easyBookDataPicked.duration_type
        }
        if (easyBookDataPicked.total_sku && !_isEmpty(easyBookDataPicked.total_sku)) {
            bookInfos.total_sku = easyBookDataPicked.total_sku
        }
        if (!_isEmpty(easyBookDataPicked.return_pick_up_location)) {
            if (
                !Array.isArray(easyBookDataPicked.return_pick_up_location)
                && typeof easyBookDataPicked.return_pick_up_location === "string"
            ) {
                bookInfos.return_papillo_dept_loca = easyBookDataPicked.return_pick_up_location
            } else {
                bookInfos.return_pick_up_location = easyBookDataPicked.return_pick_up_location[0]
            }
        }
        if (typeof bookInfos.total_sku === "undefined" || _isEmpty(bookInfos.total_sku)) {
            return easyBookException("REQUIRED_OPTION_NOT_SELECTED")
        }
    }
    const addCompleteCart = () => postWithAbort(
        url,
        {},
        { bookInfos: encodeURIComponent(JSON.stringify([bookInfos])) },
    ).then(data => {
        if (data.token) {
            const auth = offlineRetrive("auth")
            if (auth.accessToken !== data.token) {
                auth.accessToken = data.token
                offlineStore("auth", auth)
            }
        }
        let type = Constant.EASY_BOOK_PRICE_UPDATE
        const payload = {
            productPrice: data,
        }
        if (!data.ERROR) {
            const contentIds = []
            data.all_products.map(item => contentIds.push(item.product_id))
            const value = data.total_amount

            // skip price calculation as addToCart
            if (params.calculate_only !== 1) {
                dispatch(trackFBPixel({
                    action: "AddToCart",
                    contentIds,
                    contentType: "product",
                    value,
                }))
            }
        }
        if (bookData) {
            type = Constant.EASY_BOOK_SUCCESS
            payload.easyBookData = bookData
            payload.easyBookData.noUpdated = false
        }
        if (typeof data.corrections !== "undefined" && !_isEmpty(data.corrections)) {
            // const bookingOption = offlineRetrive("bookingOption", true)[bookInfos.products_id]
            let correctData
            let changedOption
            let attributes
            Object.keys(data.corrections).map(key => {
                changedOption = data.corrections[key]
                if (key === "attributes_option") {
                    Object.keys(changedOption).map(akey => {
                        attributes = changedOption[akey]
                        correctData = akey.split("_")
                        if (correctData.length > 1) {
                            payload.easyBookData.data.picked["attribute_" + correctData[1]] = attributes.fix
                            payload.easyBookData.data.picked["attribute_" + correctData[1] + "_name"] = []
                            if (attributes.fixedObject) {
                                attributes.fixedObject.map(eachAttr => {
                                    if (eachAttr) {
                                        payload.easyBookData.data.picked[
                                            "attribute_" + correctData[1] + "_name"
                                        ].push(eachAttr.text)
                                    } else {
                                        payload.easyBookData.data.picked[
                                            "attribute_" + correctData[1] + "_name"
                                        ] = []
                                    }
                                })
                            }
                        }
                    })
                } else if (key === "booking_option") {
                    Object.keys(changedOption).map(akey => {
                        if (
                            payload.easyBookData.data.picked["booking_option_" + akey]
                            && typeof payload.easyBookData.data.picked["booking_option_" + akey].length === "undefined"
                        ) {
                            payload.easyBookData.data.picked["booking_option_" + akey] = changedOption[akey].fixedObject[0]
                        } else {
                            payload.easyBookData.data.picked["booking_option_" + akey] = changedOption[akey].fixedObject
                        }
                    })
                }
            })
            addToBookingStore("bookingOption", payload.easyBookData.data.picked)
        }
        if (retrivedData) {
            payload.easyBookData.data.picked.start_date = retrivedData.start_date
        }
        dispatch({
            type,
            payload,
        })
        // only update  profile once
        // if (isProfileUpdate) {
        //     isProfileUpdate = false
        //     dispatch(getProfile())
        // }
    }).catch(console.log)
    // dispatch(reInitSession())
    return addCompleteCart()
}
/**
 * update price while selected date or any option changed
 *
 * @author Tony He <tonys9204@gmail.com>
 * @param {object} params include product_id and start_date
 * @returns {none} no returns
 */
export const updateEasyBookPrice = (params, picked) => (dispatch, getState) => {
    // if (params.start_date) {
    //     dispatch({
    //         type: Constant.EASY_BOOK,
    //     })
    // }
    const bookData = getState().BookingReducers.easyBookData
    Object.assign(bookData.data.picked, picked)
    return addToCart(params, dispatch, getState, bookData)
}

/**
 * fetch easybook tour options
 *
 * @author Tony He <tonys9204@gmail.com>
 * @param {object} params include product_id, tran_id, tran_option_id or start_date
 * @param {boolean} tickets ticket product indicator
 * @returns {function}
 * if there's start_date in prameter, will return addToCart function,
 * else return action EASY_BOOK_SUCCESS
 */
export const easyBook = (params, tickets = false, isShowLoader = true) => (dispatch, getState) => {
    if (isShowLoader && (params.start_date || tickets)) {
        dispatch({
            type: Constant.EASY_BOOK,
        })
    }
    const stateObj = getState()
    let isNeedToCleanPriceOption = false
    const retriveData = offlineRetrive("bookingOption", true)
    if (retriveData && !_isEmpty(retriveData[params.product_id])) {
        const totalGuest = {}
        if (!_isEmpty(retriveData[params.product_id].price_options)) {
            retriveData[params.product_id].price_options.map(item => {
                totalGuest[item.id] = item.value
            })
        } else {
            if (retriveData[params.product_id].room_adult_total) {
                totalGuest.ADULT_RATE = 0
                Object.keys(retriveData[params.product_id].room_adult_total).map(item => {
                    totalGuest.ADULT_RATE
                        += parseInt(
                            retriveData[params.product_id].room_adult_total[item],
                            10,
                        )
                })
            }
            if (retriveData[params.product_id].room_child_total) {
                totalGuest.CHILD_RATE = 0
                Object.keys(retriveData[params.product_id].room_child_total).map(item => {
                    totalGuest.CHILD_RATE
                        += parseInt(
                            retriveData[params.product_id].room_child_total[item],
                            10,
                        )
                })
            }
        }
        params.travelers = JSON.stringify(totalGuest)
        if (!_isEmpty(stateObj.ProductReducers.product.startDate.isRezb2bFareharbor)) {
            if (retriveData[params.product_id].show_time_id) {
                params.start_time = retriveData[params.product_id].show_time_id
            }
            if (retriveData[params.product_id].start_date !== params.start_date) {
                delete params.start_time
                delete params.travelers
                isNeedToCleanPriceOption = true
            }
            if (/Departure:|Start\stime/.test(retriveData[params.product_id].lastUpdate)) {
                delete params.travelers
                isNeedToCleanPriceOption = true
            }
        }
    }
    const currency = stateObj.CommonReducers.selectedCurrency.code
    getWithAbort(
        "/easyBookNew/getPickerList",
        {
            ...params,
            _v: 3,
            currency,
        },
    ).then(data => {
        if (!data.ok) {
            const type = Constant.EASY_BOOK_UPDATE
            return dispatch({
                type,
                payload: { ok: false },
            })
        }
        const bookData = data
        const tempData = data.data.pickerList
        const adaptData = {}
        tempData.forEach(item => {
            adaptData[item.id] = item
            if (item.name === "Tickets" || item.name === "Entradas:") {
                bookData.data.picked.room_total = 0
            }
        })
        bookData.data.pickerList = adaptData
        const { picked } = bookData.data
        picked.product_id = params.product_id
        let existStorage = retriveData && retriveData[picked.product_id]
        if (
            existStorage
            && existStorage !== null
            && existStorage !== undefined
            && existStorage.start_date !== bookData.data.picked.start_date
        ) {
            // when refresh needed it will clean old date's data for clean price of addToCart
            existStorage = null
        }
        if (isNeedToCleanPriceOption) {
            if (existStorage) {
                delete existStorage.price_options
            }
        }
        // if (
        //     !existStorage
        //     || adaptData.price_options
        // ) {
        const tempPicked = {}
        if (Number(data.data.product_type) === 4) {
            const uselData = _isEmpty(existStorage) ? picked : existStorage
            // tempPicked.return_drop_off_location = _isEmpty(uselData.return_drop_off_location)
            //     ? {
            //         id: "",
            //         text: "Please select return hotel/drop off location",
            //     }
            //     : uselData.return_drop_off_location
            // tempPicked.return_pick_up_location = _isEmpty(uselData.return_pick_up_location)
            //     ? {
            //         id: "",
            //         text: "Please select return pick up loaction",
            //     } : uselData.return_pick_up_location
            if (!_isEmpty(uselData.attribute_transportation_date_trigger)) {
                tempPicked
                    .attribute_transportation_date_trigger = uselData
                        .attribute_transportation_date_trigger
                tempPicked
                    .attribute_transportation_date_trigger_name = uselData
                        .attribute_transportation_date_trigger
                tempPicked
                    .transportation_date_trigger = uselData
                        .attribute_transportation_date_trigger
            }
            /*
            tempPicked = {
                return_drop_off_location: {
                    id: "",
                    text: "Please select return hotel/drop off location",
                },
                return_pick_up_location: {
                    id: "",
                    text: "Please select return pick up loaction",
                },
                transportation_date_trigger_name: ["Please select pick up date"],
            }
            */
        }
        if (adaptData.ticket_pick_up_date) {
            const currentDate = stringToDate(picked.start_date)
            let pickUpDate = dateToString(new Date(currentDate.getTime() - 86400000))
            if (existStorage && !_isEmpty(existStorage.ticket_pick_up_date)) {
                const selectedDate = stringToDate(existStorage.ticket_pick_up_date)
                if (selectedDate < new Date(currentDate.getTime() - 86400000)) {
                    pickUpDate = existStorage.ticket_pick_up_date
                }
            }
            tempPicked.ticket_pick_up_date = pickUpDate
            tempPicked.ticket_pick_up_date_name = pickUpDate
        }
        // if (existStorage && adaptData.price_options) {
        //     tempPicked.price_options = existStorage.price_options.map((item, index) => {
        //         const itemUpdate = item
        //         itemUpdate.id = picked.price_options[index].id
        //         return itemUpdate
        //     })
        // }
        if (adaptData.attribute_1328387251) {
            tempPicked.optionClicked = typeof tempPicked.optionClicked === "undefined"
                ? {}
                : tempPicked.optionClicked
            get(adaptData.attribute_1328387251.api).then(res => {
                tempPicked.optionClicked.attribute_1328387251 = res.data
                Object.assign(picked, tempPicked)
                addToBookingStore("bookingOption", picked)
            })
        }
        if (adaptData.attribute_1328366605) {
            tempPicked.optionClicked = typeof tempPicked.optionClicked === "undefined"
                ? {}
                : tempPicked.optionClicked
            get(adaptData.attribute_1328366605.api).then(res => {
                tempPicked.optionClicked.attribute_1328366605 = res.data
                if (adaptData.attribute_1328366983) {
                    get(adaptData.attribute_1328366983.api).then(subRes => {
                        tempPicked.optionClicked.attribute_1328366983 = subRes.data
                        Object.assign(picked, tempPicked)
                        addToBookingStore("bookingOption", picked)
                    })
                } else {
                    Object.assign(picked, tempPicked)
                    addToBookingStore("bookingOption", picked)
                }
            })
        }
        /**
         * Removed un-used params from passing easybook request
         * Updated Date: 13/06/2019
         * Update By Pratik Bhalodiya
         */
        if (existStorage) {
            Object.keys(picked).map(key => {
                if (typeof existStorage[key] !== "undefined") {
                    picked[key] = existStorage[key]
                }
            })

            // patch meeting point input to restore from stored data
            if (
                picked.hotel_pickup
                && Array.isArray(picked.hotel_pickup)
                && picked.hotel_pickup[0] === 2
                && existStorage.papillo_dept_loca
                && (
                    !picked.papillo_dept_loca
                    || picked.papillo_dept_loca === ""
                )
            ) {
                picked.papillo_dept_loca = existStorage.papillo_dept_loca
                if (existStorage.papillo_dept_loca_name) {
                    picked.papillo_dept_loca_name = existStorage.papillo_dept_loca_name
                }
            }
        }
        if (tempPicked) {
            Object.keys(picked).map(key => {
                if (typeof tempPicked[key] !== "undefined") {
                    picked[key] = tempPicked[key]
                }
            })
        }
        /** Old Removed code
            Object.assign(picked, tempPicked, existStorage)
        */
        /** END */
        addToBookingStore("bookingOption", picked)
        const type = Constant.EASY_BOOK_UPDATE
        if (params.start_date || tickets) {
            dispatch({
                type,
                payload: bookData,
            })
            params.calculate_only = 1
            return addToCart(params, dispatch, getState, bookData)
        }
        bookData.noUpdated = false
        return dispatch({
            type,
            payload: bookData,
        })
    }).catch(err => {
        console.warn(err)
        if (err.name === "AbortError") {
            return false
        }
        const type = Constant.EASY_BOOK_UPDATE
        return dispatch({
            type,
            payload: { ok: false },
        })
    })
}

/**
 * Function updates state with next set of available dates
 * by requesting with incremented value of last available date
 *
 * @param {string} startDate It is last available date
 * @param {boolean} isOnlyDate This flag is for ticket products
 * @param {number} productID useful when there's no product ID inside ProductReducer
 *
 * @author Akash M <akashm.bipl@gmail.com>
 */
export const getNextCalendarDates = (startDate = "", productID = 0) => (dispatch, getState) => {
    const productReducers = getState().ProductReducers
    const firstNextDate = addDaysToDate(startDate, 1)
    if (
        productReducers.noFurtherDatesAvailable
        || !startDate
        || productID === 0
        || productReducers.isStartDateFetching
    ) {
        return
    }
    dispatch({
        type: Constant.FETCH_START_NEXT_CALENDAR_DATES,
    })
    get(
        getStartDatePicker.path,
        {
            ...getStartDatePicker.params,
            product_id: productID,
            start_date: firstNextDate,
        },
    ).then(data => {
        let payload = data.data || data
        payload = payload.start_date
        if (payload.length === 0) {
            dispatch({
                type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER_FAILED,
            })
        } else {
            const newPayload = productReducers.product.startDate
            newPayload.start_date = {
                ...newPayload.start_date,
                ...payload,
            }
            if (!newPayload.isNeedToHideCalender) {
                offlineStore("getStartDatePicker", {
                    productID,
                    start_date: newPayload.start_date,
                }, true)
            }
            dispatch({
                type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER_SUCCESS,
                payload: {
                    ...newPayload,
                },
            })
        }
    }).catch(err => {
        console.log(err)
        dispatch({
            type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER_FAILED,
        })
    })
}
/**
 * fetch start dates if no startDate in the productReducers
 *
 * @author Tony He <tonys9204@gmail.com>
 * @param {string} param current product ID
 * @param {boolean} isOnlyDate for fetch only Start dates
 *
 * @returns {none} no returns
 */
export const fetchStartDate = param => (dispatch, getState) => {
    const { isStartDateFetching } = getState().ProductReducers
    if (isStartDateFetching) {
        return false
    }
    if (!param) {
        const payload = {
            ok: false,
            message: `${t("this product not exist")}.`,
            code: "Error",
        }
        return dispatch({
            type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER_SUCCESS,
            payload,
        })
    }
    dispatch({
        type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER,
    })
    return get(
        getStartDatePicker.path,
        {
            ...getStartDatePicker.params,
            product_id: param,
        },
    ).then(data => {
        const payload = data.data || data
        const savedDate = offlineRetrive("getStartDatePicker", true)
        if (!_isEmpty(savedDate) && "" + savedDate.productID === "" + param) {
            payload.start_date = {
                ...savedDate.start_date,
                ...payload.start_date,
            }
        }
        if (!payload.isNeedToHideCalender) {
            offlineStore("getStartDatePicker", {
                productID: param,
                start_date: payload.start_date,
            }, true)
        }
        dispatch({
            type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER_SUCCESS,
            payload,
        })
        if (param && payload.start_date && payload.is_ajax_call) {
            dispatch(getNextCalendarDates(getLastDateKey(payload.start_date), +param))
        }
        // to ticket products
        if (data.ok && Array.isArray(data.data.start_date) && !data.data.start_date.length) {
            dispatch(easyBook({
                product_id: param,
                tran_id: "transportation",
                tran_option_id: 1,
            }, true))
        }
    }).catch(err => {
        console.log(err)
        dispatch({
            type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER_FAILED,
        })
    })
}
/**
 *  action to update easy book data
 * @param  {array} bookData new easy book data
 */
export const easyBookDataUpdate = bookData => (
    dispatch => dispatch({
        type: Constant.EASY_BOOK_UPDATE,
        payload: bookData,
    })
)

export default {
    easyBook,
    fetchStartDate,
    updateEasyBookPrice,
    easyBookDataUpdate,
    getNextCalendarDates,
    easyBookException,
}
