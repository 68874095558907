import { _find, offlineRetrive } from "../../common/tool/util"
import Constant from "../constants"

const initialState = {
    CartData: [],
    CartError: {
        ERROR: "",
    },
    isCartDataFetching: false,
    removeCartData: {},
    removeCartError: {
        ERROR: "",
    },
    recsysRecommendation: [],
}
export default function CartReducer(state = initialState, action) {
    let toursWishlist = []
    switch (action.type) {
    case Constant.GET_CART_REQUEST:
        return {
            ...state,
            isCartDataFetching: true,
        }
    case Constant.CLEAR_CART:
        return { ...initialState }
    case Constant.GET_CART_SUCCESS:
        toursWishlist = offlineRetrive("toursWishlist")
        toursWishlist = toursWishlist !== null ? toursWishlist : []
        action.payload.map(item => {
            // const tempObject = _find(toursWishlist, ["id", "" + item.product_id])
            const tempObject = _find(toursWishlist, obj => +obj.id === +item.product_id)
            if (tempObject) {
                item.isWishlisted = true
            } else {
                item.isWishlisted = false
            }
        })
        return {
            ...state,
            CartError: { ERROR: "" },
            CartData: action.payload,
            isCartDataFetching: false,
        }
    case Constant.GET_CART_ERROR:
        return {
            ...state,
            CartError: action.payload,
            isCartDataFetching: false,
        }
    case Constant.REMOVE_CART_SUCCESS:
        return {
            ...state,
            CartError: { ERROR: "" },
            CartData: action.payload,
        }
    case Constant.REMOVE_CART_ERROR:
        return { ...state, CartError: action.payload }
    case Constant.CLEAN_LOGIN_DATA:
        return { ...state, ...initialState }
    case Constant.GET_CART_ALSO_VIEWED_SUCCESS:
    case Constant.GET_CART_ALSO_VIEWED_ERROR:
        return {
            ...state,
            recsysRecommendation: action.payload,
        }
    default:
        return state
    }
}
