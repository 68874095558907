import Constant from "../constants"

const initialState = {
    storeData: {},
    storeDataError: {
        ERROR: "",
    },
    transitionName: "normal",
    selectedCurrency: {
        sign: "$ USD",
        code: "USD",
        symbol: "$",
    },
    selectedCurrencyFetched: false,
}
export default function CommonReducers(state = initialState, action) {
    switch (action.type) {
    case Constant.GET_STOREDATA_REQUEST:
        return { ...state }
    case Constant.GET_STOREDATA_SUCCESS:
        return { ...state, storeData: action.payload }
    case Constant.REMOVE_CART_ERROR:
        return { ...state, storeDataError: action.payload }
    case Constant.SET_TRANSITION:
        return { ...state, transitionName: action.payload }
    case Constant.SET_CURRENCY:
        return { ...state, selectedCurrency: action.payload, selectedCurrencyFetched: true }
    default:
        return state
    }
}
