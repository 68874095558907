/* eslint-disable camelcase */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import cx from "classnames"
import { Redirect } from "react-router"
import { JSONLD, Generic, AggregateRating } from "react-structured-data"
import LazyLoad from "react-lazyload"
import {
    Menu,
    Header,
    Footer,
    ShareToggle,
    NotFound,
} from "../../common/components/loadable"
import config, { t } from "../../../config"
import {
    addToRecentlyViewed,
    // offlineRetrive,
    // addToBookingStore,
    // asyncScriptLoad,
    _isEmpty,
    criteoTrackEvent,
    pad,
    fixImageUrl,
} from "../../common/tool/util"
import { toggleShare } from "../../redux/actions/ShareActions"
import {
    shouldRedirectProduct,
    reupdateWishlist,
    resetProductNotFound,
    clearProductDetail,
    getMetaPage,
    fetchEasyBookLocationPicker,
    fetchProductItineraryPricing,
    updateReviewNotFound,
    fetchAlsoViewed,
} from "../../redux/actions/ProductActions"
import { trackFBPixel } from "../../redux/actions/CommonActions"
import {
    Top,
    Overview,
    ToursForMe,
    Description,
    CallNow,
    Itinerary,
    Review,
    GoogleReview,
    BottomUI,
    YouMayAlsoLike,
    ToursOptions,
    Upward,
    ProductProfile,
    TourOptions,
    Impressions,
} from "./components/loadable"
import { pushProductDetail, pushAddToCart } from "../../common/tool/gtm-ee"
import styles from "./index.less"
import common from "../../assets/less/common.less"

class ProductPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isScrollUpHidden: false,
            zopimStatus: "offline",
            isPopup: false,
            submitedFBPixel: false,
        }
        this.popupElements = []
    }

    componentDidMount() {
        document.scrollingElement.scrollTop = 0
        this.shouldUpdate = true
        if (
            typeof window !== "undefined"
        ) {
            if (window.lastLocation) {
                if (
                    /^\/easy-book/.test(window.lastLocation[window.lastLocation.length - 2])
                    && this.props.productId > 0
                ) {
                    this.shouldUpdate = false
                } else if (
                    window.lastLocation.length === 1
                    && window.lastLocation[0] === this.props.match.url
                    && this.props.productId > 0
                ) {
                    // Content already loaded
                    this.shouldUpdate = false
                } else if (this.isThisLastViewedProduct()) {
                    // skip if this is the same product last viewed
                    this.shouldUpdate = false
                }
                if (this.shouldUpdate && !this.props.isProductNotFound) {
                    this.props.clearProductDetail()
                    // Is this product should redirect?
                    this.props.shouldRedirectProduct(
                        this.props.match.url,
                        this.getCheckIn(),
                        this.getCheckOut(),
                        this.props.filter.room_adult_total,
                        this.props.filter.room_child_total,
                        this.props.filter.latitude,
                        this.props.filter.longitude,
                        this.props.filter.img_size,
                    )
                } else if (!this.shouldUpdate) {
                    this.props.fetchAlsoViewed(this.props.productId)
                    this.props.getMetaPage(this.props.productId)
                    // push product impression
                    this.trackProductDetailImpression()
                }
            } else if (this.props.productId === 0) {
                // Loaded without SSR? Then initiate product page
                // Is this product should redirect?
                this.props.clearProductDetail()
                this.props.shouldRedirectProduct(
                    this.props.match.url,
                    this.getCheckIn(),
                    this.getCheckOut(),
                    this.props.filter.room_adult_total,
                    this.props.filter.room_child_total,
                    this.props.filter.latitude,
                    this.props.filter.longitude,
                    this.props.filter.img_size,
                )
            } else {
                /*
                * this parts calls only when product load with SSR
                * addToRecentlyViewed use for add product to To Recently Viewed
                * reupdateWishlist function update wishlist
                */
                const pId = this.props.product.details.old_product_id
                    ? this.props.product.details.old_product_id
                    : this.props.productId
                addToRecentlyViewed(pId)
                this.props.reupdateWishlist(this.props.productId)
                // push product impressions
                this.trackProductDetailImpression()
            }
        }
        // // add initial picked data to seesionStorage from SSR data
        // if (this.props.easyBookData.ok && !this.props.easyBookData.data.picked.price_options) {
        //     const { picked } = this.props.easyBookData.data
        //     // console.log(picked, this.props.productId)
        //     picked.product_id = this.props.productId
        //     const retriveData = offlineRetrive("bookingOption", true)
        //     const existStorage = retriveData && retriveData[picked.product_id]
        //     if (Number(picked.product_id) !== 0 && !existStorage) {
        //         if (this.props.easyBookData.data.product_type === "4") {
        //             Object.assign(picked, {
        //                 return_drop_off_location: {
        //                     id: "",
        //                     text: t("Please select return hotel/drop off location"),
        //                 },
        //                 return_pick_up_location: {
        //                     id: "",
        //                     text: t("Please select return pick up location"),
        //                 },
        //                 transportation_date_trigger_name: [t("Please select pick up date")],
        //             })
        //         }
        //         addToBookingStore("bookingOption", picked)
        //     }
        // }

        // zopim not using in live
        // // load zopim chat widget
        // // @todo move zopim id to as EV
        // asyncScriptLoad("https://v2.zopim.com/?2rZp27lRnR9HtXlHHPF9V12w8WNzTzCD", "zopim", () => {
        //     let interval = null
        //     let tries = 0
        //     const self = this

        //     /**
        //      * Attach zopim functions
        //      */
        //     function attachZopim() {
        //         if (!window.$zopim) {
        //             tries += 1
        //             // if it's more than 9 sec, give up
        //             if (tries > 30) {
        //                 clearInterval(interval)
        //             }
        //             return
        //         }

        //         // clear watcher
        //         clearInterval(interval)
        //         window.$zopim(() => {
        //             // hide the inbuilt button
        //             window.$zopim.livechat.hideAll()
        //             // update button type according to agent's status
        //             window.$zopim.livechat.setOnStatus(status => {
        //                 let agent = ""
        //                 switch (status) {
        //                 case "online":
        //                 case "away":
        //                     agent = "fa-comments"
        //                     break
        //                 default:
        //                     agent = "fa-envelope"
        //                 }
        //                 self.setState({
        //                     zopimStatus: agent,
        //                 })
        //             })
        //             // hide the inbuilt button when hiding the chat window
        //             window.$zopim.livechat.window.onHide(() => {
        //                 window.$zopim.livechat.hideAll()
        //             })
        //         })
        //     }

        //     // add watcher until $zopim avaialble
        //     if (!window.$zopim) {
        //         interval = setInterval(attachZopim, 300)
        //     } else {
        //         attachZopim()
        //     }
        // })
        if (typeof window !== "undefined") {
            this.handleHash()
            window.addEventListener("hashchange", this.handleHash, false)
            criteoTrackEvent({
                event: "viewItem",
                item: this.props.product.details.IC,
            })
        }
    }

    componentDidUpdate(prevProps) {
        // Check this udpate is a route change
        if (this.props.match.url !== prevProps.match.url) {
            this.props.clearProductDetail()
            // Scroll to top, so customer feels this is a new page
            window.scrollTo(0, 0)
            this.props.shouldRedirectProduct(
                this.props.match.url,
                this.getCheckIn(),
                this.getCheckOut(),
                this.props.filter.room_adult_total,
                this.props.filter.room_child_total,
                this.props.filter.latitude,
                this.props.filter.longitude,
                this.props.filter.img_size,
            )
        }
        // all good, load product data
        if (prevProps.productId !== this.props.productId) {
            this.load(this.props.productId)
        }

        // Update dataLayer for GTM
        if (
            this.props.product.details.I
            && prevProps.product.details.I !== this.props.product.details.I
        ) {
            this.props.fetchAlsoViewed(this.props.product.details.I)
            const deviceType = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(navigator.userAgent) ? "m" : "d"
            const d = {
                deviceType,
                productId: this.props.product.details.I,
            }
            if (window.dataLayer) {
                window.dataLayer.push(d)

                // push product impressions
                this.trackProductDetailImpression()
            }
        }
        // Update Fb Pixel
        if (this.props.selectedCurrencyFetched) {
            this.submitFBPixel()
        }
    }

    componentWillUnmount() {
        if (this.props.isProductNotFound) {
            this.props.resetProductNotFound()
        }
        if (this.props.reviewNotFound) {
            this.props.updateReviewNotFound(false)
        }
        this.props.toggleShare(true)
        document.body.classList.remove(styles.noScroll)
        document.body.classList.remove("prevent_scroll")
        window.removeEventListener("hashchange", this.handleHash, false)
    }

    setScrollUpHidden = value => {
        this.setState({
            isScrollUpHidden: value,
        })
    }

    getCheckIn=() => {
        const d = new Date()
        d.setDate(d.getDate() + 3)
        return d.getFullYear() + "-" + pad(d.getMonth() + 1, 2) + "-" + pad(d.getDate(), 2)
    }

    getCheckOut=() => {
        const d = new Date()
        d.setDate(d.getDate() + 4)
        return d.getFullYear() + "-" + pad(d.getMonth() + 1, 2) + "-" + pad(d.getDate(), 2)
    }

    /**
     * Verify this particular product is the last viewed product
     *
     * @author Gihan S <gihanshp@gmail.com>
     */
    isThisLastViewedProduct = () => {
        if (!window || !window.lastLocation || window.lastLocation.length < 2) {
            return false
        }

        // skip the last entry
        // because it's injected to the lastLocation from last route change
        for (let i = window.lastLocation.length - 1; i > 0; i -= 1) {
            if (/\.html$/.test(window.lastLocation[i - 1])) {
                return this.props.match.url === window.lastLocation[i - 1]
            }
        }

        return false
    }

    handleHash = () => {
        switch (window.location.hash) {
        // case "#review-add":
        case "#review":
        case "#tr_photos":
        case "#tr_photos-upload":
        case "#pricing":
        case "#special":
        case "#pick_up_details_transfers":
        case "#itinerary-map":
        case "#itinerary":
            this.setScrollUpHidden(true)
            this.setState({ isPopup: true })
            document.body.classList.add(styles.noScroll)
            break
        default:
            this.setState({ isPopup: false })
            this.setScrollUpHidden(false)
            document.body.classList.remove(styles.noScroll)
        }
    }

    trackProductDetailImpression = () => {
        const { details } = this.props.product
        // push product impressions
        pushProductDetail(
            details.N,
            details.IC,
            details.ga_category_name,
        )
    }

    loadReviewSection = () => {
        if (
            this.props.product
            && this.props.product.details
            && this.props.product.details.ET
            && this.props.product.details.ET === "3"
        ) {
            return (
                <LazyLoad height={555} offset={500}>
                    <GoogleReview
                        keyword={this.props.product.details.N}
                        travelerPhotosSlider={this.props.product.travelerPhotos}
                        latitude={
                            (this.props.product.details.GEO)
                                ? this.props.product.details.GEO.LAT
                                : undefined
                        }
                        longitude={
                            (this.props.product.details.GEO)
                                ? this.props.product.details.GEO.LON
                                : undefined
                        }
                        history={this.props.history}
                    />
                </LazyLoad>
            )
        }
        return (
            <LazyLoad height={555} offset={500}>
                <Review
                    productId={this.props.productId}
                    history={this.props.history}
                />
            </LazyLoad>
        )
    }

    submitFBPixel = () => {
        if (
            this.props.productId
            && this.props.product
            && this.props.product.details
            && !this.state.submitedFBPixel
        ) {
            const { SP, P } = this.props.product.details
            const Price = (P !== SP && SP > 0) ? SP : P
            this.props.trackFBPixel({
                action: "ViewContent",
                contentIds: [this.props.productId],
                contentType: "product",
                value: Price,
            })
            this.setState({ submitedFBPixel: true })
        }
    }

    pushAddToCart = () => {
        const { details } = this.props.product
        pushAddToCart(
            details.N,
            details.IC,
            details.ga_category_name,
            1,
            details.region_name,
            details.activity_type_name,
        )
    }

    /**
     * Load product page data
     */
    load(productId) {
        if (productId === 0) {
            return
        }
        this.props.fetchAlsoViewed(productId)
        this.props.getMetaPage(productId)
        this.props.fetchProductItineraryPricing(productId)
        if (
            _isEmpty(this.props.product.productItineraryLocation)
            || (
                typeof this.props.product.productItineraryLocation.ok !== "undefined"
                && this.props.product.productItineraryLocation.ok !== true
            )
        ) {
            this.props.fetchEasyBookLocationPicker(
                productId,
                this.getCheckIn(),
                this.getCheckOut(),
                this.props.filter.room_adult_total,
                this.props.filter.room_child_total,
                true,
                true,
            )
        }
    }

    render() {
        // is loading or product not found?
        if (this.props.isProductFetching || this.props.isProductNotFound) {
            return (
                <div
                    className={
                        cx("body_main_container", {
                            body_main_container_right: !this.props.isMenuHidden,
                        })
                    }
                >
                    <Header
                        isBackButton
                        isSearch
                        isCart
                        isTransparent
                        history={this.props.history}
                        meta={{
                            ...this.props.MetaData,
                            canonical: config.url.t4fUrl + this.props.match.url,
                        }}
                    />
                    {
                        this.props.isProductNotFound
                        && <NotFound />
                    }
                    {
                        this.props.isProductFetching
                        && (
                            <div className={common.loader}>
                                {t("Loading")}
                                ...
                            </div>
                        )
                    }
                    <Menu
                        disableMenu={
                            this.state.isPopup
                            || !this.props.isShareHidden
                        }
                        history={this.props.history}
                    />
                </div>
            )
        }
        // render redirect
        if (this.props.redirectTo) {
            // @todo push doens't replce the current history enrty.
            // So browser back button still works. Need to fix this
            return (
                <Redirect
                    push
                    to={this.props.redirectTo}
                />
            )
        }

        const {
            history,
            isShareHidden,
            productId,
        } = this.props
        const {
            details,
        } = this.props.product
        const ppi = this.props.product.productItineraryPricing
        const ppl = this.props.product.productItineraryLocation
        const providerId = this.props.product.details.A
        let currencySymbol = "$"
        if (
            this.props.selectedCurrency
            && this.props.selectedCurrency.symbol
        ) {
            currencySymbol = this.props.selectedCurrency.symbol
        }
        let rateStar = details.S
        if (details.ET === "3" && details.hotelbeds) {
            rateStar = details.hotelbeds.star_rating * 20
        }

        // Offer structured data
        const offer = {
            priceCurrency: this.props.selectedCurrency.code,
            price: details.SP ? details.SP : 0,
            availability: details.ST ? "http://schema.org/InStock" : "http://schema.org/SoldOut",
        }
        // is there any discount with end date
        if (details.discount && details.discount.data.length > 0) {
            for (let u = 0; u < details.discount.data.length; u += 1) {
                const type = details.discount.type ? "%" : ""
                if (
                    details.discount.data[u].save_type === details.discount.type
                    && "" + details.discount.data[u].save_value === "" + details.discount.value + type
                ) {
                    offer.priceValidUntil = details.discount.data[u].end_date.substr(0, 10)
                    break
                }
            }
        }

        const genericElement = []
        if (parseInt(details.CN, 10) > 0) {
            genericElement.push(<AggregateRating
                ratingValue={details.S / 20}
                reviewCount={details.CN}
                key={1}
            />)
        }
        genericElement.push(<Generic
            type="offers"
            jsonldtype="Offer"
            schema={offer}
            key={2}
        />)

        // patch to main image be the carousel 1st image
        const bannerImages = []
        for (let u = 0; u < details.PG.length; u += 1) {
            // push the main image to 0 index
            if (u === 0) {
                if (details.PG[u] !== details.G) {
                    bannerImages.push(details.G)
                }
                bannerImages.push(details.PG[u])
            } else if (details.PG[u] !== details.G) {
                bannerImages.push(details.PG[u])
            }
        }

        const iWidth = 375
        const iHeight = 250
        const [preloadImageRaw] = bannerImages
        const preloadImage = fixImageUrl(preloadImageRaw, 375 * 2, 250 * 2, 55)
        const preloadSrcset = [
            `${fixImageUrl(preloadImageRaw, iWidth, iHeight)} 1x`,
            `${fixImageUrl(preloadImageRaw, iWidth * 2, iHeight * 2, 55)} 2x`,
            `${fixImageUrl(preloadImageRaw, iWidth * 3, iHeight * 3, 50)} 3x`,
        ]

        let isMapPosition = true
        const { data } = ppl
        // This flag is been set to avoid errors with varied response
        if (data) {
            if (typeof data === "object") {
                if (data.position) {
                    if (data.position.length === 0) {
                        isMapPosition = false
                    }
                } else {
                    isMapPosition = false
                }
            } else if (data.isArray()) {
                if (data.length === 0) {
                    isMapPosition = false
                } else if (data.position.length === 0) {
                    isMapPosition = false
                }
            }
        }
        const youMayLikeLbl = t("you may also like")
        const inspiredByLbl = t("related tours and activities")
        return (
            <div
                className={
                    cx("body_main_container", {
                        body_main_container_right: !this.props.isMenuHidden,
                    })
                }
            >
                <Header
                    isBackButton
                    isSearch
                    isCart
                    isTransparent
                    history={history}
                    meta={{
                        ...this.props.MetaData,
                        canonical: config.url.t4fUrl + this.props.match.url,
                        image: preloadImage,
                        imagesrcset: preloadSrcset.join(","),
                    }}
                />
                <JSONLD dangerouslyExposeHtml>
                    <Generic
                        type="product"
                        jsonldtype="Product"
                        schema={{
                            name: details.N,
                            image: [details.G],
                            description: details.SDS,
                            sku: details.M,
                            category: details.ga_category_name,
                        }}
                    >
                        {
                            genericElement
                        }
                    </Generic>
                </JSONLD>
                <Top
                    banners={bannerImages}
                    currencySymbol={currencySymbol}
                    video={details.video}
                    autoConfirm={details.AC}
                    ACD={details.ACD}
                    title={details.N}
                    ET={details.ET}
                    CN={details.CN}
                    ST={details.ST}
                    smallGroupSize={details.small_group_size}
                    // smallGroupSize={40}
                    tourTypeIcons={details.tour_type_icons}
                    // tourTypeIcons={["Small Group Tour"]}
                    discount={(details.discount) ? details.discount : null}
                    displayPrice={(
                        details.P !== details.SP
                        && details.SP > 0)
                        ? details.SP : details.P}
                    strikePrice={(
                        details.P !== details.SP
                        && details.SP > 0)
                        ? details.P : details.P}
                    satisfaction={rateStar}
                    A={details.A}
                    subTitle={details.sub_title}
                    promo_notices={details.promo_notices}
                    pushAddToCart={this.pushAddToCart}
                    trackFBPixel={this.props.trackFBPixel}
                    toggleShare={this.props.toggleShare}
                />
                <div className={`${styles.product_page_maindiv} ${styles.overviewPaddingAdjuster}`}>
                    <div className={styles.container}>
                        {
                            this.props.product.details.ST === false
                            && (
                                <LazyLoad offset={200} height={220}>
                                    <Impressions
                                        type="similar"
                                        heading={inspiredByLbl}
                                        gaClassName="productPageInspiredBy"
                                    />
                                </LazyLoad>
                            )
                        }
                        {
                            details.ET !== "3"
                            && (
                                <Overview
                                    heightlight={details.SDS
                                        ? details.SDS : ""}
                                    toursCode={details.M}
                                    startCity={(details.SC) ? details.SC : ""}
                                    endCity={details.EC}
                                    duration={details.D}
                                    durationType={details.DT}
                                    subTitle={details.sub_title}
                                    tourTypeIcons={details.tour_type_icons}
                                    googleTranslated={details.translated_by_google}
                                />
                            )
                        }
                        {
                            ppi.TourOption
                            && (
                                <TourOptions
                                    rateType={ppi.TourOption.rateType}
                                    tourOptions={ppi.TourOption.tourOptions}
                                    currencySymbol={currencySymbol}
                                    selectedCurrency={this.props.selectedCurrency}
                                />
                            )
                        }
                        <ToursForMe
                            toursCode={details.M}
                            tourTags={(details.tour_tags) ? details.tour_tags : []}
                            operatingLanguages={
                                (details.L && details.L.live
                                    && details.L.live.languages)
                                    ? details.L.live.languages
                                    : []
                            }
                        />
                        {/* related tours and activities , recommended, inspiredBy */}
                        {
                            this.props.product.details.ST
                            && (
                                <LazyLoad offset={200} height={220}>
                                    <Impressions
                                        heading={inspiredByLbl}
                                        type="similar"
                                        gaClassName="productPageInspiredBy"
                                    />
                                </LazyLoad>
                            )
                        }
                        {
                            details.ET === "3"
                            && (
                                <Description
                                    description={
                                        details.hotelbeds
                                        && details.hotelbeds.description
                                            ? details.hotelbeds.description
                                            : details.DS
                                    }
                                />
                            )
                        }
                        {
                            details.ET !== "3"
                            && !_isEmpty(ppi.I)
                            && ppl
                            && (
                                <Itinerary
                                    locations={ppl}
                                    visitingCities={ppi.I.visiting_cities
                                        ? ppi.I.visiting_cities : []}
                                    startCity={ppi.I.start_city}
                                    endCity={ppi.I.end_city}
                                    at={ppi.I.AT}
                                    days={ppi.I.D}
                                    note={ppi.I.N}
                                    admissionPrice={ppi.I.own_expences}
                                    displayMap={details.is_display_map}
                                    history={this.props.history}
                                    isMapPosition={isMapPosition}
                                />
                            )
                        }
                        {
                            <LazyLoad height={138} offset={500} once>
                                <BottomUI
                                    product={this.props.product}
                                    currencySymbol={currencySymbol}
                                    history={this.props.history}
                                />
                            </LazyLoad>
                        }
                        {this.loadReviewSection()}
                        <LazyLoad height={135} offset={100} once>
                            <CallNow
                                toursCode={details.M}
                            />
                        </LazyLoad>

                        <LazyLoad offset={200} height={220}>
                            <Impressions
                                heading={youMayLikeLbl}
                                type="recommended"
                                gaClassName="you_may_like_box"
                            />
                        </LazyLoad>
                        {
                            !_isEmpty(this.props.product.alsoViewed)
                            && (
                                <LazyLoad height={360} offset={500} once>
                                    <YouMayAlsoLike
                                        product={this.props.product}
                                        reset={this.props.clearProductDetail}
                                        selectedCurrency={this.props.selectedCurrency}
                                        configurations={this.props.configurations}
                                    />
                                </LazyLoad>
                            )
                        }
                        <LazyLoad height={265} offset={100} once>
                            <ProductProfile
                                expertData={details.expert}
                                productId={productId}
                                providerId={providerId}
                            />
                        </LazyLoad>
                    </div>
                </div>
                <ToursOptions
                    entityType={details.T}
                    productId={productId}
                    ET={details.ET}
                    zopimStatus={this.state.zopimStatus}
                    pushAddToCart={this.pushAddToCart}
                    selectedCurrency={this.props.selectedCurrency}
                />
                <Upward isScrollUpHidden={this.state.isScrollUpHidden} />
                <ShareToggle
                    shareLink={`${config.url.t4fUrl}${this.props.match.url}`}
                    subject={t("Check out this")}
                    body={t("Check out what I found at Tours4fun")}
                    text={details.N}
                    isShareHidden={isShareHidden}
                    toggleShare={this.props.toggleShare}
                />
                <div style={{ padding: "0.1rem" }} />
                <Footer />
                <Menu
                    disableMenu={
                        this.state.isPopup
                        || !isShareHidden
                    }
                    history={this.props.history}
                />
            </div>
        )
    }
}


ProductPage.propTypes = {
    product: PropTypes.oneOfType([PropTypes.object]),
    productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    match: PropTypes.oneOfType([PropTypes.object]),
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    isProductNotFound: PropTypes.bool,
    isShareHidden: PropTypes.bool.isRequired,
    reupdateWishlist: PropTypes.func.isRequired,
    getMetaPage: PropTypes.func.isRequired,
    // fetchProductById: PropTypes.func.isRequired,
    fetchProductItineraryPricing: PropTypes.func.isRequired,
    fetchEasyBookLocationPicker: PropTypes.func.isRequired,
    clearProductDetail: PropTypes.func.isRequired,
    shouldRedirectProduct: PropTypes.func.isRequired,
    redirectTo: PropTypes.string,
    room_adult_total: PropTypes.number,
    room_child_total: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    isMenuHidden: PropTypes.bool,
    MetaData: PropTypes.oneOfType([PropTypes.object]),
    filter: PropTypes.oneOfType([PropTypes.object]),
    toggleShare: PropTypes.func.isRequired,
}

ProductPage.defaultProps = {
    productId: 0,
    product: {},
    start_date: "",
    checkout_date: "",
    room_adult_total: 0,
    room_child_total: 0,
    latitude: 0,
    longitude: 0,
    productItineraryPricing: {},
    ismoreReview: false,
    isFullItinerary: false,
    isMenuHidden: false,
    redirectTo: "/",
    match: {},
    MetaData: {
        description: "",
        keywords: "",
        title: "",
    },
    filter: {
        advancedSearch: {},
        checkout_date: "",
        count: 0,
        img_size: "674x",
        keywords: "",
        latitude: 0,
        longitude: 0,
        page: 1,
        pageSize: 0,
        product_entity_type: "",
        room_adult_total: 2,
        room_child_total: 0,
        start_date: "",
    },
    isProductNotFound: false,
}

function mapStateToProps(state) {
    return {
        ...state.ProductReducers,
        ...state.ShareReducers,
        ...state.MetaReducers,
        ...state.LoginReducer,
        ...state.ToursReducers,
        ...state.MenuReducers,
        ...state.InitData,
        ...state.CommonReducers,
        ...state.BookingReducers,
        initData: state.InitData,
    }
}
export default connect(
    mapStateToProps,
    {
        toggleShare,
        reupdateWishlist,
        clearProductDetail,
        getMetaPage,
        resetProductNotFound,
        fetchEasyBookLocationPicker,
        fetchProductItineraryPricing,
        fetchAlsoViewed,
        shouldRedirectProduct,
        updateReviewNotFound,
        trackFBPixel,
    },
)(ProductPage)
