import { stringToDate } from "./dateUtils"
import { formatCurrency, convertCurrency } from "./Formatter"
import { _isEmpty } from "./util"
/**
 * This is a port of php pricing functions
 *
 * @author Pratik B <pratikb.bipl@gmail.com> (In React - New author)
 * @author Gihan S <gihanshp@gmail.com> (In angular - Old author)
 */
/* eslint-disable class-methods-use-this, no-param-reassign */
class ProductPrice {
    constructor() {
        this.PREFIX_TYPE_PERCENTAGE = "%"
        this.ENTITY_ONEDAY_TOUR = 0
        this.ENTITY_BUS_TOUR = 6
        this.ENTITY_CRUISE = 2
        this.ENTIRY_TRANSPORTATION = 4
        this.ENTITY_MULTIDAY_TOUR = 1
        this.ENTIRY_HOTEL = 3
    }

    /**
    *
    * GetSpecialPrice
    * @see Product::getSpecialPriceT4fAPI()
    *
    */
    getSpecialPrice(specialPrice) {
        let specialPriceVal = false
        if (!specialPrice) {
            return specialPriceVal
        }
        // TODO by using the date from client's browser may lead to problems
        const now = new Date()

        Object.keys(specialPrice).forEach(key => {
            const price = specialPrice[key]
            const departEndDate = stringToDate(price.depart_end_date)
            // const departStartDate = stringToDate(price.depart_start_date)
            const startDate = stringToDate(price.start_date)
            const endDate = stringToDate(price.end_date)
            if (
                (departEndDate > now || price.depart_end_date === "0000-00-00")
                && (startDate < now || price.start_date === "0000-00-00 00:00:00")
                && (endDate > now || price.end_date === "0000-00-00 00:00:00")
            ) {
                if (parseFloat(price.price_t4f) > 0) {
                    specialPriceVal = price.price_t4f
                } else {
                    specialPriceVal = price.price
                    if (price.price_double && price.price_double > 0) {
                        specialPriceVal = price.price_double
                    }
                }
                return false
            }
            return true
        })
        return specialPriceVal
    }

    /**
    * @see  Product::getOverridePriceT4fAPI()
    *
    */
    getOverridePrice(override) {
        if (!override) {
            return override
        }
        const now = new Date()
        let returnVal = false
        Object.keys(override).forEach(key => {
            const price = override[key]
            const purchaseStartDate = stringToDate(price.purchase_start_date)
            const purchaseEndDate = stringToDate(price.purchase_end_date)
            if (
                purchaseStartDate <= now
                && purchaseEndDate >= now
            ) {
                returnVal = price
                return false
            }
            return true
        })
        return returnVal
    }

    /**
    *
    * @see ProductPrice::calculatePrefixValue()
    *
    */
    calculatePrefixValue(prefixType, prefix, prefixValue, value) {
        let calculatedValue
        if (prefixType === this.PREFIX_TYPE_PERCENTAGE) {
            let multiplier = 1
            if (prefix === "-") {
                multiplier = (100 - prefixValue) / 100
            } else {
                multiplier = (100 + prefixValue) / 100
            }
            calculatedValue = value * multiplier
        } else {
            calculatedValue = prefix === "-" ? prefixValue - value : prefixValue + value
        }
        return calculatedValue
    }

    /**
    *
    * @see  ProductPrice::getOverriddenDisplayPriceAPI()
    *
    */
    getOverriddenDisplayPrice(product, displayPrice, priceItem) {
        let pprice
        if (parseFloat(priceItem.price) <= 0) {
            let displayPriceAttribute = "price_adult"
            switch (parseInt(product.product_entity_type_id, 10)) {
            case this.ENTITY_ONEDAY_TOUR:
            case this.ENTITY_BUS_TOUR:
            case this.ENTITY_CRUISE:
            case this.ENTIRY_TRANSPORTATION:
                displayPriceAttribute = "price_adult"
                break
            case this.ENTITY_MULTIDAY_TOUR:
            case this.ENTIRY_HOTEL:
                displayPriceAttribute = "price_double"
                break
            case this.ENTIRY_SIM:
                displayPriceAttribute = "price_single"
                break
            default:
                break
            }
            pprice = priceItem[displayPriceAttribute]
        } else {
            // common price
            pprice = parseFloat(priceItem.price)
        }
        const price = this.calculatePrefixValue(
            priceItem.prefix_type,
            priceItem.prefix,
            pprice,
            displayPrice,
        )
        return price
    }

    /**
    *
    * @see Product::getCurrencyPriceT4fFromApi()
    *
    */
    getPrice(product, toCurrency, isAffiliate, InitData) {
        isAffiliate = isAffiliate || false
        // TODO fix appropriately though affiliates are not used for mobile site.
        let price = parseFloat(product.real_default_price_t4f)
        const fromCurrency = product.currency_code ? product.currency_code : "USD"
        // todo change this one to dynamic
        let special = this.getSpecialPrice(product.special_price)
        const override = this.getOverridePrice(product.override_price)
        let defaultSavePrice = 0
        let onSale = false

        if (special && !override && parseFloat(special) !== "NaN" && special !== price && !isAffiliate) {
            defaultSavePrice = price - special
            onSale = true
        } else if (override) {
            const attribute = "real_default_price_t4f"
            price = this.getOverriddenDisplayPrice(product, product[attribute], override)
            if (special && parseFloat(special) > 0 && !isAffiliate) {
                // don't calculate the overridden special price
                // because product team wants to have control over what's being displayed
                defaultSavePrice = price - special
                onSale = true
            } else {
                special = false
            }
        }
        return {
            defaultPrice: formatCurrency(price),
            targetDefaultPrice:
                formatCurrency(
                    convertCurrency(fromCurrency, toCurrency, price, 2, InitData),
                    onSale,
                ),
            specialPrice: onSale ? special : 0,
            targetSpecialPrice:
            onSale ? convertCurrency(fromCurrency, toCurrency, special, 2, InitData) : 0,
            defaultSavePrice: formatCurrency(defaultSavePrice),
            targetSavePrice:
                convertCurrency(fromCurrency, toCurrency, defaultSavePrice, 2, InitData),
            defaultCurrency: fromCurrency,
            targetCurrency: toCurrency,
        }
    }

    /**
     * get price off in t4f
     */
    getPriceOffT4fAPI(product, currency, InitData) {
        if (!product) return false
        if (!_isEmpty(product.special_price)) {
            const today = new Date()
            const todayDate = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                0,
                0,
                0,
            )
            /* eslint-disable */
            for (let price in product.special_price) {
                price = product.special_price[price]
                if (
                    (
                        new Date(stringToDate(price.depart_end_date)).getTime() > todayDate.getTime() ||
                        (price.depart_end_date === "0000-00-00")
                    ) && (
                        new Date(stringToDate(price.start_date)).getTime() < todayDate.getTime() ||
                        price.start_date === "0000-00-00 00:00:00"
                    ) && (
                        new Date(stringToDate(price.end_date)).getTime() > todayDate.getTime() ||
                        price.end_date === "0000-00-00 00:00:00"
                    )
                ) {
                    if (parseInt(price.type, 10) === 3 && price.percentage) {
                        const off = 100 - price.percentage
                        return {
                            type: "percent",
                            value: off === parseInt(off, 10) ? parseInt(off, 10) : off,
                            data: price,
                        }
                    }
                    const defaultPrice = parseFloat(product.default_price_t4f) ?
                        parseFloat(product.default_price_t4f) :
                        parseFloat(product.default_price)
                    const specialPrice = parseFloat(price.price_t4f) ?
                        parseFloat(price.price_t4f) :
                        parseFloat(price.price)

                    const override = this.getOverridePrice(product.override_price)
                    let defaultSavePrice
                    if (override) {
                        price = this
                            .getOverriddenDisplayPrice(product, product["default_price_t4f"], override)
                        if (specialPrice && parseFloat(specialPrice)) {
                            defaultSavePrice = price - specialPrice
                        }
                    } else {
                        defaultSavePrice = defaultPrice - specialPrice
                    }
                    const fromCurrency = product.currency_code ? product.currency_code : "USD"
                    const discountValue = convertCurrency(fromCurrency, currency, defaultSavePrice, 2, InitData)
                    return {
                        type: "save",
                        value: discountValue,
                        data: price,
                    }
                }
            }
        }
        /* eslint-enable */
        return false
    }
}
/* eslint-enable class-methods-use-this, no-param-reassign */
export default ProductPrice
