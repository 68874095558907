import Constant from "../constants"

const initialState = {
    isShareHidden: true
}
export default function ShareReducers(state = initialState, action) {
    switch (action.type) {
    case Constant.SET_SHARE_HIDDEN:
        return { ...state, isShareHidden: action.isShareHidden }
    default:
        return state
    }
}
