import Constant from "../constants"

export const toggleShare = isShareHidden => dispatch => {
    dispatch({
        type: Constant.SET_SHARE_HIDDEN,
        isShareHidden,
    })
}

export default {
    toggleShare,
}
