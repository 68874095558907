import React, { Component } from "react"
import cx from "classnames"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
    Header,
    Footer,
    Search,
    Menu,
    TourSlider,
} from "../../common/components/loadable"
import {
    Landing,
    PopularCities,
    Experiences,
    FreeTours,
    NearByMeSlider,
} from "./components/loadable"
import configs, { isT4Fes, t } from "../../../config"
import { homeSearchToogle } from "../../redux/actions/SearchAction"
import { getMetaHome } from "../../redux/actions/MetaAction"
import {
    fetchHomeBanners,
    fetchPopularDestinations,
    fetchPromotions,
    fetchExperienceTourTags,
    fetchRecentlyViewed,
} from "../../redux/actions/HomeActions"
import ShareAct from "../../redux/actions/ShareActions"
import {
    fixImageUrl,
    criteoTrackEvent,
    getUsefulDocObj,
    _isEmpty,
} from "../../common/tool/util"
import animation from "../../common/tool/animation"
import styles from "./index.less"
import StylesBase from "../../assets/less/base.less"

class Home extends Component {
    /**
     * isomorphic initial data fetch for SSR and client site
     *
     * @param {object} props it's this.props if from client side, and it's store if from serverside
     *
     * @return {fetchList} datas should fetch
     *
     * @author Tony <tonys9204@gmail.com>
     */
    static fetchData(props) {
        const fetchList = []
        const {
            dispatch,
            banners,
            promotions,
            experienceData,
            popularDestinations,
            MetaPage,
        } = props
        const getAct = (ssrFunc, clientFunc) => (dispatch && dispatch(ssrFunc())) || clientFunc()

        if (_isEmpty(banners)) {
            fetchList.push(getAct(fetchHomeBanners, props.fetchHomeBanners))
        }
        if (_isEmpty(promotions)) {
            fetchList.push(getAct(fetchPromotions, props.fetchPromotions))
        }
        if (_isEmpty(popularDestinations)) {
            fetchList.push(getAct(fetchPopularDestinations, props.fetchPopularDestinations))
        }
        if (_isEmpty(experienceData)) {
            fetchList.push(getAct(fetchExperienceTourTags, props.fetchExperienceTourTags))
        }
        if (MetaPage !== "home") {
            fetchList.push(getAct(getMetaHome, props.getMetaHome))
        }
        return fetchList
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillMount() {
        // do this lifecycle on client side only
        this.props.homeSearchToogle(false)
        if (typeof window !== "undefined") {
            this.constructor.fetchData(this.props)
            this.props.fetchRecentlyViewed()
        }
    }

    componentDidMount() {
        if (typeof window !== "undefined") {
            criteoTrackEvent({ event: "viewHome" })
            const scrollEle = getUsefulDocObj(document)
            const scrollEleTop = scrollEle.scrollTop
            animation(scrollEleTop, 0, "Quad.easeInOut", val => {
                scrollEle.scrollTop = val
            })
        }
    }

    componentWillUnmount() {
        this.props.toggleShare(true)
        document.body.classList.remove("prevent_scroll")
    }

    getDealBannerHTML() {
        if (_isEmpty(this.props.promotions)) {
            return ""
        }
        const promoObj = this.props.promotions[0]
        promoObj.bannerTxt = promoObj.T.split(",")
        return (
            <div className={styles.landingBannerSec}>
                <a
                    href={`/special-travel-offers-and-deals/?ifm=1&currency=${this.props.selectedCurrency.code}`}
                >
                    <div className="container">
                        <div
                            className={cx(
                                styles.landingBannerBox,
                                isT4Fes && styles.esPromoBanner,
                            )}
                            style={{
                                backgroundImage: `url(${promoObj.G})`,
                            }}
                        >
                            <div className={styles.landingInfoDiv}>
                                <div className={styles.promoTxtContent}>
                                    <div className={styles.landingTitle}>
                                        {promoObj.bannerTxt[0]}
                                    </div>
                                </div>
                                {
                                    promoObj.bannerTxt[1]
                                    && (
                                        <div className={styles.promoOffContent}>
                                            <div
                                                className={styles.landingDiscountText}
                                                dangerouslySetInnerHTML={{
                                                    __html: promoObj.bannerTxt[1],
                                                }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        )
    }

    render() {
        const {
            history,
            banners,
            recentData,
            isMenuHidden,
            isShareHidden,
            showHomeSearch,
            experienceData,
            popularDestinations,
        } = this.props
        const heroImage = banners.length > 0 && banners[0] ? fixImageUrl(encodeURI(banners[0].G), 375 * 1.5, 0, 55) : ""
        const heroImageSrcsetAr = [
            `${fixImageUrl(encodeURI(heroImage), 375)} 1x`,
            `${fixImageUrl(encodeURI(heroImage), 375 * 1.5, 0, 55)} 2x`,
            `${fixImageUrl(encodeURI(heroImage), 375 * 2, 0, 50)} 3x`,
        ]
        const heroImageSrcset = heroImageSrcsetAr.join(", ")
        return (
            <div>
                <div />
                {/* @FIXME This empty div should be here, otherwise weird CSS issue on homepage */}
                <div
                    className={
                        cx(
                            StylesBase.body_main_container,
                            styles.homePage,
                            { [StylesBase.body_main_container_right]: !isMenuHidden },
                        )
                    }
                >
                    {/* header nav bar */}
                    <Header
                        isCart
                        history={history}
                        meta={{
                            ...this.props.MetaData,
                            canonical: configs.url.t4fUrl,
                            image: heroImage,
                            imagesrcset: heroImageSrcset,
                        }}
                    />
                    {/* banner and searchForm */}
                    {
                        (
                            showHomeSearch
                            && <Search isFromHomePage history={history} />
                        )
                        || (
                            <div className={styles.bannerContainer}>
                                {
                                    heroImage
                                    && (
                                        <img
                                            className={StylesBase.cover_img}
                                            src={heroImage}
                                            srcSet={heroImageSrcset}
                                            alt=""
                                        />
                                    )
                                }
                                <Landing
                                    bannerText={t("HOME_PAGE_HERO_BANNER_TEXT")}
                                />
                            </div>
                        )
                    }
                    <div className={styles.whiteSec}>
                        {
                            this.getDealBannerHTML()
                        }
                        <NearByMeSlider
                            CurrencySymbol={this.props.selectedCurrency.symbol}
                            currency={this.props.selectedCurrency}
                            className={styles.recentlyViewed}
                        />
                        {/* recently viewed */}
                        {
                            !!recentData.length
                            && (
                                <div className={styles.recentlyViewed + " " + styles.recentlyViewedSpace}>
                                    <div className="container tours_title_sec">
                                        <h2 className={styles.title}>
                                            {t("Recently Viewed")}
                                        </h2>
                                    </div>
                                    <TourSlider
                                        CurrencySymbol={this.props.selectedCurrency.symbol}
                                        currency={this.props.selectedCurrency}
                                        tours={recentData}
                                        heading={t("Recently Viewed")}
                                        saleEndsTimer
                                        timestamp={this.props.initData.configurations.timestamp}
                                        timezoneOffset={
                                            this.props.initData.configurations.timezone_offset
                                        }
                                        listName="Home page - Recently Viewed"
                                    />
                                </div>
                            )
                        }
                    </div>
                    {/* popular cities */}
                    <PopularCities Cities={popularDestinations} />
                    {/* experience */}
                    {
                        !isT4Fes && !!experienceData && !!experienceData.length
                        && <Experiences tags={experienceData} />
                    }
                    {/* free tours */}
                    <FreeTours />
                    {/* footer */}
                    <Footer />
                </div>
                <Menu
                    disableMenu={!isShareHidden}
                    history={this.props.history}
                />
            </div>
        )
    }
}

Home.propTypes = {
    popularDestinations: PropTypes.oneOfType([PropTypes.array]).isRequired,
    // promotions: PropTypes.oneOfType([PropTypes.array]).isRequired,
    toggleShare: PropTypes.func.isRequired,
    banners: PropTypes.oneOfType([PropTypes.array]).isRequired,
    recentData: PropTypes.oneOfType([PropTypes.array]).isRequired,
    experienceData: PropTypes.oneOfType([PropTypes.array]).isRequired,
    isShareHidden: PropTypes.bool.isRequired,
    // fetchHomeBanners: PropTypes.func.isRequired,
    // fetchPopularDestinations: PropTypes.func.isRequired,
    // fetchExperienceTourTags: PropTypes.func.isRequired,
    // fetchPromotions: PropTypes.func.isRequired,
    fetchRecentlyViewed: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    ...state.HomeReducer,
    ...state.MetaReducers,
    ...state.ShareReducers,
    ...state.MenuReducers,
    ...state.SearchReducer,
    ...state.CommonReducers,
    ...state.InitData,
    initData: state.InitData,
})
export default connect(mapStateToProps, {
    ...ShareAct,
    getMetaHome,
    homeSearchToogle,
    fetchHomeBanners,
    fetchPopularDestinations,
    fetchPromotions,
    fetchExperienceTourTags,
    fetchRecentlyViewed,
})(Home)
