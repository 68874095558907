/* eslint-disable camelcase */
import Constant from "../constants"
import {
    offlineRetrive,
    // _isEmpty
} from "../../common/tool/util"

let toursWishlist
export const initialState = {
    productId: 0,
    redirectTo: "",
    isProductFetching: false,
    product: {
        details: {
            A: "",
            AC: false,
            ACD: [{
                autoConfirmDays: "",
                autoConfirmHrs: 0,
                count: 0,
                endDate: "",
                info: "",
                isAutoConfirm: false,
                name: "",
                startDate: "",
            }],
            CN: 0,
            D: "",
            DS: "",
            DT: "",
            EC: "",
            ET: "",
            G: "",
            GB: "",
            I: "",
            IC: "",
            L: {
                audio: {
                    icon: "",
                    languages: [],
                },
                live: {
                    icon: "",
                    languages: [{
                        icon: "",
                        name: "",
                    }],
                },
            },
            M: "",
            N: "",
            OP: "",
            P: "",
            PG: [],
            PKG: false,
            S: "94",
            SC: "",
            SDS: "",
            SG: "",
            SP: "",
            ST: false,
            T: "",
            sub_title: [],
            expert: {
                logo: "",
                name: "",
                responds: {
                    time: "",
                    unit: "",
                },
            },
            promo_notices: [
                {
                    place_with_product_name: false,
                    text: "",
                    title: "",
                },
            ],
            tour_type_icons: [],
            hotelbeds: {
                address: "",
                amenities: [{
                    id: "",
                    name: "",
                    facilities: [{
                        name: "",
                        active: "",
                    }],
                }],
                attraction: "",
                avg_nightly_rate: "",
                avg_nightly_strike_rate: "",
                booking: {
                    check_in_date: "",
                    check_out_date: "",
                    guests: {
                        adults: 0,
                        children: 0,
                    },
                    nights: 0,
                    rooms: 0,
                },
                check_in_time: "",
                check_out_time: "",
                city: "",
                description: "",
                distance: 0,
                geocode: {
                    latitude: 0,
                    longitude: 0,
                },
                images: [{
                    url: "",
                }],
                name: "",
                phone: "",
                product_id: "",
                provider_code: "",
                rates: [{
                    TotalCost: 0,
                    adults: 0,
                    allotment: 0,
                    averageNightlyRate: "",
                    cancellationDescription: "",
                    childrenAges: null,
                    freeCancellable: "false",
                    hotelCancelPolicy: "",
                    name: "",
                    pax: "",
                    rateAccessCode: "",
                    rateClass: "",
                    rateComments: "",
                    rateKey: "",
                    rateType: "",
                    roomCode: "",
                    roomDescription: "",
                    roomType: "",
                    rooms: 0,
                    totalNight: 0,
                }],
                review_count: null,
                room_amenities: [{
                    available: false,
                    name: "",
                }],
                star_rating: 0,
            },
        },
        productItineraryLocation: {
            data: {
                desc: "",
                name: "",
                position: [{
                    location: [{
                        address: "",
                        full_address: "",
                        latitude: "",
                        longitude: "",
                        product_departure_id: "",
                        time: "",
                    }],
                    region: "",
                },
                {
                    location: [{
                        address: "",
                        full_address: "",
                        latitude: "",
                        longitude: "",
                        product_departure_id: "",
                        time: "",
                    }],
                    region: "",
                }],
                tip: "",
                type: "",
            },
            execute_time: "",
            message: "",
            ok: false,
        },
        productItineraryPricing: {
            I: null,
            IP: null,
            P: null,
        },
        productGReview: {},
        productReview: null,
        travelerPhotos: null,
        travelerPhotosGallary: [],
        recommended: [],
        inspiredBy: [],
        alsoViewed: [],
        recommendedProductId: 0,
    },
    subreviews: [],
    ismoreReview: false,
    fetchingReview: false,
    reviewNotFound: false,
    reviewPageNo: 0,
    isProductNotFound: false,
    isWishlisted: false,
    isFullItinerary: false,
    addReviewResultData: {
        ERROR: "",
    },
    uploadTravelPhotosUploded: false,
    uploadTravelPhotosResult: {},
    showSmallGroupToogle: false,
    showInstantConfirmationPopupToggle: false,
    isExpertQuestionPosted: false,
    noFurtherDatesAvailable: false,
}

export default function ProductReducers(state = initialState, action) {
    let isWishlisted = false
    let GReview = {}
    let { subreviews } = state
    switch (action.type) {
    case Constant.FETCH_PRODUCT_BY_ID:
        return {
            ...state,
            isProductFetching: true,
            subreviews: [],
            reviewPageNo: 0,
        }
    case Constant.FETCH_PRODUCT_BY_ID_SUCCESS:
        toursWishlist = offlineRetrive("toursWishlist")
        toursWishlist = toursWishlist !== null ? toursWishlist : []
        toursWishlist.map(tour => {
            if (tour.id === action.payload.I) {
                isWishlisted = true
            }
        })
        return {
            ...state,
            isProductFetching: false,
            product: {
                ...state.product,
                details: action.payload,
            },
            isWishlisted,
        }
    case Constant.FETCH_PRODUCT_BY_ID_FAILED:
        return { ...state, isProductNotFound: true }
    case Constant.FETCH_PRODUCT_BY_ID_RESET:
        return { ...state, ...initialState }
    case Constant.MORE_REVIEW:
        return {
            ...state,
            addReviewResultData: {
                ERROR: "",
            },
            ismoreReview: action.payload,
        }
    case Constant.SET_REVIEW_NOT_FOUND:
        return {
            ...state,
            reviewNotFound: action.payload,
        }
    case Constant.SHOW_SMALL_GROUP_TOGGLE:
        return { ...state, showSmallGroupToogle: action.payload }
    case Constant.SHOW_INSTANT_CONFIRMATION_POPUP_TOGGLE:
        return { ...state, showInstantConfirmationPopupToggle: action.payload }
    case Constant.ADD_REVIEW:
        return {
            ...state,
            addReviewResultData: {
                ERROR: "",
            },
        }
    case Constant.ADD_REVIEW_SUCCESS:
        return {
            ...state,
            addReviewResultData: action.payload,
        }
    case Constant.UPLOAD_TRAVELES_PHOTO:
        return { ...state, uploadTravelPhotosResult: {}, uploadTravelPhotosUploded: false }
    case Constant.UPLOAD_TRAVELES_PHOTO_SUCCESS:
        return {
            ...state,
            uploadTravelPhotosResult: action.payload,
            uploadTravelPhotosUploded: true,
        }
    case Constant.UPLOAD_TRAVELES_PHOTO_CLEAR:
        return {
            ...state,
            uploadTravelPhotosResult: {},
            uploadTravelPhotosUploded: false,
        }
    case Constant.SET_REVIEW_PAGE_NO:
        return { ...state, reviewPageNo: action.payload }
    case Constant.FETCH_REVIEW_DETAILS:
        return { ...state, fetchingReview: true }
    case Constant.FETCH_REVIEW_DETAILS_SUCCESS:
        subreviews = subreviews.concat(action.payload.data)
        return {
            ...state,
            subreviews,
            fetchingReview: false,
            reviewNotFound: action.payload.reviewNotFound,
        }
    case Constant.SET_FULL_ITINERARY_HIDDEN:
        return { ...state, isFullItinerary: action.payload }
    case Constant.CLEAR_PRODUCT_DETAIL:
        return { ...state, ...initialState }
    case Constant.FETCH_PRODUCT_ITINERARY_PRICING_SUCCESS:
        return { ...state, product: { ...state.product, productItineraryPricing: action.payload } }
    case Constant.FETCH_PRODUCT_PRICING_SUCCESS:
        return {
            ...state,
            product: {
                ...state.product,
                productItineraryPricing: {
                    ...state.product.productItineraryPricing,
                    ...action.payload,
                },
            },
        }
    case Constant.FETCH_EASY_BOOK_LOCATION_PICKER_SUCCESS:
        return { ...state, product: { ...state.product, productItineraryLocation: action.payload } }
    case Constant.FETCH_EASY_BOOK_LOCATION_PICKER_FAILED:
        return { ...state, productItineraryLocation: action.error }
    case Constant.FETCH_REVIEW_TRAVELER_PHOTOS_SUCCESS:
        return { ...state, product: { ...state.product, travelerPhotos: action.payload } }
    case Constant.FETCH_PRODUCT_ALSO_VIEWED_REQUEST:
        return { ...state, product: { ...state.product, recommendedProductId: action.payload } }
    case Constant.FETCH_PRODUCT_ALSO_VIEWED_SUCCESS:
        return {
            ...state,
            product: {
                ...state.product,
                // recommended: !_isEmpty(action.payload.productRecommended)
                //     ? action.payload.productRecommended : [],
                // inspiredBy: !_isEmpty(action.payload.productInspiredByItem)
                //     ? action.payload.productInspiredByItem : [],
                alsoViewed: action.payload.alsoViewed,
                recommendedProductId: action.payload.productId,
            },
        }
    case Constant.FETCH_EASY_BOOK_START_DATE_PICKER:
        return {
            ...state,
            product: { ...state.product, startDate: false },
            isStartDateFetching: true,
            isStartDateFetched: false,
        }
    case Constant.FETCH_EASY_BOOK_START_DATE_PICKER_SUCCESS:
        return {
            ...state,
            product: { ...state.product, startDate: action.payload },
            isStartDateFetching: false,
            isStartDateFetched: true,
            noFurtherDatesAvailable: !action.payload.is_ajax_call,
        }
    case Constant.FETCH_EASY_BOOK_START_DATE_PICKER_FAILED:
        return {
            ...state,
            isStartDateFetching: false,
            isStartDateFetched: false,
            noFurtherDatesAvailable: true,
        }
    case Constant.FETCH_START_NEXT_CALENDAR_DATES:
        return {
            ...state,
            isStartDateFetching: true,
            isStartDateFetched: false,
        }
    case Constant.PRODUCT_ID_UPDATED:
        return { ...state, productId: action.payload }
    case Constant.FETCH_PRODUCT_REVIEWS_SUCCESS:
        return { ...state, product: { ...state.product, productReview: action.payload } }
    case Constant.FETCH_GREVIEW_DETAILS: {
        return { ...state, productGReview: {}, fetchingReview: true }
    }
    case Constant.FETCH_PRODUCT_GREVIEWS_SUCCESS:
        GReview = {}
        GReview.C = "0"
        if (action.payload.data.reviews) {
            GReview.C = action.payload.data.reviews.length
            GReview.C = GReview.C.toString()
        }
        GReview.F = (action.payload.data.rating * 20)
        GReview.G = ""
        GReview.R = []
        GReview.V = []
        action.payload.data.reviews.map(item => {
            const temp = {
                D: item.time,
                F: (item.rating * 20),
                G: "",
                N: item.author_name,
                P: "",
                R: [],
                T: item.text,
                X: item.text,
            }
            GReview.V.push(temp)
        })
        return {
            ...state,
            fetchingReview: false,
            product: {
                ...state.product,
                productGReview: GReview,
            },
        }
    case Constant.FETCH_PRODUCT_GREVIEWS_FAILED:
        return { ...state, fetchingReview: false }
    case Constant.FETCH_REVIEW_TRAVELER_PHOTOS_LARGE_SUCCESS:
        return { ...state, product: { ...state.product, travelerPhotosGallary: action.payload } }
    case Constant.PRODUCT_SHOULD_REDIRECT:
        return { ...state, redirectTo: action.payload }
    case Constant.PRODUCT_NOT_FOUND:
        return { ...state, isProductFetching: false, isProductNotFound: true }
    case Constant.RESET_PRODUCT_NOT_FOUND:
        return { ...state, isProductNotFound: false }
    case Constant.EXPERT_QUESTION_POSTED:
        return { ...state, isExpertQuestionPosted: true }
    case Constant.IS_WISHLISTED: {
        // check product id matches
        // because this reducer may be dispatched from listing page
        if (state.product.details.I === action.payload.productId) {
            return { ...state, isWishlisted: action.payload.isWishlisted }
        }
        return state
    }
    default:
        return state
    }
}
