import Constant from "../constants"

export const initialState = {
    isMenuHidden: true,
    showMainMenuValue: true,
    showDestinationsValue: false,
    showExperience: false,
    showLanguageMenuValue: false,
    destinationsDataError: {
        ERROR: "",
    },
    destinationsData: {
        ok: false,
        message: "",
        execute_time: "",
        data: {
            href: "",
            id: "",
            name: "",
            links: [{
                href: "",
                id: "",
                name: "",
                links: [{
                    href: "",
                    id: "",
                    name: "",
                    image: "",
                    links: [{
                        href: "",
                        name: "",
                    }],
                }],
            }],
        },
    },
}
export default function MenuReducers(state = initialState, action) {
    switch (action.type) {
    case Constant.SHOW_MENU_TOGGLE:
        return {
            ...state,
            isMenuHidden: action.payload,
            showMainMenuValue: true,
            showDestinationsValue: false,
            showLanguageMenuValue: false,
            showExperience: false,
        }
    case Constant.SHOW_MENU:
        return { ...state, showMainMenuValue: action.payload }
    case Constant.SHOW_MENU_DESTINATIONS:
        return {
            ...state,
            showDestinationsValue: action.payload,
        }
    case Constant.SHOW_LANGUAGE_MENU:
        return { ...state, showLanguageMenuValue: action.payload }
    case Constant.SHOW_EXPERIENCE_MENU:
        return { ...state, showExperience: action.payload }
    case Constant.MENU_DESTINATIONS_DATA_SUCCESS:
        return { ...state, destinationsData: action.payload }
    case Constant.MENU_DESTINATIONS_DATA_ERROR:
        return { ...state, destinationsDataError: action.payload }
    default:
        return state
    }
}
