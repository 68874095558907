/**
 * Google Tag Manager Enhance Ecommers tracking helper
 *
 * @author Gihan S <gihanshp@gmail.com>
 */

/**
 * Push data to GTM dataLayer
 *
 * @param {object} ecommerce ecommerce object to send
 * @param {string} event     Event string. Default null
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
function push(ecommerce, event = null) {
    if (window && window.dataLayer) {
        const data = { ecommerce }
        if (event) {
            data.event = event
        }
        window.dataLayer.push(data)
    } else if (window) {
        console.error(new Error(`window.dataLayer is ${typeof window.dataLayer === "undefined" ? "undefined" : "empty"}`))
    }
}

/**
 * Track product impressions
 *
 * @param {array} impressions   Product object array
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushProductImpressions = impressions => {
    push({ impressions }, "productImpression")
}

/**
 * Push product click data
 *
 * @param {string} list         List Name
 * @param {string} name         Product name
 * @param {string} id           Product Id
 * @param {string} category     Category
 * @param {number} position     Position in listing
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushProductClick = (list, name, id, category, position) => {
    push(
        {
            click: {
                actionField: {
                    list,
                },
                products: [{
                    name,
                    id,
                    category,
                    position,
                }],
            },
        },
        "productClick",
    )
}

/**
 * Measure product detail
 *
 * @param {string} name         Product name
 * @param {string} id           Product id
 * @param {string} category     Product category
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushProductDetail = (name, id, category) => {
    push(
        {
            detail: {
                products: [{
                    name,
                    id,
                    category,
                }],
            },
        },
        "productDetail",
    )
}

/**
 * Track add to cart event
 *
 * @param {string} name         Product name
 * @param {string} id           Product Id
 * @param {string} category     Product category
 * @param {number} quantity     Product quntity
 * @param {string} region       Product region name
 * @param {string} activityType Product activity type name
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushAddToCart = (name, id, category, quantity = 1, region = "", activityType = "") => {
    push(
        {
            add: {
                products: [{
                    name,
                    id,
                    category,
                    quantity,
                    region,
                    activityType,
                }],
            },
        },
        "addToCart",
    )
}

/**
 * Track remove from cart
 *
 * @param {string} name         Product name
 * @param {string} id           Product Id
 * @param {string} category     Product category
 * @param {number} quantity     Product quantity
 *
 * @uathor Gihan S <gihanshp@gmail.com>
 */
export const pushRemoveFromCart = (name, id, category, quantity = 1) => {
    push(
        {
            remove: {
                products: [{
                    name,
                    id,
                    category,
                    quantity,
                }],
            },
        },
        "removeFromCart",
    )
}

/**
 * Measure checkout steps
 *
 * @param {number} step         Checkout step
 * @param {string} name         Product name
 * @param {number} id           Product Id
 * @param {string} category     Product category
 * @param {number} quantity     Product quantity
 * @param {string} activityType Activity type name
 * @param {string} region       Region name
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const pushCheckoutStep = (
    step,
    name,
    id,
    category,
    quantity = 1,
    activityType = "",
    region = "",
) => {
    push(
        {
            checkout: {
                actionField: {
                    step,
                },
                products: [{
                    name,
                    id,
                    category,
                    quantity,
                    activityType,
                    region,
                }],
            },
        },
        "checkout",
    )
}

/**
 * Track easybook
 *
 * @param {string} name         Product name
 * @param {string} id           Product Id
 * @param {string} category     Product category
 * @param {number} quantity     Product quantity. Default 1
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushOnEasyBook = (name, id, category, quantity = 1) => {
    pushCheckoutStep(1, name, id, category, quantity)
}

/**
 * Measure on checkout page
 *
 * @param {string} name         Product name
 * @param {string} id           Product Id
 * @param {string} category     Product category
 * @param {number} quantity     Product quantity, Default 1
 * @param {string} activityType Activity type name
 * @param {string} region       Product region name
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushOnCheckout = (
    name,
    id,
    category,
    quantity = 1,
    activityType = "",
    region = "",
) => {
    pushCheckoutStep(2, name, id, category, quantity, activityType, region)
}

/**
 * Measure on checkout page
 *
 * @param {string} name         Product name
 * @param {string} id           Product Id
 * @param {string} category     Product category
 * @param {number} quantity     Product quantity, Default 1
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushOnThankYou = (name, id, category, quantity = 1) => {
    pushCheckoutStep(3, name, id, category, quantity)
}


/**
 * Push easybook selection
 *
 * @param {object} checkoutOption Checkout options selected by user
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushEasyBookOption = checkoutOption => {
    push(
        {
            checkout_option: {
                actionField: {
                    step: 1,
                    option: checkoutOption,
                },
            },
        },
        "checkoutOption",
    )
}

/**
 * Track purchase
 *
 * @param {string} currencyCode     Currency code
 * @param {string} orderId          Order ID
 * @param {number} orderTotalAmount Total order amount with taxes and other
 * @param {array}  products         Products in order
 * @param {number} totalTax         Total tax for order
 * @param {number} totalShipping    Total shipping charges for order
 * @param {string} coupon           Coupon code
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const pushPurchase = (
    currencyCode,
    orderId,
    orderTotalAmount,
    products,
    totalTax = 0,
    totalShipping = 0,
    coupon = "",
    createdTime = "",
    customerName = "",
) => {
    push(
        {
            currencyCode,
            purchase: {
                actionField: {
                    id: orderId,
                    revenue: orderTotalAmount,
                    tax: totalTax,
                    shipping: totalShipping,
                    coupon,
                    createdTime,
                    customerName,
                },
                products,
            },
        },
        "purchase",
    )
}

export default push
