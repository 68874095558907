import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only"
import "whatwg-fetch"

// import { _nodeFetch as fetch } from "node-fetch"
import {
    getUrl,
    handleParams,
} from "./index"

let controller = new AbortController()

/**
 * Abortable Get api
 *
 * @param {string} endpoint endpoint
 * @param {object} params Query params
 *
 * @return Promise
 * @author Pratik B <pratikb.bipl@gmail.com>
 */
export default function getWithAbort(endpoint, params = false) {
    let url = getUrl(endpoint)
    if (params) {
        url = getUrl(endpoint, params)
    }
    controller.abort()
    controller = new AbortController()
    return fetch(url, { signal: controller.signal })
        .then(response => response.json())
        .then(data => Promise.resolve(data))
        .catch(ex => Promise.reject(ex))
}

/**
 * Abortable Post api
 *
 * @param {string} endpoint endpoint
 * @param {object} payload payload
 * @param {object} params params
 * @param {boolean} isFormData if sent file pass "true" else "false"
 *
 * @return Promise
 * @author Pratik B <pratikb.bipl@gmail.com>
 */

export function postWithAbort(
    endpoint,
    payload = {},
    params = {},
    isFormData = false,
) {
    const reqHeaderAC = new Headers()
    reqHeaderAC.append("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8;")
    let headers
    // If formadata have a file then use first header
    if (isFormData) {
        headers = {
            method: "POST",
            body: params,
            credentials: "include",
            contentType: false,
            processData: false,
        }
    } else {
        headers = {
            method: "POST",
            body: handleParams(params),
            headers: reqHeaderAC,
            credentials: "include",
        }
    }
    const url = getUrl(endpoint, payload)
    controller.abort()
    controller = new AbortController()
    headers.signal = controller.signal
    // performance profiling for api POST request
    const start = new Date().getTime() // mark profiling start
    return fetch(url, headers)
        .then(rs => rs.json())
        .then(data => {
            const end = new Date().getTime() // mark profiling end
            console.log("Abortable POST OK", (end - start) + "ms", url)
            return data
        })
        .catch(err => {
            const end = new Date().getTime() // mark profiling end
            console.log("Abortable POST ERROR", (end - start) + "ms", url)
            return Promise.reject(err)
        })
}

/**
 * Terminate last Api request
 *
 * @return void
 * @author Pratik B <pratikb.bipl@gmail.com>
 */
export function abortPendingApi() {
    controller.abort()
}
