/**
 * Push data to GTM dataLayer
 *
 * @param {object} ecommerce ecommerce object to send
 * @param {string} event     Event string. Default null
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
function push(data, event = null) {
    if (typeof window !== "undefined" && window.dataLayer) {
        if (event) {
            data.event = event
        }
        window.dataLayer.push(data)
    } else if (typeof window !== "undefined") {
        console.error(new Error(`window.dataLayer is ${typeof window.dataLayer === "undefined" ? "undefined" : "empty"}`))
    }
}

/**
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings#implementation
 *
 * @param {string} category Category
 * @param {string} varr     Variable
 * @param {number} value    Time in milliseconds. Should be integer value
 * @param {string} label    [Optional] Extra label
 *
 * @returns {void}
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
// eslint-disable-next-line import/prefer-default-export
export function sendTiming(category, varr, value, label) {
    const obj = {
        timingCategory: category,
        timingVar: varr,
        timingValue: value,
    }
    if (label) {
        obj.timingLabel = label
    }
    push(obj, "timingEvent")
}
