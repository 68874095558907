/* eslint-disable camelcase */
import Constant from "../constants"
import { get, post } from "../../common/api"
import { postWithAbort } from "../../common/api/extraFunction"
import {
    _find,
    _trim,
    _isEmpty,
    _isFunction,
    sessionRetrive,
    offlineRetrive,
    addToRecentlyViewed,
    removeFromRecentlyViewed,
    fixImageUrl,
    _remove,
    _now,
    offlineStore,
} from "../../common/tool/util"
import { getStartDatePicker } from "../../../config/requests"
import { easyBook as easyBookOption } from "./BookingOptionActions"
import { AddToWishlist as addToWishList, removeToWishlist as removeFromWishList } from "./WishlistActions"
import { t } from "../../../config"

function getRandomSize(min, max) {
    return Math.round((Math.random() * (max - min)) + min)
}

function fetchReviewDetailsSuccess(payload) {
    return {
        type: Constant.FETCH_REVIEW_DETAILS_SUCCESS,
        payload,
    }
}

function fetchGReviewDetailsSuccess(payload) {
    return {
        type: Constant.FETCH_PRODUCT_GREVIEWS_SUCCESS,
        payload,
    }
}

function fetchGReviewDetailsError(err) {
    return {
        type: Constant.FETCH_PRODUCT_GREVIEWS_FAILED,
        error: err,
    }
}

function fetchProductItineraryPricingSuccess(data) {
    const tmp = [
        { N: "Special Notes", T: "", key: "package_special_notes" },
        { N: "Reservation Process and E-Ticket", T: t("No Details available"), key: "reservation_process" },
        { N: "Terms and Conditions", T: t("No Details available"), key: "terms_conditions" },
    ]
    data.I.T.map(item => {
        if (tmp[0].key === item.K) {
            tmp[0].T = item.T
        }
        if (tmp[1].key === item.K) {
            tmp[1].T = item.T
        }
        if (tmp[2].key === item.K) {
            tmp[2].T = item.T
        }
    })
    data.I.T = tmp

    return {
        type: Constant.FETCH_PRODUCT_ITINERARY_PRICING_SUCCESS,
        payload: data,
    }
}

function fetchEasyBookStartDatePickerSuccess(data) {
    return {
        type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER_SUCCESS,
        payload: data,
    }
}

function fetchEasyBookStartDatePickerFailed(error) {
    return {
        type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER_FAILED,
        error,
    }
}

function fetchTravelerPhotosSuccess(data) {
    return {
        type: Constant.FETCH_REVIEW_TRAVELER_PHOTOS_SUCCESS,
        payload: data,
    }
}

function fetchTravelerPhotosFailed(error) {
    return {
        type: Constant.FETCH_REVIEW_TRAVELER_PHOTOS_FAILED,
        error,
    }
}

function fetchTravelerPhotosLargeSuccess(data) {
    const newData = []
    for (let i = 0; i < data.length; i += 1) {
        newData.push({
            title: data[i].title,
            src: fixImageUrl(data[i].name, getRandomSize(250, 300), getRandomSize(200, 400)),
        })
    }
    return {
        type: Constant.FETCH_REVIEW_TRAVELER_PHOTOS_LARGE_SUCCESS,
        payload: newData,
    }
}

function fetchAlsoViewedSuccess(data) {
    return {
        type: Constant.FETCH_PRODUCT_ALSO_VIEWED_SUCCESS,
        payload: data,
    }
}

function fetchAlsoViewedFailed(error) {
    return {
        type: Constant.FETCH_PRODUCT_ALSO_VIEWED_FAILED,
        error,
    }
}

function fetchEasyBookLocationPickerSuccess(data) {
    return {
        type: Constant.FETCH_EASY_BOOK_LOCATION_PICKER_SUCCESS,
        payload: data,
    }
}

function fetchEasyBookLocationPickerFailed(error) {
    return {
        type: Constant.FETCH_EASY_BOOK_LOCATION_PICKER_FAILED,
        error,
    }
}

function fetchProductByUrlSuccess(data) {
    return {
        type: Constant.FETCH_PRODUCT_BY_URL_SUCCESS,
        payload: data,
    }
}

function fetchProductByUrlFailed(error) {
    return {
        type: Constant.FETCH_PRODUCT_BY_URL_FAILED,
        error,
    }
}

function fetchProductByIdSuccess(data) {
    return {
        type: Constant.FETCH_PRODUCT_BY_ID_SUCCESS,
        payload: data,
    }
}

function fetchProductByIdFailed(error) {
    return {
        type: Constant.FETCH_PRODUCT_BY_ID_FAILED,
        error,
    }
}

function fetchProductReviewsSuccess(data) {
    return {
        type: Constant.FETCH_PRODUCT_REVIEWS_SUCCESS,
        payload: data,
    }
}

function fetchProductReviewsFailed(error) {
    return {
        type: Constant.FETCH_PRODUCT_REVIEWS_FAILED,
        error,
    }
}

export const resetProductId = () => dispatch => dispatch({
    type: Constant.FETCH_PRODUCT_BY_ID_RESET,
})

export const updateProductId = (productId = 0) => dispatch => dispatch({
    type: Constant.PRODUCT_ID_UPDATED,
    payload: productId,
})

function productShouldRedirect(url) {
    return {
        type: Constant.PRODUCT_SHOULD_REDIRECT,
        payload: url,
    }
}

function productNotFound() {
    return {
        type: Constant.PRODUCT_NOT_FOUND,
        payload: true,
    }
}

export const clearProductDetail = () => dispatch => {
    dispatch({
        type: Constant.CLEAR_PRODUCT_DETAIL,
    })
}

export const resetProductNotFound = () => dispatch => {
    dispatch({
        type: Constant.RESET_PRODUCT_NOT_FOUND,
    })
}

export const moreReview = payload => dispatch => dispatch({
    type: Constant.MORE_REVIEW,
    payload,
})

const toggleFullItinerary = isFullItinerary => dispatch => {
    dispatch({
        type: Constant.SET_FULL_ITINERARY_HIDDEN,
        payload: isFullItinerary,
    })
}

/**
 * Fetch product itinerary pricing
 *
 * @param {int}    productId Product ID
 * @param {string} imgSize   Image size
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const fetchProductItineraryPricing = (productId, imgSize) => dispatch => get("/product/getProductItineraryPricing", {
    product_id: productId,
    _v: 3.1,
    sections: "I",
    _img_size: imgSize,
})
    .then(data => {
        if (!data.ERROR) {
            dispatch(fetchProductItineraryPricingSuccess(data))
        } else {
            removeFromRecentlyViewed(productId)
            dispatch(productNotFound())
        }
    })
    .catch(error => console.error(error))

/**
 * Fetch product pricing
 *
 * @param {int}    productId Product ID
 * @param {string} imgSize   Image size
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const fetchProductPricing = productId => dispatch => get(
    "/product/getProductItineraryPricing",
    {
        product_id: productId,
        sections: "P",
        _v: 3.1,
    },
)
    .then(data => {
        if (!data.ERROR) {
            dispatch({
                payload: data,
                type: Constant.FETCH_PRODUCT_PRICING_SUCCESS,
            })
        }
    })
    .catch(error => console.error(error))

/**
 * Fetch start dates
 *
 * @param {int} productId Product ID
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const fetchStartDatePicker = productId => dispatch => {
    dispatch({
        type: Constant.FETCH_EASY_BOOK_START_DATE_PICKER,
    })
    get(
        getStartDatePicker.path,
        Object.assign(getStartDatePicker.params, { product_id: productId }),
    ).then(data => {
        dispatch(fetchEasyBookStartDatePickerSuccess(data.data))
        dispatch(easyBookOption({
            product_id: productId,
            tran_id: "transportation",
            tran_option_id: 1,
        }))
    })
        .catch(error => dispatch(fetchEasyBookStartDatePickerFailed(error)))
}

/**
 * Fetch travler photos
 *
 * @param {int}    productId Product Id
 * @param {string} imgSize   Image size string. Default to empty string
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const fetchTravelerPhotos = (productId, imgSize = "") => dispatch => {
    const params = { product_id: productId }
    if (imgSize !== "") {
        params.imgSize = imgSize
    }
    return get("/review/getTravelerPhotos", params)
        .then(data => {
            dispatch(fetchTravelerPhotosSuccess(data))
            dispatch(fetchTravelerPhotosLargeSuccess(data))
        })
        .catch(error => dispatch(fetchTravelerPhotosFailed(error)))
}

/**
 * Fetch Also viewed products
 *
 * @param {int} productId Product Id
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const fetchAlsoViewed = productId => (dispatch, getState) => {
    const RdxProduct = getState().ProductReducers.product
    if (
        Number(RdxProduct.details.I) === 0
        || RdxProduct.details.I !== productId
        || RdxProduct.recommendedProductId === productId
    ) {
        return null
    }
    dispatch({
        type: Constant.FETCH_PRODUCT_ALSO_VIEWED_REQUEST,
        payload: productId,
    })
    return get("/product/getAlsoViewed", {
        product_id: productId,
        _img_size: "552x",
        _v: 1,
    }).then(data => dispatch(fetchAlsoViewedSuccess({
        alsoViewed: data,
        productId,
    })))
        .catch(error => dispatch(fetchAlsoViewedFailed(error)))
}

/**
 * Fetch location picker
 *
 * @param {int}     productId      Product ID
 * @param {string}  startDate      Tour start date
 * @param {string}  checkoutDate   Hotel checkout date
 * @param {string}  roomAdultTotal Room adult distribuion
 * @param {string}  roomChildTotal Room children distribution
 * @param {boolean} cached         Get cached locations?
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const fetchEasyBookLocationPicker = (
    productId,
    startDate,
    checkoutDate,
    roomAdultTotal,
    roomChildTotal,
    cached = false,
    isProductPage = false,
) => dispatch => {
    const params = {
        product_id: productId,
        start_date: startDate,
        checkout_date: checkoutDate,
        room_adult_total: roomAdultTotal,
        room_child_total: roomChildTotal,
        is_product_page: isProductPage,
    }
    if (cached) {
        params.cached = 1
    }
    return get("/easyBookNew/getLocationPicker", params)
        .then(data => dispatch(fetchEasyBookLocationPickerSuccess(data)))
        .catch(error => dispatch(fetchEasyBookLocationPickerFailed(error)))
}

/**
 * Fetch product by product id
 *
 * @param {int} productId Product Id
 * @param {string} startDate Start date as Y-m-d
 * @param {string} checkoutDate Check-out date as Y-m-d
 * @param {string} roomAdultTotal Adult distribution in rooms @Verify
 * @param {strign} roomChildTotal Children distribution in rooms @Verify
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const fetchProductById = (
    productId,
    startDate,
    checkoutDate,
    roomAdultTotal,
    roomChildTotal,
    latitude,
    longitude,
    imgSize,
) => dispatch => {
    dispatch({
        type: Constant.FETCH_PRODUCT_BY_ID,
    })
    get("/product/getProductInfo", {
        productID: productId,
        start_date: startDate,
        checkout_date: checkoutDate,
        room_adult_total: roomAdultTotal,
        room_child_total: roomChildTotal,
        latitude,
        longitude,
        _img_size: imgSize,
    })
        .then(data => {
            dispatch(fetchProductByIdSuccess(data))
        })
        .catch(error => dispatch(fetchProductByIdFailed(error)))
}

const fetchProduct = (
    productURL,
    startDate,
    checkoutDate,
    roomAdultTotal,
    roomChildTotal,
) => dispatch => get("/product/getProductByUrl", {
    url: productURL,
    start_date: startDate,
    checkout_date: checkoutDate,
    room_adult_total: roomAdultTotal,
    room_child_total: roomChildTotal,
})
    .then(Mdata => {
        dispatch(fetchProductByUrlSuccess(Mdata))
    })
    .catch(err => dispatch(fetchProductByUrlFailed(err)))

/**
 * Intiate product page
 *
 * @param {string}   productURL       Product url path
 * @param {string}   startDate        Start date
 * @param {string}   checkoutDate     Check-Out date
 * @param {int}      roomAdultTotal   Adult distribution for rooms
 * @param {int}      roomChildTotal   Children distribution for rooms
 * @param {float}    latitude         Latitiude
 * @param {float}    longitude        Longitude
 * @param {int}      pageNo           Reviews page no
 * @param {int}      limit            Reviews per page
 * @param {string}   itineraryImgSize Image size for itinerary
 * @param {string}   imgSize          Image size
 * @param {int}      _v               Version
 * @param {function} redirectFunc     Redirect function
 */
export const shouldRedirectProduct = (
    productURL,
    startDate,
    checkoutDate,
    roomAdultTotal,
    roomChildTotal,
    latitude,
    longitude,
    imgSize,
) => dispatch => {
    dispatch({
        type: Constant.FETCH_PRODUCT_BY_ID,
    })
    get(
        "/product/shouldRedirectProduct",
        {
            url_path: _trim(productURL, "/"),
            _v: 3,
            start_date: startDate,
            checkout_date: checkoutDate,
            room_adult_total: roomAdultTotal,
            room_child_total: roomChildTotal,
            latitude,
            longitude,
            _img_size: imgSize,
        },
    )
        .then(response => {
            if (response.ok && !_isEmpty(response.data)) {
                if (response.data.redirect) {
                    // should dispatch redirection
                    dispatch(productShouldRedirect("/" + _trim(response.data.url_path, "/")))
                } else if (response.data.product_id > 0) {
                    // update Id and dispatch product id change
                    if (response.data.product) {
                        // Add product to Recently Viewed (Without SSR)
                        addToRecentlyViewed(
                            response.data.product.old_product_id
                                ? response.data.product.old_product_id
                                : response.data.product.I,
                        )
                        dispatch(fetchProductByIdSuccess(response.data.product))
                        if (
                            response.data.product.rezb2bProduct
                            && !_isEmpty(response.data.product.rezb2bProduct.pickup_locations)
                        ) {
                            dispatch(fetchEasyBookLocationPickerSuccess({
                                data: response.data.product.rezb2bProduct.pickup_locations,
                            }))
                        }
                    } else {
                        dispatch(fetchProductByIdFailed("Product Not Found"))
                    }
                    dispatch(updateProductId(response.data.product_id))
                } else {
                    // this could be a malformed url, dispatch not found
                    dispatch(productNotFound())
                }
            } else {
                // something went wrong, dispatch not found
                dispatch(productNotFound())
            }
        }).catch(() => dispatch(productNotFound()))
}

/**
 * Fetch product reviews
 *
 * @param {int} productId Product ID
 * @param {int} limit     Limit of reviews to fetch
 * @param {int} pageNo    Page no
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const fetchProductReviews = (productId, limit, pageNo) => dispatch => post("/review/getReviewsInfo", {
    product_id: productId, limit, page_no: pageNo, satisfaction: true,
})
    .then(data => dispatch(fetchProductReviewsSuccess(data)))
    .catch(error => dispatch(fetchProductReviewsFailed(error)))

/**
 * Dispatch expert question status
 *
 * @author Gihan S <gihanshp@gmailcom>
 */
export const expertQuestionPosted = () => dispatch => {
    dispatch({
        type: Constant.EXPERT_QUESTION_POSTED,
    })
}

const search = params => get("/product/getCityProducts", params)

const getNearbyHotels = (latitude, longitude, checkIn, checkOut, adults, children, exclude) => {
    const queryParams = {
        latitude,
        longitude,
        check_in: checkIn,
        check_out: checkOut,
        adults,
        children,
        exclude,
    }
    return get("/product/getHotelsNearby", queryParams)
}
export const getReviews = (productId, pageNo, limit) => dispatch => {
    dispatch({
        type: Constant.FETCH_REVIEW_DETAILS,
    })
    post("/review/getReviewsInfo", {}, {
        product_id: productId, limit, page_no: pageNo, satisfaction: true,
    })
        .then(data => {
            if (typeof data.V !== "undefined" && data.V.length > 0) {
                dispatch(fetchReviewDetailsSuccess({
                    data: data.V,
                    reviewNotFound: false,
                }))
            } else if (typeof data.V !== "undefined" && data.V.length === 0) {
                dispatch(fetchReviewDetailsSuccess({
                    data: [],
                    reviewNotFound: true,
                }))
            }
        }).catch(err => {
            console.error(err)
        })
}
export const getReviewFromGooglePlace = () => dispatch => {
    dispatch({
        type: Constant.FETCH_GREVIEW_DETAILS,
    })
    window.reviewService.nearbySearch(window.reviewLocationObj, placeRes => {
        if (placeRes.length > 0) {
            window.reviewService.getDetails({
                placeId: placeRes[0].place_id,
                language: "en",
            }, (result, status) => {
                if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                    return dispatch(fetchGReviewDetailsError(status))
                }
                return dispatch(fetchGReviewDetailsSuccess({
                    data: result,
                }))
            })
        } else {
            dispatch(fetchGReviewDetailsError("No place found"))
        }
    })
}
export const addReview = formElement => dispatch => {
    dispatch({
        type: Constant.ADD_REVIEW,
    })
    const reviewData = new FormData(formElement)
    post("/review/saveReviewsInfo", {}, reviewData, true)
        .then(data => dispatch({
            type: Constant.ADD_REVIEW_SUCCESS,
            payload: data,
        }))
        .catch(err => {
            console.error(err)
            dispatch({
                type: Constant.ADD_REVIEW_ERROR,
            })
        })
}
export const clearTravelPhotosDetails = () => dispatch => {
    dispatch({
        type: Constant.UPLOAD_TRAVELES_PHOTO_CLEAR,
    })
}
export const uploadTravelPhotos = (newFd, successCB) => dispatch => {
    dispatch({
        type: Constant.ADD_REVIEW,
    })
    post("/review/uploadTravelerPhotos", {}, newFd, true)
        .then(data => {
            if (data.ERROR) {
                alert(t(data.ERROR))
                return dispatch({
                    type: Constant.UPLOAD_TRAVELES_PHOTO_ERROR,
                })
            }
            alert(t("Thank you for sharing your photos with us") + ". " + t("Your files are waiting for approval"))
            if (_isFunction(successCB)) {
                successCB()
            }
            return dispatch({
                type: Constant.UPLOAD_TRAVELES_PHOTO_SUCCESS,
                payload: data,
            })
        }).catch(err => {
            alert(t("Failed to upload") + ". " + t("Please try again") + ".")
            console.error(err)
            return dispatch({
                type: Constant.UPLOAD_TRAVELES_PHOTO_ERROR,
            })
        })
}
export const showSmallGroup = param => dispatch => {
    dispatch({
        type: Constant.SHOW_SMALL_GROUP_TOGGLE,
        payload: param,
    })
}
export const showInstantConfirmationPopup = param => dispatch => {
    dispatch({
        type: Constant.SHOW_INSTANT_CONFIRMATION_POPUP_TOGGLE,
        payload: param,
    })
}

const updateEasyBookPrice = params => dispatch => {
    const attributesOption = {}
    const easyBookData = sessionRetrive("bookingOption")[params]
    const pickedKeys = Object.keys(easyBookData)
    pickedKeys.forEach(k => {
        if (k.indexOf("attribute") > -1 && k.indexOf("_name") === -1) {
            attributesOption[k.replace("attribute", "id")] = easyBookData[k]
        }
    })
    let papilloDeptTime
    if (easyBookData.location_departure_time_name) {
        // destructuring assignment for eslint
        const temp = easyBookData.location_departure_time_name[0]
        papilloDeptTime = temp
    }
    const bookInfos = {
        products_id: params,
        availabletourdate: easyBookData.start_date + "::##!!!",
        room_total: easyBookData.room_total,
        room_adult_total: easyBookData.room_adult_total,
        room_child_total: easyBookData.room_child_total,
        room_type: easyBookData.room_type,
        attributes_option: attributesOption,
        h_e_id: easyBookData.h_e_id,
        hotel_pick_up_location: easyBookData.hotel_pick_up_location,
        pick_up_location: easyBookData.location,
        papillo_dept_loca: easyBookData.papillo_dept_loca,
        papillo_dept_time: papilloDeptTime,
        show_time: easyBookData.show_time,
    }
    postWithAbort("/cart/addCompleteCart", {}, { bookInfos: JSON.stringify([bookInfos]) }).then(() => {
        get("/cart/getProductPrice", { product_id: params }).then(data => {
            dispatch({
                type: Constant.EASY_BOOK_UPDATE,
                payload: data.data,
            })
        })
    })
}
const easyBook = params => (dispatch, getState) => {
    dispatch({
        type: Constant.EASY_BOOK,
    })
    const bookData = []
    const getProductPrice = () => get("/cart/getProductPrice", { product_id: params.product_id }).then(data => {
        bookData.push(data)
        dispatch({
            type: Constant.EASY_BOOK_SUCCESS,
            payload: bookData,
        })
    })
    const addToCart = () => {
        const attributesOption = {}
        const sessionData = sessionRetrive("bookingOption")
        const retrivedData = sessionData && sessionData[params.product_id]
        const easyBookData = retrivedData || bookData[0].data.picked
        const pickedKeys = Object.keys(easyBookData)
        pickedKeys.forEach(k => {
            if (k.indexOf("attribute") > -1 && k.indexOf("_name") === -1) {
                attributesOption[k.replace("attribute", "id")] = easyBookData[k]
            }
        })
        let papilloDeptTime
        if (easyBookData.location_departure_time_name) {
            // destructuring assignment for eslint
            const temp = easyBookData.location_departure_time_name[0]
            papilloDeptTime = temp
        }
        const bookInfos = {
            products_id: params.product_id,
            availabletourdate: easyBookData.start_date + "::##!!!",
            room_total: easyBookData.room_total,
            room_adult_total: easyBookData.room_adult_total,
            room_child_total: easyBookData.room_child_total,
            room_type: easyBookData.room_type,
            attributes_option: attributesOption,
            h_e_id: easyBookData.h_e_id,
            hotel_pick_up_location: easyBookData.hotel_pick_up_location,
            pick_up_location: easyBookData.location,
            papillo_dept_loca: easyBookData.papillo_dept_loca,
            papillo_dept_time: papilloDeptTime,
            show_time: easyBookData.show_time,
        }
        postWithAbort("/cart/addCompleteCart", {}, { bookInfos: JSON.stringify([bookInfos]) }).then(() => {
            getProductPrice()
        })
    }
    const getPickerList = () => get("/easyBookNew/getPickerList", params).then(data => {
        const tempData = data.data.pickerList
        const adaptData = {}
        tempData.forEach(item => {
            adaptData[item.id] = item
        })
        data.data.pickerList = adaptData
        bookData.push(data)
        addToCart()
    })
    if (getState().LoginReducer.authenticated) {
        getPickerList()
    } else {
        get("/guest/loginAsGuest").then(() => {
            getPickerList()
        })
    }
}
export const getMetaPage = productId => dispatch => {
    dispatch({
        type: Constant.FETCH_META_DETAILS,
        payload: "product",
    })
    let param = {}
    if (productId !== "") {
        param = { product_id: productId }
    }
    param.isBrowserCache = true
    get("/product/getMetaInformation", param)
        .then(data => {
            dispatch({
                type: Constant.FETCH_META_DETAILS_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.log(err)
        })
}
export const reupdateWishlist = productId => dispatch => {
    // console.log("updateWishlistForProduct called")
    let isWishlisted = false
    if (offlineRetrive("toursWishlist")
        && !_isEmpty(offlineRetrive("toursWishlist"))) {
        const toursWishlist = offlineRetrive("toursWishlist")
        isWishlisted = !!(_find(
            toursWishlist,
            item => item.id + "" === productId + "",
        ))
    }
    dispatch({
        type: Constant.IS_WISHLISTED,
        payload: {
            productId,
            isWishlisted,
        },
    })
}

/**
 * Toggle product from wishlit
 *
 * @param {int} productId Product ID
 */
export const toggleWishlist = productId => (dispatch, getState) => {
    let toursWishlist = offlineRetrive("toursWishlist")
    const tourList = getState().ToursReducers.tours
    toursWishlist = toursWishlist !== null ? toursWishlist : []
    let isDeleted = false
    // remove
    _remove(toursWishlist, item => {
        if (+item.id === +productId) {
            tourList.map((Item, ind) => {
                if (+Item.I === +productId) {
                    tourList[ind].isWishlisted = false
                }
            })
            isDeleted = true
            return true
        }
        return false
    })


    if (!isDeleted) {
        tourList.map((Item, ind) => {
            if (+Item.I === +productId) {
                tourList[ind].isWishlisted = true
            }
        })
        dispatch({
            type: Constant.IS_WISHLISTED,
            payload: {
                productId,
                isWishlisted: true,
            },
        })
        addToWishList(
            productId,
            dispatch,
            () => {
                toursWishlist.push({ id: +productId, time: _now() })
                offlineStore("toursWishlist", toursWishlist)
                // dispatch for listing page update
                dispatch({
                    type: Constant.TOURS_LIST_PRODUCT_WISHLIST_CHANGE,
                    payload: {
                        productId,
                        isWishlisted: true,
                    },
                })
            },
            () => {
                // rollback on error
                dispatch({
                    type: Constant.IS_WISHLISTED,
                    payload: {
                        productId,
                        isWishlisted: false,
                    },
                })
            },
        )
    } else {
        dispatch({
            type: Constant.IS_WISHLISTED,
            payload: {
                productId,
                isWishlisted: false,
            },
        })
        removeFromWishList(
            productId,
            dispatch,
            () => {
                offlineStore("toursWishlist", toursWishlist)
                // dispatch for listing page update
                dispatch({
                    type: Constant.TOURS_LIST_PRODUCT_WISHLIST_CHANGE,
                    payload: {
                        productId,
                        isWishlisted: false,
                    },
                })
            },
            () => {
                // rollback on error
                dispatch({
                    type: Constant.IS_WISHLISTED,
                    payload: {
                        productId,
                        isWishlisted: true,
                    },
                })
            },
        )
    }
}

/**
 * Post expert question
 *
 * @param {string} name       Customer name
 * @param {string} email      Customer email
 * @param {string} question   Question
 * @param {int}    providerId Provider ID
 * @param {int}    productId  Product ID
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const postExpertQuestion = (
    name,
    email,
    question,
    providerId,
    productId,
) => (
    post("/product/postQuestion", {}, {
        customer_name: name,
        customer_email: email,
        customer_email_repeat: email,
        product_id: productId,
        provider_id: providerId,
        question,
    })
)

export const updateReviewNotFound = payload => dispatch => {
    dispatch({
        type: Constant.SET_REVIEW_NOT_FOUND,
        payload,
    })
}

export const updateReviewPageNo = payload => dispatch => {
    dispatch({
        type: Constant.SET_REVIEW_PAGE_NO,
        payload,
    })
}

const ProductAPI = {
    shouldRedirectProduct,
    reupdateWishlist,
    updateProductId,
    resetProductNotFound,
    moreReview,
    resetProductId,
    fetchProduct,
    getReviews,
    addReview,
    uploadTravelPhotos,
    clearTravelPhotosDetails,
    search,
    getNearbyHotels,
    toggleFullItinerary,
    clearProductDetail,
    easyBook,
    updateEasyBookPrice,
    showSmallGroup,
    showInstantConfirmationPopup,
    getMetaPage,
    getReviewFromGooglePlace,
    updateReviewNotFound,
    updateReviewPageNo,
}
export default ProductAPI
