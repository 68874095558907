import Constant from "../constants"
import {
    _find, offlineRetrive, initFilterData, initTabData,
} from "../../common/tool/util"

const intialTours = {
    tours: [],
    filter: initFilterData(),
    filterData: {
        all: {},
        available: {},
        footerFilter: {},
    },
    backFilterData: {},
    totalProducts: 0,
    filterTotalProducts: 0,
    isToursNotFound: false,
    isToursFetching: false,
    isSearchOpen: false,
    isFilterOpen: false,
    tabData: initTabData(),
    places: [],
    nearByMeQuickSearch: {
        geo: {
            lat: "",
            long: "",
        },
        products: [],
    },
}

export default function ToursReducers(state = intialTours, action) {
    let {
        tours,
        totalProducts,
        filterTotalProducts,
        isToursNotFound,
    } = state
    let toursWishlist
    let { filter } = state
    let tempObject
    let { places } = state
    switch (action.type) {
    case Constant.FILTER_RESET:
        filter = action.payload
        if (action.isEmptyTour) {
            tours = []
            filter.page = 1
            totalProducts = 0
            filterTotalProducts = 0
            isToursNotFound = false
        }
        return {
            ...state,
            filter,
            isToursNotFound,
            tours,
            totalProducts,
            filterTotalProducts,
            isToursFetching: false,
        }
    case Constant.SET_FILTER_ADVANCED:
        filter.advancedSearch = action.payload
        return {
            ...state,
            filter,
            filterTotalProducts: 0,
        }
    case Constant.FETCH_TOURS:
        return { ...state, isToursNotFound, isToursFetching: true }
    case Constant.FILTER_DATA_SET_IN:
        return {
            ...state,
            filterData: action.filterData,
            filterTotalProducts: action.filterTotalProducts,
        }
    case Constant.FETCH_TOURS_SUCCESS:
        tours = tours.concat(action.payload.products)
        toursWishlist = offlineRetrive("toursWishlist")
        toursWishlist = toursWishlist !== null ? toursWishlist : []
        places = action.payload.places ? action.payload.places : []
        toursWishlist.map(tour => {
            // tempObject = _find(tours, ["I", tour.id])
            tempObject = _find(tours, obj => obj.I === tour.id)
            if (tempObject) {
                tempObject.isWishlisted = true
            }
        })
        isToursNotFound = (
            action.payload.products.length === 0
            || (
                !(
                    action.payload.products.length === 8
                    || action.payload.products.length === 15
                )
            )
        ) || action.payload.noMore
        if (
            state.tabData && action.payload.mainFilter
            && typeof action.payload.mainFilter.entity_stats !== "undefined"
        ) {
            state.tabData = initTabData()
            state.tabData.map(item => {
                if (item.entityId === 3) {
                    return
                }
                item.isEnabled = Boolean(action.payload.mainFilter.entity_stats[item.entityId])
            })
        }
        state.tabData[0].isEnabled = true // All option will visible for any situation
        /**
         * @author Tony <tonys9204@gmail.com>
         * @date 2018-01-18
         * @start
         * this will lead some unexpexcted result,
         * @todo should use global variable carefully!!!
         *
         */
        filter = state.filter
        if (filter.product_entity_type + "" === "3") {
            if (action.payload.geocode) {
                filter.latitude = action.payload.geocode.latitude
                filter.longitude = action.payload.geocode.longitude
            }
        }
        filter.advancedSearch = action.advancedSearch
        return {
            ...state,
            tours,
            filter,
            isToursNotFound,
            places,
            totalProducts: action.payload.total_products,
            filterTotalProducts: action.payload.total_products,
            isToursFetching: false,
        }
    case Constant.FETCH_TOURS_ERROR:
        isToursNotFound = true
        filter = state.filter
        return { ...state, isToursNotFound, isToursFetching: false }
    case Constant.FETCH_TOURS_ERROR_ALGOLIA:
        filter = state.filter
        return { ...state, filter, isToursFetching: false }
    case Constant.CHANGED_SEARCH_VISIBILITY:
        return { ...state, isSearchOpen: action.isSearchOpen }
    case Constant.CHANGED_FILTER_VISIBILITY:
        return {
            ...state,
            isFilterOpen: action.isFilterOpen,
            filterData: action.filterData,
            filter: action.filter,
            backFilterData: action.backFilterData,
            backFilter: action.backFilter,
            filterTotalProducts: state.totalProducts,
        }
    case Constant.OFF_SREVER_FLAG_TOUR:
        toursWishlist = offlineRetrive("toursWishlist")
        toursWishlist = toursWishlist !== null ? toursWishlist : []
        toursWishlist.map(tour => {
            // tempObject = _find(tours, ["I", tour.id])
            tempObject = _find(tours, obj => obj.I === tour.id)
            if (tempObject) {
                tempObject.isWishlisted = true
            }
        })
        return {
            ...state,
            tours,
        }
    case Constant.FETCH_NEAR_BY_ME_TOUR_QUICK_SEARCH_SUCCESS:
        return {
            ...state,
            nearByMeQuickSearch: { ...action.payload },
        }
    case Constant.TOURS_LIST_PRODUCT_WISHLIST_CHANGE: {
        if (state.tours.length === 0) {
            return state
        }
        const toursList = state.tours.map(tour => {
            // is product exist in list?
            if (action.payload.productId === tour.I) {
                tour.isWishlisted = action.payload.isWishlisted
            }
            return tour
        })
        return {
            ...state,
            tours: toursList,
        }
    }
    default:
        return state
    }
}
