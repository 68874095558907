import constants from "../constants"
import { processHistoryQueue } from "../../common/tool/util"

/**
 * Trigger history queue process after FETCH_META_DETAILS_SUCCESS action
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const history = () => next => action => {
    if (typeof window !== "undefined" && action.type === constants.FETCH_META_DETAILS_SUCCESS) {
        processHistoryQueue()
    }
    const result = next(action)
    return result
}

/**
 * Log redux actions
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const logger = () => next => action => {
    console.info("Dispatched", action.type)
    return next(action)
}

export default history
