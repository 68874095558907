import Constant from "../constants"

const initialState = {
    ordersList: [],
    hasMoreResults: "",
    isOrderFetched: false,
    isOrderFetching: true,
    accountInformation: {
        userDetail: {},
        isFetching: false,
        isFetched: false,
    },
    savedTravelers: {
        travelerDetail: [],
        isTravelersFetched: false,
    },
    reviews: {
        isUploading: false,
        isUploaded: false,
    },
    error: {
        password: "",
        savedTravelers: "",
    },
    newsletters: {
        isNewslettersFetched: false,
        topics: [],
        regions: {
            sub: [],
        },
    },
}

export default function AccountReducers(state = initialState, action) {
    switch (action.type) {
    case Constant.FETCH_ORDERS_LIST_SUCCESS:
        return {
            ...state,
            ordersList: action.payload.ordersList,
            isOrderFetched: true,
            isOrderFetching: false,
            hasMoreResults: action.payload.hasMore,
        }
    case Constant.FETCH_ORDERS_REQUEST:
        return {
            ...state,
            isOrderFetched: false,
            isOrderFetching: true,
        }
    case Constant.GET_ACCOUNT_INFO_SUCCESS:
        return {
            ...state,
            accountInformation: {
                userDetail: {
                    ...action.payload,
                    fullname: `${action.payload.firstname} ${action.payload.lastname}`,
                },
                isFetching: false,
                isFetched: true,
            },
        }
    case Constant.UPDATE_ACCOUNT_INFO_REQUEST:
        return {
            ...state,
            accountInformation: {
                ...state.accountInformation,
                isFetching: true,
                isFetched: false,
            },
        }
    case Constant.UPDATE_ACCOUNT_INFO_SUCCESS: {
        const userDetail = {
            ...action.payload,
            fullname: `${action.payload.firstname} ${action.payload.lastname}`,
            telephone: decodeURIComponent(action.payload.telephone),
            cellphone: decodeURIComponent(action.payload.cellphone),
        }

        return {
            ...state,
            accountInformation: {
                userDetail,
                isFetching: false,
                isFetched: true,
            },
        }
    }
    case Constant.UPDATE_ACCOUNT_INFO_ERROR:
        return {
            ...state,
            accountInformation: {
                ...state.accountInformation,
                isFetching: false,
                isFetched: true,
            },
        }
    case Constant.FETCH_TRAVELER_DETAILS_SUCCESS:
        return {
            ...state,
            savedTravelers: {
                travelerDetail: action.payload,
                isTravelersFetched: true,
            },
        }
    case Constant.UPDATE_NEWSLETTERS_SUCCESS:
        return {
            ...state,
            newsletters: action.payload,
        }
    case Constant.TOGGLE_REVIEW_IS_UPLOADING:
        return {
            ...state,
            reviews: {
                isUploading: action.payload.isUploading,
                isUploaded: action.payload.isUploaded,
            },
        }
    case Constant.LOGOUT_USER_SUCCESS:
        // when user logout need to reset all object
        return {
            ...initialState,
        }
    case Constant.UPDATE_TRAVELER_DETAILS_SUCCESS:
        return {
            ...state,
            savedTravelers: {
                travelerDetail: action.payload,
                isTravelersFetched: true,
            },
        }
    case Constant.UPDATE_SAVED_TRAVELER_ERROR: {
        const error = { ...action.payload }
        if (typeof error.first_name !== "undefined") {
            error.fullname = error.first_name
            delete error.first_name
        }
        if (typeof error.last_name !== "undefined") {
            error.fullname = error.last_name
            delete error.last_name
        }
        return {
            ...state,
            error: {
                password: "",
                savedTravelers: error,
            },
        }
    }
    case Constant.UPDATE_ERROR: {
        return {
            ...state,
            error: action.payload,
        }
    }
    default: return state
    }
}
