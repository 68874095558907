import Constant from "../constants"

const initialState = {
    easyBookData: {
        execute_time: "",
        message: "",
        ok: false,
        data: {
            h_e_id: "0",
            h_l_id: "0",
            is_broadway_tour: "0",
            product_code: "",
            product_duration: 0,
            product_name: "",
            product_type: "0",
            picked: {},
            pickerList: {},
            isNeedRefresh: false, // refresh pickerList upon customer selection
        },
        noUpdated: false,
    },
    calculating: false,
}

export default function BookingReducers(state = initialState, action) {
    switch (action.type) {
    case Constant.EASY_BOOK:
        return {
            ...state,
            ...initialState,
            isEasyBookDataFetching: true,
        }
    case Constant.EASY_BOOK_SUCCESS:
        return {
            ...state,
            ...action.payload,
            isEasyBookDataFetching: false,
            isEasyBookPriceFetching: false,
            calculating: false,
        }
    case Constant.EASY_BOOK_UPDATE:
        return {
            ...state,
            easyBookData: action.payload,
            isEasyBookDataFetching: false,
        }
    case Constant.EASY_BOOK_PRICE_FETCHING:
        return {
            ...state,
            isEasyBookPriceFetching: true,
            calculating: true,
        }
    case Constant.EASY_BOOK_PRICE_CALCULATING:
        return {
            ...state,
            productPrice: false,
            calculating: true,
        }
    case Constant.EASY_BOOK_PRICE_UPDATE:
        return {
            ...state,
            productPrice: action.payload,
            isEasyBookDataFetching: false,
            isEasyBookPriceFetching: false,
            calculating: false,
        }
    case Constant.CLEAR_CART_PRODUCT_BOOKINGREDUCERS:
        return {
            ...state,
            productPrice: {
                ...state.productPrice,
                all_products: [],
            },
        }
    default:
        return state
    }
}
