import Constant from "../constants"
import { offlineRetrive, _isFunction, _clone } from "../../common/tool/util"
import { get } from "../../common/api"
import { postWithAbort } from "../../common/api/extraFunction"
import {
    getHomeBanners,
    getPopularDestinations,
    getPromotionBanners,
    getExperienceTourTags,
    getNewsletterSubscription,
} from "../../../config/requests"
import Algolia from "../../common/tool/algolia"

const fetchExperienceTourTagsSuccess = payload => (
    { type: Constant.FETCH_HOME_EXPERIENCE_DATA_SUCCESS, payload }
)
const fetchExperienceTourTagsError = error => (
    { type: Constant.FETCH_HOME_EXPERIENCE_DATA_FAILED, error }
)
const fetchPromotionsSuccess = payload => (
    { type: Constant.FETCH_HOME_PROMOTIONS_SUCCESS, payload }
)
const fetchPromotionsError = error => (
    { type: Constant.FETCH_HOME_PROMOTIONS_FAILED, error }
)
const fetchPopularDestinationsSuccess = payload => (
    { type: Constant.FETCH_HOME_POPULAR_DESTINATIONS_SUCCESS, payload }
)
const fetchPopularDestinationsError = error => (
    { type: Constant.FETCH_HOME_POPULAR_DESTINATIONS_FAILED, error }
)
const fetchHomeBannersSuccess = payload => (
    { type: Constant.FETCH_HOME_BANNERS_SUCCESS, payload }
)
const fetchHomeBannersError = error => (
    { type: Constant.FETCH_HOME_BANNERS_FAILED, error }
)
const fetchRecentlyViewedSuccess = payload => (
    { type: Constant.FETCH_HOME_RECENTLY_VIEWED_SUCCESS, payload }
)
const fetchRecentlyViewedError = error => (
    { type: Constant.FETCH_HOME_RECENTLY_VIEWED_FAILED, error }
)

export const fetchHomeBanners = () => dispatch => get(getHomeBanners.path, getHomeBanners.params)
    .then(data => dispatch(fetchHomeBannersSuccess(data)))
    .catch(error => dispatch(fetchHomeBannersError(error)))

export const fetchPopularDestinations = () => dispatch => get(getPopularDestinations.path, getPopularDestinations.params)
    .then(data => dispatch(fetchPopularDestinationsSuccess(data)))
    .catch(error => dispatch(fetchPopularDestinationsError(error)))

export const fetchPromotions = () => dispatch => get(getPromotionBanners.path, getPromotionBanners.params)
    .then(data => dispatch(fetchPromotionsSuccess(data)))
    .catch(error => dispatch(fetchPromotionsError(error)))

export const fetchExperienceTourTags = () => dispatch => get(getExperienceTourTags.path, getExperienceTourTags.params)
    .then(data => dispatch(fetchExperienceTourTagsSuccess(data.data)))
    .catch(error => dispatch(fetchExperienceTourTagsError(error)))

export const fetchRecentlyViewed = () => (dispatch, getState) => {
    const recentlyViewed = offlineRetrive("recentlyViewed")
    if (!recentlyViewed) {
        return false
    }
    const algoliaConfing = getState().InitData.configurations.algolia
    const configurationAlgolia = _clone(algoliaConfing)
    const algolia = new Algolia(configurationAlgolia)
    algolia.setFilter("global_status", 1, "=")
    if (algoliaConfing.search_index.search("t4fes") >= 0) {
        algolia.setFilter("t4fes_status", 1, "=")
    } else {
        algolia.setFilter("t4f_status", 1, "=")
    }
    algolia.setSearchIndex(configurationAlgolia.search_index)
    const productIds = []
    recentlyViewed.map(item => {
        productIds.push(item.id)
    })
    return algolia.getProductById(
        productIds,
        data => dispatch(fetchRecentlyViewedSuccess(data)),
        error => dispatch(fetchRecentlyViewedError(error)),
        getState().InitData,
        getState().CommonReducers.selectedCurrency.code,
    )
}

/**
 * Subscribes user to newsletters
 * @param {string} email email id of user
 * @param {successCb} function callback function on successful response
 * @param {failureCb} function callback function on failed response
 * @author Akash M <akashm.bipl@gmail.com>
 */

export const subscribeToNewsletters = (email, successCb = "", failureCb = "") => () => {
    postWithAbort(getNewsletterSubscription.path, {}, { email })
        .then(data => {
            if (data.ok) {
                if (_isFunction(successCb)) {
                    if (data.code === 208) {
                        successCb("Email address already exists")
                    } else {
                        successCb()
                    }
                }
            } else if (!data.ok && _isFunction(failureCb)) {
                failureCb()
            }
        })
        .catch(error => console.log("error", error))
}

export default {
    fetchHomeBanners,
    fetchPopularDestinations,
    fetchPromotions,
    fetchExperienceTourTags,
    fetchRecentlyViewed,
    subscribeToNewsletters,
}
