/* eslint-disable camelcase */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import cx from "classnames"
import { Link } from "react-router-dom"
import { BreadcrumbList, ListItem } from "react-semantic-breadcrumbs"
import {
    Header,
    Menu,
    Search,
    ToursItem,
    Upward,
    Footer,
} from "../../common/components/loadable"
import {
    MapView,
    Filter,
    FooterFilter,
} from "./components/loadable"
import {
    searchToursNearBy,
    searchToursByKeyword,
    setFilter,
    setFilterOnly,
    openSearch,
    openFilter,
    offServerFlag,
    getLisingMetaData,
    setFilterAccordingUrl,
    getAvailableFilter,
    toggleWishlist,
} from "../../redux/actions/ToursActions"
import { setSuggestionValue } from "../../redux/actions/SearchAction"
import { setRecentSearch } from "../../redux/actions/LocalstorageAction"
import {
    urlUnPrettify,
    urlPrettify,
    htmlEntityDecode,
} from "../../common/tool/Formatter"
import {
    debounce,
    _isEmpty,
    getSortingFilter,
    _startsWith,
    criteoTrackEvent,
    offlineStore,
    offlineRetrive,
    _trim,
    getUsefulDocObj,
    fixImageUrl,
} from "../../common/tool/util"
import animation from "../../common/tool/animation"
import { reset as resetMeta } from "../../redux/actions/MetaAction"
import { pushProductImpressions } from "../../common/tool/gtm-ee"
import "./index.less"
import common from "../../assets/less/common.less"
import StylesSprite from "../../assets/less/sprite.less"
import config, { t, filterList } from "../../../config"

class Tours extends React.Component {
    /**
     * isomorphic initial data fetch for SSR and client site
     * because of stability, now, this only used while SSR
     *
     * @param {object} props it's this.props if from client side, and it's store if from serverside
     * @param {string} url current router path to get search key, from server side
     *
     * @return {fetchList} datas should fetch
     *
     * @author Tony <tonys9204@gmail.com>
     */
    static fetchData(props, ctx) {
        const fetchList = []
        const { dispatch } = props
        const { url } = ctx
        const [searchKeyword] = url.split("/")[2].split("?")
        const keywords = urlUnPrettify(searchKeyword)
        let restrictedAttribute = ""
        if (/^\/popular\//.test(url)) {
            restrictedAttribute = "tour_tag" // Tour tag can be turned off from admin side, so don't depend on this
        }
        if (!/^near-me$/.test(searchKeyword)) {
            fetchList.push(dispatch(setFilter({ keywords }, url, true, true)))
            fetchList.push(dispatch(searchToursByKeyword(restrictedAttribute, url, false, ctx)))
        }
        return fetchList
    }

    /**
     * Track product impressions in listing page viewport
     */
    trackProductsInViewport = debounce(() => {
        const impressions = []
        this.props.tours.map((product, index) => {
            // is this product not tracked?
            if (this.productsAddedToViewport.indexOf(product.I) === -1) {
                const node = document.querySelector(`.pid-${product.I}`)
                if (node) {
                    const bounds = node.getBoundingClientRect()
                    // is product in viewport?
                    if (
                        // is vertically within viewport?
                        (
                            (bounds.top >= 0 && window.innerHeight > bounds.top)
                            || (bounds.bottom >= 0 && window.innerHeight > bounds.bottom)
                        )
                        // is horizontally within viewport?
                        && (
                            (bounds.left >= 0 && window.innerWidth > bounds.left)
                            || (bounds.right >= 0 && window.innerWidth > bounds.right)
                        )
                    ) {
                        this.productsAddedToViewport.push(product.I)
                        impressions.push({
                            name: product.N,
                            id: product.M,
                            category: product.ga_category_name,
                            list: this.props.match.url,
                            position: index + 1,
                        })
                    }
                }
            }
        })

        if (impressions.length > 0) {
            pushProductImpressions(impressions)
        }
    }, 100)

    constructor(props) {
        super(props)
        this.loadMore = this.loadMore.bind(this)
        this.offServerFlag = this.offServerFlag.bind(this)
        this.pageUrl = "/tours"
        this.restrictedAttribute = ""
        this.productsAddedToViewport = []
        if (_startsWith(this.props.match.url, "/popular")) {
            this.restrictedAttribute = "tour_tag"
            this.pageUrl = "/popular"
        }
        this.searchToursDebounce = debounce(loadMore => {
            this.props.searchToursByKeyword(
                this.restrictedAttribute,
                this.props.match.url,
                loadMore,
            )
            /*
            if (this.props.match.params.searchKeyword === "near-me") {
                this.props.searchToursNearBy()
            } else if (!this.props.isToursFetching) {
                this.props.searchToursByKeyword(this.restrictedAttribute, false, loadMore)
            }
            */
            this.resetFilteEllipsis()
        }, 300)
        this.wishlistElement = []
        this.filterResetAll = () => {} // function will comes after filter render
        this.setSortFilter = () => {} // function will comes after filter render
        this.filterDeselect = () => {} // function will comes after filter render
        this.pendingFunction = {} // add function which need to call at the end
        this.placeSelected = () => {} // function will comes after map-view render
        this.lastSortFilter = ""
        this.prePathname = ""
        this.filterPlacedPos = false
        this.filterStickyTop = false
        this.filterElementRef = false
        this.state = {
            filterTags: false,
            filterElements: [],
            isScrollUpHidden: false,
            displayMod: offlineRetrive("near-by-display-mod", true) || "map-and-list",
            isNearByPage: this.props.match.params.searchKeyword === "near-me",
            nearSearchString: t("Near Me"),
        }
        this.resetFilteEllipsisTime = 0
        this.resetFilteEllipsis = () => {
            clearTimeout(this.resetFilteEllipsisTime)
            if (this.state.isNearByPage) {
                return
            }
            if (this.filterElementRef) {
                this.filterElementRef.classList.remove("single_line")
                this.filterElementRef.classList.remove("filter_close")
                const filterElementHeight = 73
                if (this.filterElementRef.offsetHeight > filterElementHeight) {
                    if (!this.state.filterTags) {
                        this.filterElementRef.classList.add("filter_close")
                    }
                } else {
                    this.filterElementRef.classList.add("single_line")
                }
            } else {
                this.resetFilteEllipsisTime = setTimeout(this.resetFilteEllipsis, 300)
            }
        }
        this.toggleFilterStickyTop = scrollTop => {
            if (
                this.filterPlacedPos
                && this.filterStickyTop
            ) {
                if (scrollTop >= this.filterPlacedPos.offsetTop) {
                    this.filterStickyTop.classList.add("sticky_top")
                } else {
                    this.filterStickyTop.classList.remove("sticky_top")
                }
            }
        }
        this.resetMetaData(true)
    }

    UNSAFE_componentWillMount() {
        // do not execute this lifecycle at server side
        if (typeof window !== "undefined") {
            const resetFilter = {}
            const {
                match,
                filter,
                location,
                isSearchOpen,
            } = this.props
            if (typeof match.params.entityType === "undefined") {
                resetFilter.product_entity_type = ""
            }
            if (
                match.params.searchKeyword
                && match.params.searchKeyword !== "near-me"
                && (
                    urlUnPrettify(match.params.searchKeyword)
                    !== urlUnPrettify(filter.keywords)
                    || (
                        this.prePathname !== ""
                        && location.pathname !== this.prePathname
                    )
                )
            ) {
                const keywords = urlUnPrettify(match.params.searchKeyword)
                if (isSearchOpen) {
                    this.props.openSearch(false)
                }
                this.props.setRecentSearch(keywords)
                this.props.setSuggestionValue(keywords)
                resetFilter.keywords = keywords
                this.filterResetAll()
                this.props.setFilter({ ...resetFilter }, location.pathname, true, false, true)
                this.searchToursDebounce()
            } else if (window.lastLocation) {
                const noOfPastUrl = window.lastLocation.length
                if (
                    noOfPastUrl >= 3
                    && window.lastLocation[noOfPastUrl - 2] === "/"
                    && _startsWith(window.lastLocation[noOfPastUrl - 1], "/tours/")
                    && _startsWith(window.lastLocation[noOfPastUrl - 3], "/tours/")
                    && window.lastLocation[noOfPastUrl - 1] !== window.lastLocation[noOfPastUrl - 3]
                ) {
                    this.filterResetAll()
                    this.props.setFilter({
                        keywords: urlUnPrettify(match.params.searchKeyword),
                    }, location.pathname, true, false, true)
                    this.searchToursDebounce()
                }
            }
        }
    }

    componentDidMount() {
        // Below code update scroll position to top of the page. Product deatils page is exceptional
        if (
            typeof window !== "undefined"
            && window.lastLocation
        ) {
            if (!(/\.html$/.test(window.lastLocation[window.lastLocation.length - 2]))) {
                window.scrollTo(0, 0)
                const scrollEle = getUsefulDocObj(document)
                const scrollEleTop = scrollEle.scrollTop
                animation(scrollEleTop, 0, "Quad.easeInOut", val => {
                    scrollEle.scrollTop = val
                })
            }
        }
        if (
            this.props.location.state
            && typeof this.props.location.state.offSsr !== "undefined"
        ) {
            this.props.setFilterAccordingUrl(window.location.pathname, filter => {
                this.props.setFilter(filter)
                if (_startsWith(window.location.pathname, "/popular")) {
                    this.restrictedAttribute = "tour_tag"
                }
                if (_startsWith(window.location.pathname, "/tours/near-me")) {
                    this.props.searchToursNearBy()
                } else {
                    this.props.searchToursByKeyword(
                        this.restrictedAttribute,
                        window.location.pathname,
                    )
                }
            })
        }
        // Track Search Using FB Pixel (SSR side)
        if (
            typeof window !== "undefined"
            && !window.lastLocation
        ) {
            this.trackSearchWithFBPixel()
        }
        if (
            typeof document !== "undefined"
        ) {
            if (document.readyState === "loading") {
                document.addEventListener("DOMContentLoaded", this.resetFilteEllipsis)
            } else { // `DOMContentLoaded` already fired
                this.resetFilteEllipsis()
            }
            document.addEventListener("scroll", this.trackProductsInViewport, { passive: true })
        }
        if (typeof window !== "undefined") {
            let eventString = ""
            this.props.tours.map(item => {
                eventString += item.M + "\",\""
            })
            eventString = `"${eventString}"`
            criteoTrackEvent({
                event: "viewList",
                item: [eventString],
            })
        }
    }

    /**
     * @author tony <tonys9204@gmail.com>
     * @date 2018/01/11
     * start
     * hotfix: same key keywords not update,
     * eg, selected "multiday" while keyword new-york, search "new york" will not update tour_list
     * //update: to control tour listing page update, reduce unless render//
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const resetFilter = {}
        const { pathname } = this.props.location
        const nextPathname = nextProps.location.pathname
        if (
            nextProps.match.params.searchKeyword
            && (
                urlUnPrettify(nextProps.match.params.searchKeyword)
                !== urlUnPrettify(this.props.filter.keywords)
                // hotfix: same key keywords not update
                || (pathname !== nextPathname && /^\/\w+\/[\w-]+\/?$/.test(nextPathname))
            )
        ) {
            const keywords = urlUnPrettify(nextProps.match.params.searchKeyword)
            this.props.setRecentSearch(keywords)
            this.props.setSuggestionValue(keywords)
            resetFilter.keywords = keywords
            resetFilter.product_entity_type = ""
            this.setState({
                isNearByPage: this.props.match.params.searchKeyword === "near-me",
            })
        }
        if (this.props.isSearchOpen) {
            this.props.openSearch(false)
        }
        if (nextProps.match.params.entityType) {
            let entityType = false
            this.props.tabData.map(item => {
                if (
                    urlUnPrettify(item.entity)
                    === urlUnPrettify(nextProps.match.params.entityType)
                ) {
                    entityType = item.entityId + ""
                }
            })
            if (
                entityType
                && entityType
                !== this.props.filter.product_entity_type + ""
            ) {
                resetFilter.product_entity_type = entityType + ""
            }
        }
        if (
            !_isEmpty(resetFilter)
            && !(
                nextProps.location.state !== undefined
                && nextProps.location.state.alreadySearch
            )
        ) {
            this.filterResetAll()
            const isNewSearch = nextProps.location.pathname !== this.props.location.pathname
            this.props.setFilter({ ...resetFilter }, nextProps.location.pathname, isNewSearch)
            if (isNewSearch) {
                this.pendingFunction.searchToursDebounce = () => this.searchToursDebounce()
            }
        }
        window.previousLocation = this.props.location
        Object.keys(this.pendingFunction).map(fkey => {
            this.pendingFunction[fkey]()
            delete this.pendingFunction[fkey]
        })

        const filterArray = []
        filterArray.advancedSearch = []
        Object.keys(nextProps.filter).map(item => {
            filterArray[item] = nextProps.filter[item]
        })
        Object.keys(filterArray.advancedSearch).map(item => {
            filterArray[item] = filterArray.advancedSearch[item]
        })
        const elements = []
        let value = ""
        const addObjectInElement = (dataObj, filterLbl, key, filterObj) => {
            Object.keys(dataObj).map(fitem => {
                value = decodeURIComponent(dataObj[fitem])
                value = htmlEntityDecode(value)
                value = urlUnPrettify(value)
                elements.push({
                    key,
                    value: filterLbl + value,
                    object: filterObj,
                })
            })
        }
        Object.keys(filterArray).map(item => {
            for (let i = 0; i < filterList.length; i += 1) {
                if (filterList[i].apiName === item) {
                    if (item === "duration") {
                        filterArray[item] = filterArray[item].split("-")
                    }
                    if (item === "departure") {
                        value = filterArray[item].split("-")
                        let filterLbl = ""
                        if (
                            value.length === 3
                            && filterList[i].objectVar === "operationDate"
                        ) {
                            filterLbl = `${t("Departure Date")}: `
                        } else if (
                            value.length === 2
                            && filterList[i].objectVar === "operationMonths"
                        ) {
                            filterLbl = `${t("Departure Months")}: `
                        } else {
                            // eslint-disable-next-line no-continue
                            continue
                        }
                        for (let index = 0; index < value.length; index += 1) {
                            value[index] = value[index].replace(/^\w/, c => c.toUpperCase())
                        }
                        filterArray[item] = filterLbl + value.join("-")
                        if (
                            typeof filterArray.prepostdate !== "undefined"
                            && filterArray.prepostdate !== "no"
                        ) {
                            filterArray[item] += `± 3 ${t("days")}`
                        }
                        elements.push({
                            key: item,
                            value: filterArray[item],
                            object: filterList[i],
                        })
                    } else if (item === "price") {
                        filterArray[item] = filterArray[item].split("-")
                        filterArray[item] = nextProps.selectedCurrency.symbol + filterArray[item][0]
                                            + "- " + nextProps.selectedCurrency.symbol + filterArray[item][1]
                        elements.push({
                            key: item,
                            value: `${t("Price")}: ` + filterArray[item],
                            object: filterList[i],
                        })
                    } else if (typeof filterArray[item] === "object") {
                        value = ""
                        if (item === "attractions") {
                            value = `${t("Visit")}: `
                        }
                        if (item === "star") {
                            value = `${t("Star")}: `
                        }
                        if (item === "duration") {
                            value = `${t("Stay")}: `
                            filterArray[item].forEach((element, key) => {
                                filterArray[item][key] = element + ` ${t("Days")}`
                            })
                        }
                        addObjectInElement(filterArray[item], value, item, filterList[i])
                    } else {
                        value = decodeURIComponent(filterArray[item])
                        if (item === "depart_from") {
                            value = `${t("From")}: ` + value
                        } else if (item === "depart_end") {
                            value = `${t("To")}: ` + value
                        } else if (item === "sale") {
                            value = urlUnPrettify(value)
                        } else if (item === "hotel_name") {
                            value = `${t("Hotel name")}: ` + value
                        } else if (item === "hotel_location_name") {
                            value = `${t("Location")}: ` + value
                        } else if (item === "product_entity_type") {
                            return // don't do any thing
                        }
                        elements.push({
                            key: item,
                            value,
                            object: filterList[i],
                        })
                    }
                }
            }
        })
        this.setState({
            filterElements: elements,
        })

        // Following logic is required because, tour page can be rendered without
        // fetching data when user visit listing -> product -> listing navigation
        // So nextProps.MetaPage !=== "tours" ensures it triggers
        if (
            (
                (
                    nextProps.MetaPage !== "tours"
                    && nextProps.MetaPage !== ""
                )
                || (
                    this.props.isToursFetching
                    && !nextProps.isToursFetching
                )
            )
            && (
                nextProps.tours.length > 0
                || (
                    nextProps.tours.length === 0
                    && nextProps.isToursNotFound
                )
            )
            && this.props.filter.page === 1 // only need for the first page
        ) {
            this.props.getLisingMetaData(this.props.match.url, nextProps.totalProducts)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.url !== prevProps.match.url) {
            this.resetMetaData()
        }
        // meta data has updated
        if (this.props.MetaData.title !== "" && prevProps.MetaData.title === "") {
            this.trackSearchWithFBPixel()
        }
        // @FIXME Commented after introducing lodable, this function cannot find
        // this.selectFilterFromURLData()
        if (!window.lastLocation) {
            window.lastLocation = [window.location.pathname]
        }
        if (this.props.tours.length > 0) {
            this.trackProductsInViewport() // initial trigger
        }
        this.resetFilteEllipsis()
    }

    componentWillUnmount() {
        this.prePathname = this.props.location.pathname
        document.removeEventListener("scroll", this.trackProductsInViewport, { passive: true })
    }

    setScrollUpHidden = value => {
        this.setState({
            isScrollUpHidden: value,
        })
    }

    getMenuString() {
        let menuString = ""
        const entityId = this.props.filter.product_entity_type
        if (entityId === "") {
            menuString = t("Tours")
        } else if (entityId !== "") {
            this.props.tabData.map(item => {
                if (item.entityId === +entityId) {
                    if (item.label && item.label !== "") {
                        menuString = item.label
                    } else {
                        menuString = item.entity
                    }
                }
            })
        }
        return menuString
    }

    getSearchKeyword = searchKeyword => {
        const keyWord = urlUnPrettify(searchKeyword)
        if (keyWord === "Near Me") {
            return this.state.nearSearchString
        }
        return keyWord
    }

    getSearchSuffix() {
        const selectedOption = this.getMenuString()
        if (selectedOption === t("Tours")) {
            return ""
        }
        return selectedOption
    }

    getHeaderString(filterKeyword = "") {
        let headerString = t("Tours")
        if (this.props.MetaData.subTitle) {
            headerString = this.props.MetaData.subTitle

            // departure
            if (typeof this.props.filter.advancedSearch.depart_from !== "undefined") {
                const departureCity = this.props.filter.advancedSearch.depart_from
                headerString += ` ${t("From")} ${urlUnPrettify(departureCity)}`
            }

            return headerString
        }
        const selectedOption = this.getMenuString()
        if (selectedOption && filterKeyword) {
            headerString = `${this.getSearchKeyword(filterKeyword)} ${t(selectedOption)}`
            if (selectedOption === t("Hotels") || selectedOption === t("Transportation Services")) {
                headerString = `${t(selectedOption)} ${t("in")} ${filterKeyword}`
            }
            if (typeof this.props.filter.advancedSearch.depart_from !== "undefined") {
                const departureCity = this.props.filter.advancedSearch.depart_from
                if (selectedOption === t("Tours")) {
                    headerString = `${filterKeyword} ${t(selectedOption)} ${t("From")} ${urlUnPrettify(departureCity)}`
                } else {
                    headerString = `${t(selectedOption)} ${t("From")} ${urlUnPrettify(departureCity)}`
                }
            }
        }
        return headerString
    }

    /**
     * Total Page view: full-screen-map, map-and-list, list
     * It's toggled in this.state.displayMod
     */
    setDisplayMod(displayMod) {
        offlineStore("near-by-display-mod", displayMod, true)
        this.setState({
            displayMod,
        })
    }

    offServerFlag = () => {
        if (this.props.match.params.searchKeyword === "near-me") {
            return
        }
        if (this.props.tours.length < 1 && !this.props.isToursFetching) {
            // it's temporary patch for fix 0 tour
            this.props.setFilter({
                page: 0,
            }, "", true)
            this.searchToursDebounce()
        }
        this.props.offServerFlag()
    }

    displayTours() {
        if (this.props.tours.length > 0) {
            return this.props.tours.map((item, index) => (
                <ToursItem
                    key={index}
                    {...item}
                    onWishlistClicked={() => this.props.toggleWishlist(item.I)}
                    entityType={this.props.filter.product_entity_type}
                    position={index}
                    listing={this.props.match.url}
                    selectedCurrency={this.props.selectedCurrency}
                    cssClass={`pid-${item.I}`}
                    isNearByPage={this.state.isNearByPage}
                />
            ))
        }
        return []
    }

    trackSearchWithFBPixel() {
        const topFiveContentIds = []
        this.props.tours.map((item, index) => {
            if (index < 5) {
                topFiveContentIds.push(item.I)
            }
        })
        window.fbq("track", "Search", {
            search_string: this.props.filter.keywords,
            content_ids: topFiveContentIds, // top 5 search results
            content_type: "product",
        })
    }

    loadMore() {
        if (!this.props.isToursNotFound && !this.props.isToursFetching) {
            this.searchToursDebounce(true)
        }
    }

    /**
     * Reset meta data
     *
     * @param {boolean} safe Safely reset SSR meta
     */
    resetMetaData(safe) {
        this.props.resetMeta(safe)
    }

    renderEntityType() {
        return this.props.tabData.map(item => {
            if (!item.isEnabled) {
                return ""
            }
            if (parseInt(item.entityId, 10) === 3) {
                return ""
            }
            let redirectUrl = ""
            if (item.entityId === "") {
                redirectUrl = `${this.pageUrl}/${this.props.match.params.searchKeyword}/`
            } else {
                redirectUrl = `${this.pageUrl}/${this.props.match.params.searchKeyword}/${urlPrettify(item.entity)}`
            }
            return (
                <li
                    key={item.entityId}
                    className={cx(
                        "",
                        {
                            active: (
                                "" + this.props.filter.product_entity_type
                                === item.entityId + ""
                            ),
                        },
                    )}
                >
                    <Link
                        to={redirectUrl}
                        onClick={() => {
                            this.filterResetAll(false, true)
                            this.props.setFilter({
                                product_entity_type: "" + isNaN(item.entityId) ? "" : item.entityId,
                                advancedSearch: {
                                    sort_by: this.lastSortFilter,
                                },
                            }, redirectUrl)
                            this.searchToursDebounce()
                        }}
                    >
                        {(item.label && item.entityId !== 4) ? t(item.label) : t(item.entity)}
                    </Link>
                </li>
            )
        })
    }

    render() {
        if (this.props.filter.advancedSearch.sort_by) {
            this.lastSortFilter = this.props.filter.advancedSearch.sort_by
        }
        const sortFilter = this.props.configurations
            && getSortingFilter(
                this.props.filter.product_entity_type,
                this.props.configurations.algolia.search_index,
            )
        const filterBtn = () => (
            <button
                type="button"
                className="filter_button"
                onClick={
                    () => this.props.openFilter(true)
                }
            >
                {t("Filters")}
                <em className={`${StylesSprite.sprite_base} filter_icon`} />
            </button>
        )
        const getcommonListingElement = () => (
            <section className="list_product_section">
                <div className="filter_belt_container" ref={node => { this.filterPlacedPos = node }}>
                    <div className="filter_belt" ref={node => { this.filterStickyTop = node }}>
                        {filterBtn()}
                        <div
                            className="bt_filter_option"
                            style={{
                                display: this.props.match.params.searchKeyword === "near-me"
                                    && "none",
                            }}
                        >
                            <select
                                onChange={e => {
                                    this.setSortFilter(e.target.value)
                                    this.lastSortFilter = e.target.value
                                }}
                                value={
                                    this.props.filter.advancedSearch.sort_by
                                        ? this.props.filter.advancedSearch.sort_by
                                        : ""
                                }
                            >
                                {
                                    !!sortFilter
                                    && sortFilter.map((item, index) => (
                                        <option value={item.key} key={index}>
                                            {t(item.t)}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div
                            className={cx("bt_filter_option", {
                                "dis-none": !this.state.isNearByPage,
                            })}
                            style={{ fontSize: "0.28 rem" }}
                            onClick={() => this.setDisplayMod("map-and-list")}
                        >
                            Map View
                        </div>
                        <div className="clear" />
                    </div>
                </div>
                <div className="container">
                    <h2 className="filter_record_counter">
                        <span>{this.props.totalProducts + " "}</span>
                        {
                            this.getHeaderString(this.props.filter.keywords)
                        }
                    </h2>
                    <div
                        className={
                            cx("filter_selected", {
                                "dis-none": this.state.filterElements.length === 0,
                            })
                        }
                    >
                        <div
                            className={
                                cx("filter_keywords", {
                                    filter_close: !this.state.filterTags,
                                    "dis-none": this.state.filterElements.length === 0,
                                })
                            }
                            ref={
                                ele => {
                                    if (ele) {
                                        this.filterElementRef = ele
                                    }
                                }
                            }
                            onClick={
                                () => {
                                    this.setState({
                                        filterTags: true,
                                    })
                                }
                            }
                        >
                            {
                                this.state.filterElements.map((item, key) => (
                                    <div className="filter_tag" key={"ft_" + key}>
                                        {
                                            (this.props.filter.advancedSearch.sale
                                            !== undefined
                                            && this.props.filter.advancedSearch.sale === "all")
                                                ? t("All Sales")
                                                : urlUnPrettify(t(item.value))
                                        }
                                        <span
                                            onClick={() => {
                                                this.filterDeselect(
                                                    item.object.objectVar,
                                                    item.value,
                                                    item.object.dataKey,
                                                )
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                ))
                            }
                            <div
                                className="filter_bottomlink"
                            >
                                <div
                                    className="filter_clear_all"
                                    onClick={() => {
                                        this.setState({
                                            filterElements: [],
                                        })
                                        this.filterResetAll(false, true)
                                        const redirectUrl = `${this.pageUrl}/${this.props.match.params.searchKeyword}`
                                        this.props.setFilter({
                                            product_entity_type: "",
                                            advancedSearch: {
                                                sort_by: this.lastSortFilter,
                                            },
                                        }, redirectUrl)
                                        this.searchToursDebounce()
                                        this.props.history.push(redirectUrl)
                                    }}
                                >
                                    {t("Clear All")}
                                </div>
                                <div
                                    className="filter_hide"
                                    onClick={
                                        e => {
                                            this.setState({
                                                filterTags: false,
                                            })
                                            e.stopPropagation()
                                        }
                                    }
                                >
                                    {t("Hide")}
                                </div>
                                <div className="clear" />
                            </div>
                        </div>
                    </div>
                    <div className="tours_main_content">
                        {
                            this.displayTours()
                        }
                    </div>
                    {
                        this.props.isToursFetching && (
                            <div key={0} className={common.loader}>
                                {t("Loading")}
                                ...
                            </div>
                        )
                    }
                    {
                        !(
                            this.props.isToursFetching
                            || this.props.isToursNotFound
                        ) && this.props.tours.length !== 0
                        && (
                            <div
                                key={0}
                                ref={lbtnRef => {
                                    if (lbtnRef) {
                                        /**
                                        Used bellow code for hide load-more button when
                                        listing page open/initial request of tour fetching
                                        */
                                        setTimeout(() => {
                                            lbtnRef.style.display = "block"
                                        }, 1000)
                                    }
                                }}
                                style={{
                                    display: "none",
                                }}
                            >
                                <button
                                    type="button"
                                    onClick={this.loadMore}
                                    className="load_more_btn"
                                >
                                    {t("Load More")}
                                </button>
                            </div>
                        )
                    }
                </div>
            </section>
        )
        const listItem = [
            <ListItem key="-1" url={config.host}>Tours4Fun</ListItem>,
        ]
        if (this.props.MetaData.breadcrumbsSchema) {
            this.props.MetaData.breadcrumbsSchema.map((item, key) => listItem
                .push(<ListItem key={key} url={config.host + "/" + _trim(item.id, "/")}>
                    {item.name}
                </ListItem>))
        }
        let canonical = config.url.t4fUrl + this.props.match.url
        if (canonical.charAt(canonical.length - 1) !== "/") {
            canonical += "/"
        }
        const meta = {
            ...this.props.MetaData,
            canonical,
        }
        if (this.props.tours.length > 0) {
            meta.image = fixImageUrl(encodeURI(this.props.tours[0].G), 325 * 2, 233 * 2, 55)
            const imageSrcsetArr = [
                `${fixImageUrl(meta.image, 325, 233)} 1x`,
                `${fixImageUrl(meta.image, 325 * 2, 233 * 2, 55)} 2x`,
                `${fixImageUrl(meta.image, 325 * 3, 233 * 3, 50)} 3x`,
            ]
            meta.imagesrcset = imageSrcsetArr.join(", ")
        }
        return (
            <div key="tour_listing">
                <div
                    className={
                        cx("body_main_container", {
                            body_main_container_right: !this.props.isMenuHidden,
                        })
                    }
                >
                    {
                        this.props.MetaData.breadcrumbsSchema
                        && (
                            <BreadcrumbList
                                format="JSON-LD"
                            >
                                {listItem}
                            </BreadcrumbList>
                        )
                    }
                    <Header
                        menuWithArrow
                        isBackButton
                        isCart
                        history={this.props.history}
                        meta={meta}
                        isDisplayRibbon={!this.state.isNearByPage}
                    />
                    {
                        this.props.isSearchOpen
                            ? (
                                <Search
                                    history={this.props.history}
                                    searchValue={
                                        this.getSearchKeyword(this.props.match.params.searchKeyword)
                                    }
                                    isNearBy={this.state.isNearByPage}
                                    placeSelected={this.placeSelected}
                                    placeString={this.state.nearSearchString}
                                />
                            )
                            : (
                                <section className="list_search_section">
                                    <div className="container">
                                        <div className="list_search_component">
                                            <div onClick={() => this.props.openSearch(true)}>
                                                <h1 className="inputsearchlist">
                                                    {" "}
                                                    {
                                                        this.getSearchKeyword(this
                                                            .props.match.params.searchKeyword)
                                                    }
                                                    {" "}
                                                    {
                                                        this.getSearchSuffix()
                                                    }
                                                </h1>
                                                <span className="searchbtnlist">
                                                    <span className={`${StylesSprite.sprite_base} search-icon`} />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.pageUrl !== "/popular"
                                    && this.props.match.params.searchKeyword !== "near-me"
                                    && (
                                        <div className="tour_menu">
                                            <div className="tour_menu_sub">
                                                <ul className="list_cat_links">
                                                    {this.renderEntityType()}
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                    }
                                </section>
                            )
                    }
                    <Filter
                        show={this.props.isFilterOpen}
                        history={this.props.history}
                        openFilter={this.props.openFilter}
                        filterTotalProducts={this.props.filterTotalProducts}
                        getAvailableFilter={
                            filter => this.props.getAvailableFilter(
                                filter,
                                this.restrictedAttribute,
                            )
                        }
                        resetFilter={resetFilter => {
                            this.filterResetAll = resetFilter
                        }}
                        filterDeselect={filterDeselect => {
                            this.filterDeselect = filterDeselect
                            this.resetFilteEllipsis()
                        }}
                        CurrencySymbol={this.props.selectedCurrency.symbol}
                        setFilter={(advancedSearch, url = "", emptyTour = true) => this.props.setFilter({
                            advancedSearch,
                        }, url, emptyTour)}
                        entityType={this.props.tabData}
                        offServerFlag={this.offServerFlag}
                        setSortFilter={funcSortFilter => { this.setSortFilter = funcSortFilter }}
                        hitSearchTours={this.searchToursDebounce}
                        selectFilterFromURLData={func => {
                            this.selectFilterFromURLData = func
                            this.resetFilteEllipsis()
                        }}
                        pageUrl={this.pageUrl}
                        isPopuler={this.pageUrl === "/popular"}
                        isNearBy={this.state.isNearByPage}
                    />
                    {/* list product section */}
                    <div />
                    {
                        this.state.isNearByPage && (
                            this.state.displayMod === "map-and-list"
                            || this.state.displayMod === "full-screen-map"
                        )
                            ? (
                                <MapView
                                    setDisplayMod={flag => {
                                        this.setDisplayMod(flag)
                                    }}
                                    filterBtn={filterBtn}
                                    tours={this.props.tours}
                                    places={this.props.places}
                                    displayMod={this.state.displayMod}
                                    selectedCurrency={this.props.selectedCurrency}
                                    setFilter={(
                                        filter,
                                        url,
                                        emptyTour,
                                        isSSR,
                                        isFullReset,
                                    ) => this.props.setFilter(
                                        filter,
                                        url,
                                        emptyTour,
                                        isSSR,
                                        isFullReset,
                                    )}
                                    setFilterOnly={filter => this.props.setFilterOnly(filter)}
                                    setSearchString={
                                        nearSearchString => this.setState({ nearSearchString })
                                    }
                                    placeSelected={placeSelectedCB => {
                                        this.placeSelected = placeSelectedCB
                                    }}
                                    hitSearchTours={this.searchToursDebounce}
                                    isToursNotFound={this.props.isToursNotFound}
                                    filter={this.props.filter}
                                />
                            )
                            : getcommonListingElement()
                    }
                    {
                        this.pageUrl !== "/popular"
                        && this.props.match.params.searchKeyword !== "near-me"
                        && (
                            <>
                                <FooterFilter listPageUrl={this.props.match.url} />
                                <Footer />
                            </>
                        )
                    }
                </div>
                <Menu
                    disableMenu={this.props.isFilterOpen}
                    history={this.props.history}
                />
                <Upward
                    isScrollUpHidden={this.state.isScrollUpHidden}
                    scrollUp={
                        scrollTop => {
                            this.toggleFilterStickyTop(scrollTop)
                        }
                    }
                    scrollDown={
                        () => {
                            if (this.filterPlacedPos && this.filterStickyTop) {
                                this.filterStickyTop.classList.remove("sticky_top")
                            }
                        }
                    }
                    pressedGoTop={() => {
                        this.filterStickyTop.classList.remove("sticky_top")
                    }}
                />
            </div>
        )
    }
}

Tours.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object]),
    tours: PropTypes.oneOfType([PropTypes.array]),
    tabData: PropTypes.oneOfType([PropTypes.array]),
    searchToursByKeyword: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setFilterOnly: PropTypes.func.isRequired,
    totalProducts: PropTypes.number,
    isToursNotFound: PropTypes.bool,
    isToursFetching: PropTypes.bool,
    isFilterOpen: PropTypes.bool,
    isSearchOpen: PropTypes.bool,
}
/*
const ENTITY_ONEDAY_TOUR = 0;
const ENTITY_MULTIDAY_TOUR = 1;
const ENTITY_CRUISE = 2;
const ENTIRY_HOTEL = 3;
const ENTIRY_TRANSPORTATION = 4;
const ENTIRY_SIM = 5;
const ENTITY_BUS_TOUR = 6;
*/
Tours.defaultProps = {
    tours: [],
    history: {},
    totalProducts: 0,
    tabData: [],
    match: {},
    isToursNotFound: false,
    isToursFetching: false,
    isSearchOpen: false,
    isFilterOpen: false,
}

function mapStateToProps(state) {
    return {
        ...state.ToursReducers,
        ...state.MetaReducers,
        ...state.MenuReducers,
        ...state.CommonReducers,
        ...state.InitData,
    }
}

export default connect(mapStateToProps, {
    setSuggestionValue,
    setRecentSearch,
    resetMeta,
    searchToursNearBy,
    searchToursByKeyword,
    setFilter,
    setFilterOnly,
    openSearch,
    openFilter,
    offServerFlag,
    getLisingMetaData,
    toggleWishlist,
    setFilterAccordingUrl,
    getAvailableFilter,
})(Tours)
