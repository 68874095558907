import loadable from "@loadable/component"

export const Top = loadable(() => import("./top"))
export const Overview = loadable(() => import("./overview"))
export const ToursForMe = loadable(() => import("./tours-for-me"))
export const Description = loadable(() => import("./description"))
export const CallNow = loadable(() => import("./call-now"))
export const Itinerary = loadable(() => import("./itinerary"))
export const Review = loadable(() => import("./review"))
export const GoogleReview = loadable(() => import("./googleReview"))
export const BottomUI = loadable(() => import("./bottom-ui"))
export const YouMayAlsoLike = loadable(() => import("./you-may-also-like"))
export const ToursOptions = loadable(() => import("./tours-option"))
export const Upward = loadable(() => import("./upward-arrow"))
export const ProductProfile = loadable(() => import("./product-profile"))
export const TourOptions = loadable(() => import("./tour-options"))
export const Impressions = loadable(() => import("./Impressions"))
