import { pad } from "./util"
import { t } from "../../../config"
/**
 *
 * @author Pratik B <pratikb.bipl@gmail.com> (In React - New author)
 * @author Gihan S <gihanshp@gmail.com> (In angular - Old author)
 */
/* eslint-disable no-useless-escape, no-param-reassign, no-underscore-dangle */
export function stringToDate(dateString, utc) {
    utc = utc || false
    if (Object.prototype.toString.call(dateString) === "[object Date]") {
        return new Date(dateString)
    }
    let dateParts
    let year
    let month
    let day
    let hours
    let minutes
    let seconds
    if (dateString.match(/^\d{4}\-\d{2}\-\d{2}$/)) {
        dateParts = dateString.split("-")
        year = dateParts[0]
        month = dateParts[1]
        day = dateParts[2]
        hours = "12"
        minutes = "00"
        seconds = "00"
    } else if (dateString.match(/^\d{4}\-\d{2}\-\d{2} \d{2}:\d{2}:\d{2}$/)) {
        const date = dateString.substr(0, 10)
        const time = dateString.substr(10, 8)
        dateParts = date.split("-")
        year = dateParts[0]
        month = dateParts[1]
        day = dateParts[2]
        const timeParts = time.split(":")
        hours = timeParts[0]
        minutes = timeParts[1]
        seconds = timeParts[2]
    }
    if (utc) {
        return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds))
    }
    return new Date(year, month - 1, day, hours, minutes, seconds)
}

export function dateToString(date) {
    return date.getFullYear()
        + "-" + pad(date.getMonth() + 1, 2)
        + "-" + pad(date.getDate(), 2)
}

/**
 * @author Tony <tonys9204@gmail.com>
 * whether year is lep year
 * @param {String/Number} year
 * @returns {boolean}
 */
export const isLeapYear = year => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)

/**
 * get month
 * @param date
 * @returns {*|number}
 */
export const getMonth = date => {
    let d
    if (date) {
        d = date
    } else {
        d = new Date()
    }
    return d.getMonth()
}

/**
 * get month
 * @param number
 * @returns {*|number}
 */
export const getMonthLetter = num => {
    const months = [t("January"), t("February"), t("March"), t("April"), t("May"), t("June"), t("July"), t("August"), t("September"), t("October"), t("November"), t("December")]
    const mIndex = parseInt(num, 10)
    if (!isNaN(mIndex) && mIndex >= 0 && mIndex < 12) {
        return months[mIndex]
    }
    console.warn("please give a number between 0 - 11")
    return false
}

/**
 * get month
 * @param number
 * @returns {*|number}
 */
export const getMonthLetterShort = num => {
    const month = getMonthLetter(num)
    if (month) {
        return month.substr(0, 3)
    }
    return false
}


/**
 * get full year
 * @param date
 * @returns {number}
 */
export const getFullYear = function(date) {
    let d
    if (date) {
        d = date
    } else {
        d = new Date()
    }
    return d.getFullYear()
}

/**
 * get day of the month
 * @param date
 * @returns {number}
 */
export const getDate = function(date) {
    let d
    if (date) {
        d = date
    } else {
        d = new Date()
    }
    return d.getDate()
}

/**
 * return first day of month is which day of the week
 * @param date
 * @returns {number}
 */
export const weekOfMonth = function(date) {
    let d
    if (date) {
        d = date
    } else {
        d = new Date()
    }
    return new Date(getFullYear(d), getMonth(d), 1).getDay()
}
