import { _find, offlineRetrive } from "../../common/tool/util"
import Constant from "../constants"

const initialState = {
    RecentlyData: [],
    RecentlyError: {
        ERROR: "",
    },
}
export default function RecentlyReducer(state = initialState, action) {
    let toursWishlist = []
    switch (action.type) {
    case Constant.RECENTLY_VIEWED_DATA_INIT: {
        const RecentlyData = offlineRetrive("recentlyViewed")
        return { ...state, RecentlyData }
    }
    case Constant.RECENTLY_VIEWED_DATA_REQUEST:
        return { ...state }
    case Constant.RECENTLY_VIEWED_DATA_SUCCESS: {
        const { RecentlyData } = state
        toursWishlist = toursWishlist !== null ? toursWishlist : []
        toursWishlist = offlineRetrive("toursWishlist")

        const recentlyData = RecentlyData.map(item => {
            const id = action.payload.old_product_id
                ? action.payload.old_product_id
                : action.payload.I
            if ("" + item.id === "" + id) {
                item.details = action.payload
                item.details.isWishlisted = Boolean(_find(
                    toursWishlist,
                    o => ("" + o.id === "" + id),
                ))
            }
            return item
        })
        return {
            ...state,
            RecentlyError: { ERROR: "" },
            RecentlyData: recentlyData,
        }
    }
    case Constant.RECENTLY_VIEWED_DATA_ERROR:
        return { ...state, RecentlyError: action.payload }
    default:
        return state
    }
}
