import Constant from "../constants"

const intialTours = {
    isDataFetching: false,
    checkOutData: {}, // braintreeToken,cartProduct,commonData,storeData
    isError: false,
    orderSuccess: false,
    confirmOrderID: "",
    getReservationInformationData: {},
    getReservationInformationError: { ERROR: "" },
    travelerSuggestions: [],
    isPaypalCheckout: true,
    rememberedUserData: [],
}

export default function CheckoutReducers(state = intialTours, action) {
    let isError = state.isError
    switch (action.type) {
    case Constant.CHECKOUT_DATA_REQUEST:
        return {
            ...state,
            isDataFetching: true,
            checkOutData: {},
            isError: false,
        }
    case Constant.CHECKOUT_DATA_ERROR:
        return {
            ...state,
            isDataFetching: false,
            checkOutData: {},
            isError: true,
        }
    case Constant.CHECKOUT_DATA_SUCCESS:
        isError = (action.payload.commonData.TDO.length === 0)
        return {
            ...state,
            isDataFetching: false,
            checkOutData: action.payload,
            isPaypalCheckout: true,
            isError,
        }
    case Constant.REMEMBER_USER_DATA:
        return {
            ...state,
            rememberedUserData: action.rememberedUserData,
        }
    case Constant.CHECKOUT_ORDER_SUCCESS:
        return {
            ...state,
            isDataFetching: false,
            orderSuccess: action.orderSuccess,
            confirmOrderID: action.confirmOrderID,
        }
    case Constant.GET_RESERVATION_REQUEST:
        return {
            ...state,
            getReservationInformationData: {},
            getReservationInformationError: { ERROR: "" },
        }
    case Constant.GET_RESERVATION_SUCCESS:
        return {
            ...state,
            getReservationInformationData: action.payload,
        }
    case Constant.GET_RESERVATION_ERROR:
        return {
            ...state,
            getReservationInformationError: action.payload,
        }
    case Constant.FREQUENT_CONTACTS_REQUEST:
        return { ...state, travelerSuggestions: [] }
    case Constant.FREQUENT_CONTACTS_SUCCESS:
        return { ...state, travelerSuggestions: action.payload }
    case Constant.FREQUENT_CONTACTS_VALUE_CLEAR:
        return { ...state, travelerSuggestions: action.payload }
    case Constant.FREQUENT_CONTACTS_VALUE_SET:
        return { ...state, frequentContactsSuggestionValue: action.suggestion }
    case Constant.IS_PAYPAL_CHECKOUT:
        return { ...state, isPaypalCheckout: action.isPaypalCheckout }
    case Constant.CLEAR_CART_PRODUCT_CHECKOUTREDUCERS:
        return {
            ...state,
            checkOutData: {},
        }
    case Constant.APPLIED_PROMO_UPDATED_REQUEST:
    case Constant.APPLIED_PROMO_UPDATED:
        return {
            ...state,
            checkOutData: {
                ...state.checkOutData,
                cartProduct: {
                    ...state.checkOutData.cartProduct,
                    appliedCoupon: state.checkOutData.cartProduct
                        && state.checkOutData.cartProduct.appliedCoupon
                        ? {
                            ...state.checkOutData.cartProduct.appliedCoupon,
                            ...action.payload,
                        } : {},
                },
            },
        }
    default:
        return state
    }
}
