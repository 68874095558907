import { t } from "../../../config"
import { get } from "../api"

/* eslint-disable no-useless-escape, no-param-reassign, eqeqeq */
export function urlPrettify(input) {
    if (input) {
        return input.toLowerCase().replace(/ /g, "-")
    }
    return input
}
export function urlUnPrettify(input) {
    if (input) {
        return input.replace(/(?!-$)-|_/g, " ").replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    }
    return input
}
/**
 * function toSeoUrl
 * Producing SEO friendly URL
 * @export
 * @param {string} url
 * @returns {string}
 */
export function toSeoUrl(url) {
    // make the url lowercase
    if (typeof url === "undefined") {
        return ""
    }
    let encodedUrl = url.toString().toLowerCase()
    // replace & with and
    encodedUrl = encodedUrl.split(/\&+/).join("and")
    // remove invalid characters
    // encodedUrl = encodedUrl.split(/[^()a-z0-9]/).join("-")
    const upper = encodedUrl.toUpperCase()
    let result = ""
    for (let i = 0; i < encodedUrl.length; i += 1) {
        if (
            encodedUrl[i] != upper[i]
            || (parseInt(upper[i], 10) >= 0 && parseInt(upper[i], 10) <= 9)
        ) {
            result += encodedUrl[i]
        } else {
            result += "-"
        }
    }
    return result
    // remove duplicates
    // encodedUrl = encodedUrl.split(/-+/).join("-")
    // trim leading & trailing characters
    // encodedUrl = _trim(encodedUrl.replace(/\-{2,5}/g, "-"), "-")
    // encodedUrl = _trim(encodedUrl, "-")
}
export function htmlEntityDecode(str) {
    return str
        .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
        .replace(/&quot;/g, "\"")
}

export function isEqualUriSeo(param1, param2) {
    return (
        toSeoUrl(htmlEntityDecode(decodeURIComponent(param1))).toUpperCase()
        === toSeoUrl(htmlEntityDecode(decodeURIComponent(param2))).toUpperCase()
    )
}
export function safe(input) {
    if (input) {
        return input.replace(/\W/g, "")
    }
    return input
}

/**
 * Convert currency
 *
 * @param {string} fromC      From currency code
 * @param {string} toC        To currency code
 * @param {number} amount     Amount
 * @param {number} precisions Number of Precision points
 * @param {object} InitData   Initial data
 */
export function convertCurrency(fromC, toC, amount, precisions, InitData) {
    const { exchangeRates } = InitData
    if ((fromC === toC && fromC.toLowerCase() === "usd") || !exchangeRates[fromC] || !exchangeRates[toC] || !amount) {
        return amount
    }

    // Convert to USD if source and target currency same and not USD for add markup rate calculation
    if (fromC === toC) {
        // converting to USD so use rate without markup
        amount = parseFloat(amount / exchangeRates[fromC].rate).toFixed(4)
        fromC = "USD"
    }

    if (precisions === "undefined" || isNaN(parseInt(precisions, 10))) {
        precisions = 2
    }

    let amountUSD = amount
    if (fromC.toLowerCase() !== "usd") {
        amountUSD = parseFloat(amount / exchangeRates[fromC].rate).toFixed(4)
    }
    let toAmount
    if (toC.toLowerCase() !== "usd") {
        // if not usd convert, apply markedup rate
        toAmount = parseFloat(amountUSD * exchangeRates[toC].markedup_rate)
    } else {
        toAmount = amountUSD
    }
    // this is becahse rounding used by toFixed method
    // In server side amount is output without rounding
    // also to avoid javascript edge cases in floatint point numbers
    const re = new RegExp("^-?\\d+(?:\.\\d{0," + (precisions || -1) + "})?")
    const finalAmount = toAmount.toString().match(re)[0]
    return finalAmount
}

export function convertToUSD(from, amount) {
    return this.convertCurrency(from, "USD", amount)
}

export function formatCurrency(amount) {
    return parseFloat(amount).toFixed(2)
}
/**
 * function dateFormater
 * convert date string into date formate for reviews
 * @export
 * @param {string} dateVal
 * @param {bool} arrFormate
 * @returns {string}
 */
export function dateFormater(dateVal, arrFormate = false) {
    // first convert the dateVal to yyyy/MM/dd
    // @see https://coderwall.com/p/gvwb9g/fix-invalid-date-on-safari-ie
    // let tmpDateStr = dateVal.replace(/-/g, "/")
    // tmpDateStr = tmpDateStr.split("/")
    const d = new Date(dateVal.replace(/-/g, "/"))
    const day = ("0" + d.getDate()).slice(-2)
    const year = d.getFullYear()
    const days = [
        t("Sun"),
        t("Mon"),
        t("Tue"),
        t("Wed"),
        t("Thu"),
        t("Fri"),
        t("Sat"),
    ]
    const months = [
        t("Jan"), t("Feb"), t("Mar"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep"), t("Oct"), t("Nov"), t("Dec"),
    ]
    const month = months[d.getMonth()]
    const dayString = days[d.getDay()]
    if (arrFormate) {
        const result = [dayString, day, month, year]
        return result
    }
    return (`${month} ${day}, ${year}`)
}

/**
 * function dateFormater
 * convert date string into date formate for reviews
 * @export
 * @param {string} price
 * @returns {string}
 */
export function priceFormat(price) {
    let p = String(price)
    if (!/,/.test(p)) {
        p = p.replace(/(\d)(?=(\d{3})+(\.\d+)?$)/g, "$1,")
    }
    return p.replace(/\.00$/, "")
}
/* eslint-enable no-useless-escape, no-param-reassign */

/**
 * Map old mobile site path to new
 *
 * @param {string} pathx
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const mapOldPathToNew = pathx => new Promise((resolve, reject) => {
    if (/^\/(find|search)\//.test(pathx)) {
        let path = pathx.replace(/^\/(find|search)/, "/tours")
            .replace("multi-day-tours", "multiday-tours")
            .replace("/all", "")
        const startCity = pathx.match(/\/start-city\/([^/]+)/)
        if (startCity) {
            const sc = decodeURIComponent(decodeURIComponent(startCity[1]))
            path = path.replace(/\/start-city\/([^/]+)/, `/from-${toSeoUrl(sc)}`)
        }
        const endCity = pathx.match(/\/end-city\/([^/]+)/)
        if (endCity) {
            const ec = decodeURIComponent(decodeURIComponent(endCity[1]))
            path = path.replace(/\/end-city\/([^/]+)/, (startCity ? "-" : "/") + "to-" + toSeoUrl(ec))
        }
        const departure = pathx.match(/\/departure\/([^/]+)/)
        if (departure) {
            const dep = decodeURIComponent(decodeURIComponent(departure[1]))
            path = path.replace(/\/departure\/([^/]+)/, ((startCity || endCity) ? "-" : "/") + "departure-" + toSeoUrl(dep))
        }
        const visitCity = pathx.match(/\/attraction\/([^/]+)/)
        if (visitCity) {
            const vc = decodeURIComponent(decodeURIComponent(visitCity[1]))
            path = path.replace(/\/attraction\/([^/]+)/, ((startCity || endCity || departure) ? "-" : "/") + "visit-" + toSeoUrl(vc))
        }
        resolve(path)
    } else if (pathx.startsWith("/easy-book-start-date/")) {
        const matches = pathx.match(/^\/easy-book-start-date\/\w+\/(\d+)/)
        resolve(`/easy-book/${matches[1]}`)
    } else if (pathx.startsWith("/easy-book-location/")) {
        const matches = pathx.match(/^\/easy-book-location\/\w+\/(\d+)/)
        resolve(`/easy-book/${matches[1]}`)
    } else if (pathx.startsWith("/login") || pathx.startsWith("/forgot-password")) {
        resolve("/login")
    } else if (pathx.startsWith("/sign-up")) {
        resolve("/signup")
    } else if (pathx.startsWith("/near-me")) {
        resolve("/tours/near-me")
    } else if (pathx.startsWith("/wish-list")) {
        resolve("/wishlist")
    } else if (pathx.startsWith("/about") || pathx.startsWith("/help")) {
        resolve("/help")
    } else if (pathx.startsWith("/pay-now")) {
        resolve("/paynow")
    } else if (pathx.startsWith("/shopping-cart")) {
        resolve("/shopping_cart")
    } else if (
        /^\/(recently-viewed|easy-book)\//.test(pathx)
    ) {
        resolve(pathx)
    } else if (
        pathx.startsWith("/product/")
        || pathx.startsWith("/hotels/")
        || pathx.startsWith("/map/")
    ) {
        // extract product id from url
        let matches = pathx.match(/^\/product\/\w+\/\w+\/(\d+)/)
        let productId = 0
        if (matches) {
            productId = parseInt(matches[1], 10)
        } else if (pathx.match(/^\/(product|hotels|map)\/(\d+)/)) {
            matches = pathx.match(/^\/(product|hotels|map)\/(\d+)/)
            productId = parseInt(matches[2], 10)
        }
        if (productId > 0) {
            // resolve product Id to url
            get("/product/getUrl", { product_id: productId }).then(response => {
                if (response.ok) {
                    resolve("/" + response.data.url_path)
                } else {
                    const msg = response.ok === false && response.message
                        ? response.message
                        : "Error from /product/getUrl"
                    const error = new Error(msg)
                    console.log(error)
                    reject(error)
                }
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        } else {
            const error = new Error(t("No product id found"))
            console.log(error)
            reject(error)
        }
    } else {
        const error = new Error(t("Unknown path"))
        reject(error)
    }
})

/**
 * Add Slashes
 *
 * @param {string} string
 *
 * @author Pratik B <pratikb.bipl@gmail.com>
*/
export const addslashes = str => str.replace(/\\/g, "\\\\")
    .replace(/'/g, "\\'")
    .replace(/"/g, "\\\"")
    .replace(/\0/g, "\\0")

/**
 * Strip Slashes
 *
 * @param {string} string
 *
 * @author Pratik B <pratikb.bipl@gmail.com>
 */
export const stripslashes = str => str.replace(/\\'/g, "'")
    .replace(/\\"/g, "\"")
    .replace(/\\0/g, "\0")
    .replace(/\\\\/g, "\\")

/**
 * Change parent element style by child element
 *
 * @param {string} htmlString html content where need to update
 * @param {string} parentElementSelector javascript element selector like: "li .cssClass"
 * @param {string} childElementSelector javascript element selector like: "li .cssClass"
 * @param {object} style object of styles which need to be set in parent
 *
 * @author Pratik B <pratikb.bipl@gmail.com>
 */
export const changeElementStyleByChild = (
    htmlString,
    parentElementSelector,
    childElementSelector,
    style,
) => {
    if (typeof DOMParser === "undefined") {
        return htmlString
    }
    const fDom = new DOMParser().parseFromString(htmlString, "text/html")
    fDom.querySelectorAll(parentElementSelector).forEach(item => {
        if (item.querySelector(childElementSelector)) {
            Object.keys(style).map(eachStyle => {
                item.style[eachStyle] = style[eachStyle]
            })
        }
    })
    return fDom.querySelector("body").innerHTML.trim()
}
