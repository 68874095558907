import Constant from "../constants"
import { get, post } from "../../common/api"
import { readCookie } from "../../common/tool/util"

/**
 * Get you may also like tours for shopping cart page
 *
 * @param {Function} dispatch dispatch
 * @param {Array} cartProducts Array of cart products
 * @param {Number} deletedProduct deleted product id, default 0 if not delete
 *
 * @author Pratik B <pratikb.bipl@gmail.com>
 */
export const youMayAlsoLike = (dispatch, cartProducts, deletedProduct = 0) => {
    const recVariant = readCookie("RECSYS_CART_RECOMMENDATION")
    if (!(recVariant && Number(recVariant) === 1)) {
        return null
    }
    const passObj = {
        product_id: "",
        _img_size: "552x",
        campaignType: false,
    }
    let tmpProductID = ""
    const getAlsoViewed = reqObject => get("/product/getCartRecommendation", reqObject)
    if (!cartProducts.ERROR) {
        let isDisabledBookNow = 0
        cartProducts.map(item => {
            if (item.isDisabledBookNow) {
                tmpProductID = item.I
                isDisabledBookNow += 1
            }
        })
        if (
            (
                deletedProduct !== 0
                && cartProducts.length === 0
            ) || (
                cartProducts.length > 1
                && isDisabledBookNow === cartProducts.length
            )
        ) {
            passObj.campaignType = "HRNN"
        } else if (
            isDisabledBookNow === cartProducts.length
            && isDisabledBookNow === 1
            && tmpProductID !== 0
        ) {
            passObj.campaignType = "SIMS"
            passObj.product_id = tmpProductID
        }
        if (passObj.campaignType) {
            return getAlsoViewed(passObj).then(data => dispatch({
                type: Constant.GET_CART_ALSO_VIEWED_SUCCESS,
                payload: {
                    type: passObj.campaignType,
                    productList: data,
                },
            }))
        }
    }
    return dispatch({
        type: Constant.GET_CART_ALSO_VIEWED_ERROR,
        payload: {
            type: "",
            productList: [],
        },
    })
}

export const getCart = () => dispatch => {
    dispatch({
        type: Constant.GET_CART_REQUEST,
    })
    get("/cart/getCartProduct")
        .then(data => {
            // youMayAlsoLike(dispatch, data)
            if (data.ERROR) {
                return dispatch({
                    type: Constant.GET_CART_ERROR,
                    payload: data,
                })
            }
            return dispatch({
                type: Constant.GET_CART_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.error(err)
        })
}

export const clearCart = () => dispatch => {
    // youMayAlsoLike(dispatch, [])
    dispatch({
        type: Constant.CLEAR_CART,
    })
}
export const removeToCart = (CustomerBasketId, ProductId) => dispatch => {
    dispatch({
        type: Constant.REMOVE_CART_REQUEST,
    })
    post("/cart/deleteCartProduct", {}, { CustomerBasketId, ProductId })
        .then(data => {
            // youMayAlsoLike(dispatch, data, ProductId)
            if (data.ERROR) {
                return dispatch({
                    type: Constant.REMOVE_CART_ERROR,
                    payload: data,
                })
            }
            return dispatch({
                type: Constant.REMOVE_CART_SUCCESS,
                payload: data,
                productId: ProductId,
            })
        })
        .catch(err => {
            console.error(err)
        })
}

const CartAct = {
    getCart,
    removeToCart,
    clearCart,
}
export default CartAct
