import Constant from "../constants"

const initialState = {
    isAddWishlist: false,
    initWishlistData: [],
    initWishlistError: {
        ERROR: ""
    },
    addWishlistData: [],
    addWishlistError: {
        ERROR: ""
    },
    removeWishlistData: [],
    removeWishlistError: {
        ERROR: ""
    },
    getWishlistData: [],
    getWishlistError: {
        ERROR: ""
    },
}
export default function WishlistReducer(state = initialState, action) {
    switch (action.type) {
    case Constant.INIT_WISHLIST:
        return { ...state }
    case Constant.INIT_WISHLIST_SUCCESS:
        return { ...state, initWishlistData: action.payload, isAddWishlist: true }
    case Constant.INIT_WISHLIST_ERROR:
        return { ...state, initWishlistError: action.payload }
    case Constant.GET_WISHLIST:
        return { ...state }
    case Constant.GET_WISHLIST_SUCCESS:
        action.payload.map(
          item => {
              item.isWishlisted = true
          }
        )
        return {
            ...state,
            getWishlistData: action.payload
        }
    case Constant.GET_WISHLIST_ERROR:
        return { ...state, getWishlistError: action.payload }
    case Constant.ADD_WISHLIST:
        return { ...state }
    case Constant.ADD_WISHLIST_SUCCESS:
        return { ...state, initWishlistData: action.payload }
    case Constant.ADD_WISHLIST_ERROR:
        return { ...state, addWishlistError: action.payload }
    case Constant.REMOVE_WISHLIST:
        return { ...state }
    case Constant.REMOVE_WISHLIST_SUCCESS:
        return { ...state, removeWishlistData: action.payload }
    case Constant.REMOVE_WISHLIST_ERROR:
        return { ...state, removeWishlistError: action.payload }
    default:
        return state
    }
}
