import React from "react"
import loadable from "@loadable/component"
import { Redirect } from "react-router"
import { t } from "./index"
import Home from "../client/containers/home"
import Tours from "../client/containers/tours"
import Product from "../client/containers/product"

// import Login from "../client/containers/login"
// import Signup from "../client/containers/signup"
// import Checkout from "../client/containers/checkout"
// import PayNow from "../client/containers/checkout/paynow"
// import Profile from "../client/containers/profile"
// import Wishlist from "../client/containers/wishlist"
// import RecentlyViewed from "../client/containers/recently-viewed"
// import ThankYou from "../client/containers/thank-you"
// import Help from "../client/containers/help"
// import BookingOption from "../client/containers/booking-option"
// import BookingOptionSelection from "../client/containers/booking-option/booking-option-selection"
// import ShoppingCart from "../client/containers/shopping-cart"
// import AppDownload from "../client/common/components/app-download"
// import PrivacyPolicy from "../client/containers/privacy-policy"
// import Blank from "../client/common/components/BlankComponent"
// import UserAccount from "../client/containers/user-account"
// import UserReservations from "../client/containers/user-account/components/reservations"
// import MyReviews from "../client/containers/user-account/components/my-reviews"
// import ProfileUpdate from "../client/containers/user-account/components/profile-edit"
// import PasswordEdit from "../client/containers/user-account/components/profile-edit/password"
// import SavedTravellers from "../client/containers/user-account/components/saved-travellers"
// import EditTraveler
//     from "../client/containers/user-account/components/saved-travellers/edit-traveler-details"
// import Newsletters from "../client/containers/user-account/components/newsletters"

// const Home = loadable(() => import("../client/containers/home"))
// const Product = loadable(() => import("../client/containers/product"))
// const Tours = loadable(() => import("../client/containers/tours"))

const Login = loadable(() => import("../client/containers/login"))
const Signup = loadable(() => import("../client/containers/signup"))
const Checkout = loadable(() => import("../client/containers/checkout"))
const PayNow = loadable(() => import("../client/containers/checkout/paynow"))
const Profile = loadable(() => import("../client/containers/profile"))
const Wishlist = loadable(() => import("../client/containers/wishlist"))
const RecentlyViewed = loadable(() => import("../client/containers/recently-viewed"))
const ThankYou = loadable(() => import("../client/containers/thank-you"))
const Help = loadable(() => import("../client/containers/help"))
const BookingOption = loadable(() => import("../client/containers/booking-option"))
const BookingOptionSelection = loadable(() => import("../client/containers/booking-option/booking-option-selection"))
const ShoppingCart = loadable(() => import("../client/containers/shopping-cart"))
const AppDownload = loadable(() => import("../client/common/components/app-download"))
const PrivacyPolicy = loadable(() => import("../client/containers/privacy-policy"))
const UserAccount = loadable(() => import("../client/containers/user-account"))
const UserReservations = loadable(() => import("../client/containers/user-account/components/reservations"))
const MyReviews = loadable(() => import("../client/containers/user-account/components/my-reviews"))
const ProfileUpdate = loadable(() => import("../client/containers/user-account/components/profile-edit"))
const PasswordEdit = loadable(() => import("../client/containers/user-account/components/profile-edit/password"))
const SavedTravellers = loadable(() => import("../client/containers/user-account/components/saved-travellers"))
const EditTraveler = loadable(() => import("../client/containers/user-account/components/saved-travellers/edit-traveler-details"))
const Newsletters = loadable(() => import("../client/containers/user-account/components/newsletters"))
const Blank = loadable(() => import("../client/common/components/BlankComponent"))


// import NotFound from "../client/common/components/not-found"
// import Paths from "../client/paths"
//
// const routes = []
// Paths.forEach(item => {
//     routes.push({
//         path: item.path,
//         exact: item.exact,
//         component: require.ensure(item.component)
//     })
// })
const routes = [
    {
        path: "/",
        exact: true,
        component: Home,
    },
    {
        path: "/login",
        component: Login,
    },
    {
        path: "/signup",
        component: Signup,
    },
    {
        path: "/checkout/:productId",
        component: Checkout,
    },
    {
        path: "/paynow/:orderId/:gId*",
        component: PayNow,
    },
    {
        path: "/tours/:searchKeyword/:entityType*",
        component: Tours,
    },
    {
        path: "/popular/:searchKeyword/:entityType*",
        component: Tours,
    },
    {
        path: "/profile",
        component: Profile,
    },
    {
        path: "/recently-viewed",
        component: RecentlyViewed,
    },
    {
        path: "/wishlist",
        component: Wishlist,
    },
    {
        path: "/checkout-success/:orderId",
        component: ThankYou,
    },
    {
        path: "/help",
        component: Help,
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
    },
    {
        path: "/shopping_cart",
        component: ShoppingCart,
    },
    {
        path: "/account.html",
        component: UserAccount,
        exact: true,
    },
    {
        path: "/ac-leave-review.html",
        component: MyReviews,
    },
    {
        path: "/account-edit.html",
        component: ProfileUpdate,
        exact: true,
    },
    {
        path: "/edit-password",
        component: PasswordEdit,
        exact: true,
    },
    {
        path: "/frequent-contacts.html",
        component: SavedTravellers,
        exact: true,
    },
    // react router v5 supports array of paths
    {
        path: "/account_history_info.html",
        component: UserReservations,
        exact: true,
    },
    {
        path: "/account_history_info.html?departure=past",
        component: UserReservations,
        exact: true,
    },
    // react router v5 supports array of paths
    {
        path: "/traveler-detail/:traveler",
        component: EditTraveler,
        exact: true,
    },
    {
        path: "/add-traveler",
        component: EditTraveler,
        exact: true,
    },
    {
        path: "/account_newsletters.html",
        component: Newsletters,
        exact: true,
    },
    {
        path: "/easy-book/:productId/:selectionName",
        component: BookingOptionSelection,
    },
    {
        path: "/landing/*",
        loadDesktopSite: true,
        component: Blank,
    },
    {
        path: "/country/*",
        loadDesktopSite: true,
        component: Blank,
    },
    {
        path: "/c/*",
        loadDesktopSite: true,
        component: Blank,
        fullPath: "/country/",
    },
    {
        path: "/ctn/*",
        loadDesktopSite: true,
        component: Blank,
        fullPath: "/",
    },
    {
        path: "/special-travel-offers-and-deals*",
        loadDesktopSite: true,
        component: Blank,
    },
    {
        path: "/join_rewards4fun.html*",
        loadDesktopSite: true,
        component: Blank,
    },
    // add urls that have recorded in google search console as anomalies
    // redirect following urls to continent pages
    {
        path: [
            "/north-america-tours(/)?",
            "/south-america-tours(/)?",
            "/europe-tours(/)?",
            "/central-america-tours(/)?",
            "/africa-tours(/)?",
            "/oceania-tours(/)?",
            "/america-del-sur-tours(/)?",
        ],
        component: props => <Redirect to={"/ctn" + props.match.url} />,
    },
    {
        path: t("DESKTOP_SITE_ROUTE_HOTELS"),
        loadDesktopSite: true,
        component: Blank,
    },
    {
        path: "/*.html",
        component: Product,
    },
    {
        path: "/easy-book/:productId",
        component: BookingOption,
    },
    {
        path: "/app-download",
        component: AppDownload,
    },
]

export default routes
