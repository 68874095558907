const Constant = {
    INIT_DATA: "INIT_DATA",
    INIT_DATA_REQUEST: "INIT_DATA_REQUEST",
    INIT_DATA_CANCELED: "INIT_DATA_CANCELED",
    ADD_TODO: "ADD_TODO",
    LOADING: "LOADING",
    ADD_PUBLIC_DETAIL: "ADD_PUBLIC_DETAIL",
    INCREMENT_COUNTER: "INCREMENT_COUNTER",
    DECREMENT_COUNTER: "DECREMENT_COUNTER",
    REQUEST_DATA: "REQUEST_DATA",
    RECEIVE_DATA: "RECEIVE_DATA",
    DATA_ERROR: "DATA_ERROR",
    FETCH_REQUEST: "FETCH_REQUEST",
    FETCH_SUCCESS: "FETCH_SUCCESS",
    FETCH_ERROR: "FETCH_ERROR",
    // Menu
    SHOW_MENU_TOGGLE: "SHOW_MENU_TOGGLE",
    SHOW_MENU: "SHOW_MENU",
    SHOW_MENU_DESTINATIONS: "SHOW_MENU_DESTINATIONS",
    SHOW_LANGUAGE_MENU: "SHOW_LANGUAGE_MENU",
    SHOW_EXPERIENCE_MENU: "SHOW_EXPERIENCE_MENU",
    MENU_DESTINATIONS_DATA_REQUEST: "MENU_DESTINATIONS_DATA_REQUEST",
    MENU_DESTINATIONS_DATA_SUCCESS: "MENU_DESTINATIONS_DATA_SUCCESS",
    MENU_DESTINATIONS_DATA_ERROR: "MENU_DESTINATIONS_DATA_ERROR",
    // Meta-data
    FETCH_META_DETAILS: "FETCH_META_DETAILS",
    FETCH_META_DETAILS_SUCCESS: "FETCH_META_DETAILS_SUCCESS",
    FETCH_META_DETAILS_ERROR: "FETCH_META_DETAILS_ERROR",
    META_DETAILS_RESET: "META_DETAILS_RESET",
    // product Detail
    CLEAR_PRODUCT_DETAIL: "CLEAR_PRODUCT_DETAIL",
    FETCH_PRODUCT_ITINERARY_PRICING_SUCCESS: "FETCH_PRODUCT_ITINERARY_PRICING_SUCCESS",
    FETCH_PRODUCT_ITINERARY_PRICING_FAILED: "FETCH_PRODUCT_ITINERARY_PRICING_FAILED",
    FETCH_PRODUCT_PRICING_SUCCESS: "FETCH_PRODUCT_PRICING_SUCCESS",
    FETCH_PRODUCT_ALSO_VIEWED_REQUEST: "FETCH_PRODUCT_ALSO_VIEWED_REQUEST",
    FETCH_PRODUCT_ALSO_VIEWED_SUCCESS: "FETCH_PRODUCT_ALSO_VIEWED_SUCCESS",
    FETCH_PRODUCT_ALSO_VIEWED_FAILED: "FETCH_PRODUCT_ALSO_VIEWED_FAILED",
    FETCH_PRODUCT_BY_URL_SUCCESS: "FETCH_PRODUCT_BY_URL_SUCCESS",
    FETCH_PRODUCT_BY_URL_FAILED: "FETCH_PRODUCT_BY_URL_FAILED",
    FETCH_PRODUCT_BY_ID: "FETCH_PRODUCT_BY_ID",
    FETCH_PRODUCT_BY_ID_RESET: "FETCH_PRODUCT_BY_ID_RESET",
    FETCH_PRODUCT_BY_ID_SUCCESS: "FETCH_PRODUCT_BY_ID_SUCCESS",
    FETCH_PRODUCT_BY_ID_FAILED: "FETCH_PRODUCT_BY_ID_FAILED",
    PRODUCT_ID_UPDATED: "PRODUCT_ID_UPDATED",
    FETCH_GREVIEW_DETAILS: "FETCH_GREVIEW_DETAILS",
    FETCH_PRODUCT_REVIEWS_SUCCESS: "FETCH_PRODUCT_REVIEWS_SUCCESS",
    FETCH_PRODUCT_REVIEWS_FAILED: "FETCH_PRODUCT_REVIEWS_FAILED",
    // Google review
    FETCH_PRODUCT_GREVIEWS: "FETCH_PRODUCT_GREVIEWS",
    FETCH_PRODUCT_GREVIEWS_SUCCESS: "FETCH_PRODUCT_GREVIEWS_SUCCESS",
    FETCH_PRODUCT_GREVIEWS_FAILED: "FETCH_PRODUCT_GREVIEWS_FAILED",

    PRODUCT_SHOULD_REDIRECT: "PRODUCT_SHOULD_REDIRECT",
    PRODUCT_NOT_FOUND: "PRODUCT_NOT_FOUND",
    RESET_PRODUCT_NOT_FOUND: "RESET_PRODUCT_NOT_FOUND",
    EXPERT_QUESTION_POSTED: "EXPERT_QUESTION_POSTED",
    // small Group Popup Toogle
    SHOW_SMALL_GROUP_TOGGLE: "SHOW_SMALL_GROUP_TOGGLE",
    // show instant confirmation popup
    SHOW_INSTANT_CONFIRMATION_POPUP_TOGGLE: "SHOW_INSTANT_CONFIRMATION_POPUP_TOGGLE",
    // more review
    MORE_REVIEW: "MORE_REVIEW",
    SET_REVIEW_PAGE_NO: "SET_REVIEW_PAGE_NO",
    SET_REVIEW_NOT_FOUND: "SET_REVIEW_NOT_FOUND",
    FETCH_REVIEW_DETAILS: "FETCH_REVIEW_DETAILS",
    FETCH_REVIEW_DETAILS_SUCCESS: "FETCH_REVIEW_DETAILS_SUCCESS",
    FETCH_REVIEW_DETAILS_ERROR: "FETCH_REVIEW_DETAILS_ERROR",
    FETCH_REVIEW_TRAVELER_PHOTOS_SUCCESS: "FETCH_REVIEW_TRAVELER_PHOTOS_SUCCESS",
    FETCH_REVIEW_TRAVELER_PHOTOS_FAILED: "FETCH_REVIEW_TRAVELER_PHOTOS_FAILED",
    FETCH_REVIEW_TRAVELER_PHOTOS_LARGE_SUCCESS: "FETCH_REVIEW_TRAVELER_PHOTOS_LARGE_SUCCESS",
    FETCH_REVIEW_TRAVELER_PHOTOS_LARGE_FAILED: "FETCH_REVIEW_TRAVELER_PHOTOS_LARGE_FAILED",
    FETCH_REVIEWS_INFO_SUCCESS: "FETCH_REVIEWS_INFO_SUCCESS",
    FETCH_REVIEWS_INFO_FAILED: "FETCH_REVIEWS_INFO_FAILED",
    // Init Recently Viewed
    RECENTLY_VIEWED_DATA_INIT: "RECENTLY_VIEWED_DATA_INIT",
    RECENTLY_VIEWED_DATA_REQUEST: "RECENTLY_VIEWED_DATA_REQUEST",
    RECENTLY_VIEWED_DATA_SUCCESS: "RECENTLY_VIEWED_DATA_SUCCESS",
    RECENTLY_VIEWED_DATA_ERROR: "RECENTLY_VIEWED_DATA_ERROR",
    // Init Wishlist
    INIT_WISHLIST: "INIT_WISHLIST",
    INIT_WISHLIST_SUCCESS: "INIT_WISHLIST_SUCCESS",
    INIT_WISHLIST_ERROR: "INIT_WISHLIST_ERROR",
    // Add to Wishlist
    ADD_WISHLIST: "ADD_WISHLIST",
    ADD_WISHLIST_SUCCESS: "ADD_WISHLIST_SUCCESS",
    ADD_WISHLIST_ERROR: "ADD_WISHLIST_ERROR",
    // Remove to Wishlist
    REMOVE_WISHLIST: "REMOVE_WISHLIST",
    REMOVE_WISHLIST_SUCCESS: "REMOVE_WISHLIST_SUCCESS",
    REMOVE_WISHLIST_ERROR: "REMOVE_WISHLIST_ERROR",
    // get Wishlist
    GET_WISHLIST: "GET_WISHLIST",
    GET_WISHLIST_SUCCESS: "GET_WISHLIST_SUCCESS",
    GET_WISHLIST_ERROR: "GET_WISHLIST_ERROR",
    // Update Product Wishlist
    IS_WISHLISTED: "IS_WISHLISTED",
    // add review
    ADD_REVIEW: "ADD_REVIEW",
    ADD_REVIEW_SUCCESS: "ADD_REVIEW_SUCCESS",
    ADD_REVIEW_ERROR: "ADD_REVIEW_ERROR",
    // Upload Travel Pics
    UPLOAD_TRAVELES_PHOTO: "UPLOAD_TRAVELES_PHOTO",
    UPLOAD_TRAVELES_PHOTO_SUCCESS: "UPLOAD_TRAVELES_PHOTO_SUCCESS",
    UPLOAD_TRAVELES_PHOTO_ERROR: "UPLOAD_TRAVELES_PHOTO_ERROR",
    UPLOAD_TRAVELES_PHOTO_CLEAR: "UPLOAD_TRAVELES_PHOTO_CLEAR",
    // Easy Book
    EASY_BOOK: "EASY_BOOK",
    EASY_BOOK_SUCCESS: "EASY_BOOK_SUCCESS",
    EASY_BOOK_UPDATE: "EASY_BOOK_UPDATE",
    EASY_BOOK_PRICE_FETCHING: "EASY_BOOK_PRICE_FETCHING",
    EASY_BOOK_PRICE_UPDATE: "EASY_BOOK_PRICE_UPDATE",
    EASY_BOOK_ERROR: "EASY_BOOK_ERROR",
    FETCH_EASY_BOOK_START_DATE_PICKER: "FETCH_EASY_BOOK_START_DATE_PICKER",
    FETCH_EASY_BOOK_START_DATE_PICKER_SUCCESS: "FETCH_EASY_BOOK_START_DATE_PICKER_SUCCESS",
    FETCH_EASY_BOOK_START_DATE_PICKER_FAILED: "FETCH_EASY_BOOK_START_DATE_PICKER_FAILED",
    FETCH_START_NEXT_CALENDAR_DATES: "FETCH_START_NEXT_CALENDAR_DATES",
    FETCH_EASY_BOOK_LOCATION_PICKER_SUCCESS: "FETCH_EASY_BOOK_LOCATION_PICKER_SUCCESS",
    FETCH_EASY_BOOK_LOCATION_PICKER_FAILED: "FETCH_EASY_BOOK_LOCATION_PICKER_FAILED",
    EASY_BOOK_PRICE_CALCULATING: "EASY_BOOK_PRICE_CALCULATING",
    // home page
    FETCH_HOME_SUCCESS: "FETCH_HOME_SUCCESS",
    FETCH_HOME_ERROR: "FETCH_HOME_ERROR",
    FETCH_HOME_BANNERS_SUCCESS: "FETCH_HOME_BANNERS_SUCCESS",
    FETCH_HOME_BANNERS_FAILED: "FETCH_HOME_BANNERS_FAILED",
    FETCH_HOME_EXPERIENCE_DATA_SUCCESS: "FETCH_HOME_EXPERIENCE_DATA_SUCCESS",
    FETCH_HOME_EXPERIENCE_DATA_FAILED: "FETCH_HOME_EXPERIENCE_DATA_FAILED",
    FETCH_HOME_PROMOTIONS_SUCCESS: "FETCH_HOME_PROMOTIONS_SUCCESS",
    FETCH_HOME_PROMOTIONS_FAILED: "FETCH_HOME_PROMOTIONS_FAILED",
    FETCH_HOME_POPULAR_DESTINATIONS_SUCCESS: "FETCH_HOME_POPULAR_DESTINATIONS_SUCCESS",
    FETCH_HOME_POPULAR_DESTINATIONS_FAILED: "FETCH_HOME_POPULAR_DESTINATIONS_FAILED",
    FETCH_HOME_RECENTLY_VIEWED_SUCCESS: "FETCH_HOME_RECENTLY_VIEWED_SUCCESS",
    FETCH_HOME_RECENTLY_VIEWED_FAILED: "FETCH_HOME_RECENTLY_VIEWED_FAILED",
    // nearByMeTourQuickSearch
    FETCH_NEAR_BY_ME_TOUR_QUICK_SEARCH_SUCCESS: "FETCH_NEAR_BY_ME_TOUR_QUICK_SEARCH_SUCCESS",
    // tours
    FILTER_RESET: "FILTER_RESET",
    SET_FILTER_ADVANCED: "SET_FILTER_ADVANCED",
    FILTER_DATA_SET_IN: "FILTER_DATA_SET_IN",
    FETCH_TOURS: "FETCH_TOURS",
    FETCH_TOURS_SUCCESS: "FETCH_TOURS_SUCCESS",
    FETCH_TOURS_ERROR: "FETCH_TOURS_ERROR",
    FETCH_TOURS_ERROR_ALGOLIA: "FETCH_TOURS_ERROR_ALGOLIA",
    CHANGED_FILTER_VISIBILITY: "CHANGED_FILTER_VISIBILITY",
    OFF_SREVER_FLAG_TOUR: "OFF_SREVER_FLAG_TOUR",
    TOURS_LIST_PRODUCT_WISHLIST_CHANGE: "TOURS_LIST_PRODUCT_WISHLIST_CHANGE",
    // search
    CHANGED_SEARCH_VISIBILITY: "CHANGED_SEARCH_VISIBILITY",
    UPDATED_SEARCH_TEXT_VALUE: "UPDATED_SEARCH_TEXT_VALUE",
    SUGGESTIONS_GET: "SUGGESTIONS_GET",
    SUGGESTIONS_GET_SUCCESS: "SUGGESTIONS_GET_SUCCESS",
    SUGGESTIONS_GET_ERROR: "SUGGESTIONS_GET_ERROR",
    SUGGESTIONS_SEARCH_VALUE_SET: "SUGGESTIONS_SEARCH_VALUE_SET",
    SUGGESTIONS_SEARCH_VALUE_CLEAR: "SUGGESTIONS_SEARCH_VALUE_CLEAR",
    LOAD_SUGGESTIONS_BEGIN: "LOAD_SUGGESTIONS_BEGIN",
    SHOW_AT_HOME: "SHOW_AT_HOME",
    // Recent Search
    RECENT_SEARCH_SUCCESS: "RECENT_SEARCH_SUCCESS",
    RECENT_SEARCH_ERROR: "RECENT_SEARCH_ERROR",
    // Popular Search
    POPULAR_SEARCH: "POPULAR_SEARCH",
    POPULAR_SEARCH_ERROR: "POPULAR_SEARCH_ERROR",
    POPULAR_SEARCH_SUCCESS: "POPULAR_SEARCH_SUCCESS",
    SET_FILTER_HIDDEN: "SET_FILTER_HIDDEN",
    // Share
    SET_SHARE_HIDDEN: "SET_SHARE_HIDDEN",
    // Show Full Itinerary
    SET_FULL_ITINERARY_HIDDEN: "SET_FULL_ITINERARY_HIDDEN",
    // Check Login
    INIT_LOGIN_REQUEST: "INIT_LOGIN_REQUEST",
    CHECK_INIT_LOGIN_REQUEST: "CHECK_INIT_LOGIN_REQUEST",
    INIT_LOGIN_SUCCESS: "INIT_LOGIN_SUCCESS",
    INIT_LOGIN_ERROR: "INIT_LOGIN_ERROR",
    // Check Login
    CHECK_LOGIN_REQUEST: "CHECK_LOGIN_REQUEST",
    CHECK_LOGIN_SUCCESS: "CHECK_LOGIN_SUCCESS",
    CHECK_LOGIN_ERROR: "CHECK_LOGIN_ERROR",
    REMEMBER_USER_DATA: "REMEMBER_USER_DATA",
    // Get Profile
    GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
    GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
    GET_PROFILE_ERROR: "GET_PROFILE_ERROR",
    // Login
    FETCH_LOGIN_REQUEST: "FETCH_LOGIN_REQUEST",
    FETCH_LOGIN_SUCCESS: "FETCH_LOGIN_SUCCESS",
    FETCH_LOGIN_ERROR: "FETCH_LOGIN_ERROR",
    // clear User Details
    CLEAR_USER_DETAILS: "CLEAR_USER_DETAILS",
    // Social Login
    FETCH_SOCIAL_LOGIN_REQUEST: "FETCH_SOCIAL_LOGIN_REQUEST",
    FETCH_SOCIAL_LOGIN_SUCCESS: "FETCH_SOCIAL_LOGIN_SUCCESS",
    FETCH_SOCIAL_LOGIN_ERROR: "FETCH_SOCIAL_LOGIN_ERROR",
    // Forgot Password
    FETCH_FORGOT_PASSWORD_REQUEST: "FETCH_FORGOT_PASSWORD_REQUEST",
    FETCH_FORGOT_PASSWORD_SUCCESS: "FETCH_FORGOT_PASSWORD_SUCCESS",
    FETCH_FORGOT_PASSWORD_ERROR: "FETCH_FORGOT_PASSWORD_ERROR",
    // Social Check Login
    FETCH_SOCIAL_REGIISTERED_REQUEST: "FETCH_SOCIAL_REGIISTERED_REQUEST",
    FETCH_SOCIAL_REGIISTERED_SUCCESS: "FETCH_SOCIAL_REGIISTERED_SUCCESS",
    FETCH_SOCIAL_REGIISTERED_ERROR: "FETCH_SOCIAL_REGIISTERED_ERROR",
    // Social Singup
    FETCH_SOCIAL_REGIISTER_REQUEST: "FETCH_SOCIAL_REGIISTER_REQUEST",
    FETCH_SOCIAL_REGIISTER_SUCCESS: "FETCH_SOCIAL_REGIISTER_SUCCESS",
    // login As Guest
    LOGIN_AS_GUEST_REQUEST: "LOGIN_AS_GUEST_REQUEST",
    LOGIN_AS_GUEST_SUCCESS: "LOGIN_AS_GUEST_SUCCESS",
    LOGIN_AS_GUEST_ERROR: "LOGIN_AS_GUEST_ERROR",
    // Singup
    FETCH_SIGNUP_REQUEST: "FETCH_SIGNUP_REQUEST",
    FETCH_SIGNUP_SUCCESS: "FETCH_SIGNUP_SUCCESS",
    FETCH_SIGNUP_ERROR: "FETCH_SIGNUP_ERROR",
    FETCH_PRIVACY_POLICY_SUCCESS: "FETCH_PRIVACY_POLICY_SUCCESS",
    // Can Activate Guest Account
    ACTIVE_GUEST_REQUEST: "ACTIVE_GUEST_REQUEST",
    ACTIVE_GUEST_SUCCESS: "ACTIVE_GUEST_SUCCESS",
    ACTIVE_GUEST_ERROR: "ACTIVE_GUEST_ERROR",
    // Can Activate Guest Account
    CAN_ACTIVE_GUEST_REQUEST: "CAN_ACTIVE_GUEST_REQUEST",
    CAN_ACTIVE_GUEST_SUCCESS: "CAN_ACTIVE_GUEST_SUCCESS",
    // Booking
    FETCH_PICKERLIST: "FETCH_PICKERLIST",
    FETCH_PICKERLIST_SUCCESS: "FETCH_PICKERLIST_SUCCESS",
    FETCH_PICKERLIST_ERROR: "FETCH_PICKERLIST_ERROR",
    // Booking
    CHECKOUT_DATA_REQUEST: "CHECKOUT_DATA_REQUEST",
    CHECKOUT_DATA_SUCCESS: "CHECKOUT_DATA_SUCCESS",
    CHECKOUT_DATA_ERROR: "CHECKOUT_DATA_ERROR",
    CHECKOUT_ORDER_SUCCESS: "CHECKOUT_ORDER_SUCCESS",
    // Get Orderlist
    GET_RESERVATION_REQUEST: "GET_RESERVATION_REQUEST",
    GET_RESERVATION_SUCCESS: "GET_RESERVATION_SUCCESS",
    GET_RESERVATION_ERROR: "GET_RESERVATION_ERROR",
    // Get Frequent Contacts
    FREQUENT_CONTACTS_REQUEST: "FREQUENT_CONTACTS_REQUEST",
    FREQUENT_CONTACTS_SUCCESS: "FREQUENT_CONTACTS_SUCCESS",
    FREQUENT_CONTACTS_ERROR: "FREQUENT_CONTACTS_ERROR",
    FREQUENT_CONTACTS_VALUE_SET: "FREQUENT_CONTACTS_VALUE_SET",
    FREQUENT_CONTACTS_VALUE_CLEAR: "FREQUENT_CONTACTS_VALUE_CLEAR",
    // Get Cart
    GET_CART_REQUEST: "GET_CART_REQUEST",
    GET_CART_SUCCESS: "GET_CART_SUCCESS",
    GET_CART_ERROR: "GET_CART_ERROR",
    CLEAR_CART: "CLEAR_CART",
    GET_CART_ALSO_VIEWED_SUCCESS: "GET_CART_ALSO_VIEWED_SUCCESS",
    GET_CART_ALSO_VIEWED_ERROR: "GET_CART_ALSO_VIEWED_ERROR",
    // Remove to cart
    REMOVE_CART_REQUEST: "REMOVE_CART_REQUEST",
    REMOVE_CART_SUCCESS: "REMOVE_CART_SUCCESS",
    REMOVE_CART_ERROR: "REMOVE_CART_ERROR",
    // Update Requested PATH
    UPDATE_REQUESTED_PATH: "UPDATE_REQUESTED_PATH",
    // Logout
    LOGOUT_USER_INIT: "LOGOUT_USER_INIT",
    LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
    LOGOUT_USER_ERROR: "LOGOUT_USER_ERROR",
    // Get StoreData
    GET_STOREDATA_REQUEST: "GET_STOREDATA_REQUEST",
    GET_STOREDATA_SUCCESS: "GET_STOREDATA_SUCCESS",
    GET_STOREDATA_ERROR: "GET_STOREDATA_ERROR",
    // set transitionName
    SET_TRANSITION: "SET_TRANSITION",
    // Set Currency
    SET_CURRENCY: "SET_CURRENCY",
    SET_CURRENCY_COOKIE: "SET_CURRENCY_COOKIE",
    // Track FBPixel
    TRACK_FBPIXEL: "TRACK_FBPIXEL",
    IS_PAYPAL_CHECKOUT: "IS_PAYPAL_CHECKOUT",
    // Account Page
    UPDATE_PROFILE_PICTURE_SUCCESS: "UPDATE_PROFILE_PICTURE_SUCCESS",
    UPDATE_PROFILE_PICTURE_FAILURE: "UPDATE_PROFILE_PICTURE_FAILURE",
    FETCH_ORDERS_REQUEST: "FETCH_ORDERS_REQUEST",
    UPDATE_ACCOUNT_INFO_REQUEST: "UPDATE_ACCOUNT_INFO_REQUEST",
    GET_ACCOUNT_INFO_SUCCESS: "GET_ACCOUNT_INFO_SUCCESS",
    UPDATE_ACCOUNT_INFO_SUCCESS: "UPDATE_ACCOUNT_INFO_SUCCESS",
    UPDATE_ACCOUNT_INFO_ERROR: "UPDATE_ACCOUNT_INFO_ERROR",
    FETCH_TRAVELER_DETAILS_SUCCESS: "FETCH_TRAVELER_DETAILS_SUCCESS",
    UPDATE_TRAVELER_DETAILS_SUCCESS: "UPDATE_TRAVELER_DETAILS_SUCCESS",
    UPDATE_SAVED_TRAVELER_ERROR: "UPDATE_SAVED_TRAVELER_ERROR",
    UPDATE_ERROR: "UPDATE_ERROR",
    UPDATE_NEWSLETTERS_SUCCESS: "UPDATE_NEWSLETTERS_SUCCESS",
    TOGGLE_REVIEW_IS_UPLOADING: "TOGGLE_REVIEW_IS_UPLOADING",
    FETCH_ORDERS_LIST_SUCCESS: "FETCH_ORDERS_LIST_SUCCESS",
    CLEAR_SIGN_UP_DATA: "CLEAR_SIGN_UP_DATA",
    // Clear checkout product
    CLEAR_CART_PRODUCT_BOOKINGREDUCERS: "CLEAR_CART_PRODUCT_BOOKINGREDUCERS",
    CLEAR_CART_PRODUCT_CHECKOUTREDUCERS: "CLEAR_CART_PRODUCT_CHECKOUTREDUCERS",
    APPLIED_PROMO_UPDATED: "APPLIED_PROMO_UPDATED",
    APPLIED_PROMO_UPDATED_REQUEST: "APPLIED_PROMO_UPDATED_REQUEST",
}
export default Constant
