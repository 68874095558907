import Constant from "../constants"

const initialState = {
    initLoginData: {},
    getProfileData: {},
    getProfileError: {
        ERROR: {},
    },
    activateGuestAccountData: { SUCCESS: 0 },
    activateGuestAccountError: { ERROR: "" },
    canActivateGuestAccountData: { can_activate: false },
    isToken: false,
    initLoginError: "",
    userDetails: {},
    authenticated: false,
    authenticatedFetched: false,
    requestedPath: "/",
    registeredData: {},
    socialResponce: {},
    referer: "",
    forgotresponse: "",
    forgoterror: "",
    authSource: "",
    authTime: "",
    expires: "",
    lastRequestTime: "",
    accessToken: "",
    cartSize: 0, // cartSize is used if user didn't loging
}
export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
    case Constant.INIT_LOGIN_SUCCESS:
        return {
            ...state,
            isToken: action.isToken,
            initLoginData: action.payload,
            initLoginError: "",
            cartSize: 0, // no need after user login
        }
    case Constant.INIT_LOGIN_ERROR:
        return {
            ...state,
            isToken: action.isToken,
            initLoginError: action.payload,
            cartSize: action.cartSize,
        }
    case Constant.FETCH_LOGIN_REQUEST:
        return {
            ...state,
            authenticated: false,
            authenticatedFetched: false,
            initLoginError: "",
        }
    case Constant.FETCH_LOGIN_SUCCESS:
        return {
            ...state,
            userDetails: action.payload,
            authenticated: true,
            authenticatedFetched: true,
            referer: action.referer,
        }
    case Constant.FETCH_LOGIN_ERROR:
        return {
            ...state,
            authenticated: false,
            authenticatedFetched: true,
            userDetails: action.payload,
            referer: action.referer,
        }
    case Constant.CHECK_LOGIN_REQUEST:
        return {
            ...state,
            authenticated: false,
            authenticatedFetched: false,
            requestedPath: action.requestedPath,
        }
    case Constant.CHECK_LOGIN_SUCCESS:
        return {
            ...state,
            userDetails: action.payload,
            authenticated: true,
            authenticatedFetched: true,
            referer: action.referer,
        }
    case Constant.CHECK_LOGIN_ERROR:
        return {
            ...state,
            authenticated: false,
            authenticatedFetched: true,
            referer: action.referer,
        }
    case Constant.CLEAR_USER_DETAILS:
        return { ...state, userDetails: {} }
    case Constant.FETCH_SOCIAL_LOGIN_REQUEST:
        return {
            ...state,
            authenticated: action.authenticated,
            authenticatedFetched: false,
        }
    case Constant.FETCH_SOCIAL_LOGIN_SUCCESS:
        return {
            ...state,
            userDetails: action.payload,
            authenticated: true,
            authenticatedFetched: true,
        }
    case Constant.FETCH_SOCIAL_LOGIN_ERROR:
        return {
            ...state,
            authenticated: false,
            authenticatedFetched: true,
            referer: action.referer,
        }
    case Constant.FETCH_SOCIAL_REGIISTERED_REQUEST:
        return {
            ...state,
            authenticated: action.authenticated,
            authenticatedFetched: false,
        }
    case Constant.FETCH_SOCIAL_REGIISTER_SUCCESS:
        return {
            ...state,
            userDetails: action.payload,
            authenticated: true,
            authenticatedFetched: true,
            referer: action.referer,
        }
    case Constant.FETCH_SOCIAL_REGIISTERED_SUCCESS:
        return {
            ...state,
            registeredData: action.payload,
            socialResponce: action.socialResponce,
        }
    case Constant.FETCH_FORGOT_PASSWORD_SUCCESS:
        return { ...state, forgoterror: "", forgotresponse: action.payload }
    case Constant.FETCH_FORGOT_PASSWORD_ERROR:
        return { ...state, forgoterror: action.payload }
    case Constant.ACTIVE_GUEST_SUCCESS:
        return {
            ...state,
            authenticated: true,
            authenticatedFetched: true,
            activateGuestAccountData: action.payload,
        }
    case Constant.ACTIVE_GUEST_ERROR:
        return { ...state, activateGuestAccountError: action.payload }
    case Constant.CAN_ACTIVE_GUEST_SUCCESS:
        return { ...state, canActivateGuestAccountData: action.payload }
    case Constant.GET_PROFILE_REQUEST:
        return {
            ...state,
            canActivateGuestAccountData: { can_activate: false },
            getProfileError: { ERROR: {} },
        }
    case Constant.GET_PROFILE_SUCCESS:
        return {
            ...state,
            getProfileError: { ERROR: {} },
            getProfileData: action.payload,
        }
    case Constant.GET_PROFILE_ERROR:
        return {
            ...state,
            getProfileData: {
                ...state.getProfileData,
                cart_size: action.payload.cart_size,
            },
            getProfileError: action.payload,
        }
    case Constant.LOGOUT_USER_SUCCESS:
        return {
            ...state,
            authenticated: false,
            userDetails: {},
            getProfileData: {},
            registeredData: {},
        }
    case Constant.UPDATE_REQUESTED_PATH:
        return {
            ...state,
            requestedPath: action.payload,
        }
    case Constant.UPDATE_PROFILE_PICTURE_SUCCESS:
        return {
            ...state,
            getProfileData: action.payload,
        }
    case Constant.UPDATE_PROFILE_PICTURE_FAILURE:
        return {
            ...state,
            getProfileError: action.payload,
        }
    default:
        return state
    }
}
