import Constant from "../constants"

const data = {
    isFetching: false,
}

export default function InitData(state = data, action) {
    switch (action.type) {
    case "CLEAR_INIT_DATA":
        return {
            routing: { urlData: null },
            isFetching: false,
        }
    case "CLEAR_URL_DATA":
        return {
            ...state,
            routing: { urlData: null },
            isFetching: false,
        }
    case Constant.INIT_DATA_REQUEST:
        return {
            ...state,
            isFetching: true,
        }
    case Constant.INIT_DATA_CANCELED:
        return {
            ...state,
            isFetching: false,
        }
    case Constant.INIT_DATA:
        return {
            ...state,
            ...action.payload,
            isFetching: false,
        }
    default:
        return state
    }
}
