import {
    _remove,
    fixImageUrl,
    _trim,
    isEmail,
} from "./util"
import config, { t } from "../../../config/index"

const CDN = config.cdn.url
/**
 * img url filter
 *
 * @export
 * @param {any} data
 * @param {any} w
 * @param {any} h
 * @param {number} [q=85]
 * @param {boolean} [d=false]
 * @returns
 */
export function imgFilter(data, w, h, q = 85, d = false) {
    const reg = "^((https|http)?://)"
    const re = new RegExp(reg)
    const dpr = d ? window.devicePixelRatio : 1

    let imgUrl = ""

    if (!re.test(data)) {
        imgUrl = CDN + data
    } else {
        imgUrl = data
    }

    if (w && h) {
        return fixImageUrl(imgUrl, Math.round(w * dpr), Math.round(h * dpr), Number(q))
    }
    return imgUrl
}

export function liveFilter(language) {
    if (language) {
        return language.substring(0, language.length - 5)
    }
    return false
}

// added from old MSite
export function htmlFilter(content) {
    const contentHtml = content && content.replace(/\n/g, "<br />")
    return contentHtml
}

export function ucwords(content) {
    if (content) {
        return content.replace(/\w+/g, a => a.charAt(0).toUpperCase() + a.slice(1).toLowerCase())
    }
    return content
}

export function clearhtml(content) {
    if (!content) {
        return content
    }
    return content.replace(/(<([^>]+)>)/ig, "")
}

export function htmlEntities(str) {
    return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
}

export function htmlEntitiesDecode(str) {
    return String(str)
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#039;/g, "'")
}
/**
* function arrayAddUniqueRecent
* return unique value with recent order
*
* @export
* @param {array} temp
* @param {string} keyword
* @returns {array}
*/
export function arrayAddUniqueRecent(temp, keyword) {
    _remove(temp, n => n === keyword)
    temp.unshift(keyword)
    return temp
}

/**
 * @param  {string} fullname fullname of user
 * @return {string} error message
 * @author Akash M < akashm.bipl@gmail.com >
 */
export function fullNameValidator(fullname) {
    const trimmedVal = _trim(fullname).replace(/\s\s+/g, " ")
    let splitVal = trimmedVal.split(" ")
    if (splitVal.length > 2) {
        const tmpSplitVal = [splitVal.shift()]
        tmpSplitVal.push(splitVal.join(" "))
        splitVal = tmpSplitVal
    }
    if (trimmedVal === "") {
        return `${t("This is a required field")}.`
    }
    if (!(/^[a-zA-Z ]*$/.test(trimmedVal))) {
        return `${t("Only characters allowed")}.`
    }
    if (splitVal.length < 2) {
        return `${t("Please enter first and last name")}.`
    }
    if (splitVal[0].length < 2 && splitVal[1].length < 2) {
        return `${t("First Name and Last must contain a minimum of 2 characters")}.`
    }
    if (splitVal[0].length < 2) {
        return `${t("First Name must contain a minimum of 2 characters")}.`
    }
    if (splitVal[1].length < 2) {
        return `${t("Last Name must contain a minimum of 2 characters")}.`
    }
    return ""
}

/**
 * @param  {string} email email of user
 * @return {string} error message
 * @author Akash M < akashm @gmail.com >
 */
export function emailValidator(email) {
    if (!email) {
        return t("This is a required field")
    } if (!isEmail(email)) {
        return t("VALID_EMAIL_STRING")
    }
    return ""
}

/**
 * @param  {number} number phone number of user
 * @return {string} error message
 * @author Akash M < akashm @gmail.com >
 */
export function phoneNumberValidator(number, isMandatory = false) {
    if (isMandatory && !number) {
        return t("This is a required field")
    } if (number && !(/^([+0-9\s]{8,16})$/.test(number))) {
        return t("Please enter a valid phone number")
    }
    return ""
}
