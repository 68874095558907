/* eslint-disable camelcase */
// import algoliasearch from "algoliasearch"
import Constant from "../constants"
import Algolia from "../../common/tool/algolia"
import { get, post } from "../../common/api"
import getWithAbort from "../../common/api/extraFunction"
import * as Filter from "../../common/tool/filter"
import {
    toSeoUrl,
    urlUnPrettify,
    stripslashes,
    addslashes,
} from "../../common/tool/Formatter"
import initialData from "./InitActions"
import { AddToWishlist as addToWishList, removeToWishlist as removeFromWishList } from "./WishlistActions"

import {
    _isEmpty,
    _find,
    _split,
    _isEqual,
    _remove,
    _clone,
    getSortingFilter,
    _trim,
    offlineRetrive,
    offlineStore,
    _startsWith,
    _endsWith,
    initFilterData,
    _now,
    pad,
} from "../../common/tool/util"
import { t } from "../../../config"


function fetchTours() {
    return {
        type: Constant.FETCH_TOURS,
    }
}

function fetchToursSuccess(payload, isAlgolia = false, advancedSearch = false) {
    if (isAlgolia) {
        payload.products.map(item => {
            const tmpItem = item
            if (tmpItem.TTI.indexOf("Buy 2, Get 3rd & 4th Discounted") > -1) {
                tmpItem.specialOffer = "Buy 2, Get 3rd & 4th Discounted"
            } else if (tmpItem.TTI.indexOf("Buy 2, Get 3rd Discount") > -1 || tmpItem.TTI.indexOf("Buy 2, Get 3rd Discounted") > -1) {
                tmpItem.specialOffer = "Buy 2, Get 3rd Discounted"
            }
        })
    } else {
        payload.products.map(item => {
            const tmpItem = item
            if (tmpItem.TTI && tmpItem.TTI.indexOf("5") > -1) {
                tmpItem.specialOffer = "Buy 2, Get 3rd & 4th Discounted"
            } else if (tmpItem.TTI && tmpItem.TTI.indexOf("4") > -1) {
                tmpItem.specialOffer = "Buy 2, Get 3rd Discounted"
            }
        })
    }
    return {
        type: Constant.FETCH_TOURS_SUCCESS,
        payload,
        advancedSearch,
    }
}

export function openSearch(isSearchOpen) {
    return {
        type: Constant.CHANGED_SEARCH_VISIBILITY,
        isSearchOpen,
    }
}

/**
 * openFilter will open and close filter
 * @param {Boolean} isFilterOpen Open Hide Filter (true = open, false = hide)
 * @param {Boolean} isRestoreFilter for backup/ restore filter data
 *
 * @author Pratik B < pratikb @gmail.com >
 */

export const openFilter = (isFilterOpen, isRestoreFilter = true) => (dispatch, getState) => {
    const payload = {
        isFilterOpen,
        backFilterData: {},
        filter: getState().ToursReducers.filter,
        filterData: getState().ToursReducers.filterData,
        backFilter: {},
    }
    if (isFilterOpen && isRestoreFilter) {
        payload.backFilterData = _clone(getState().ToursReducers.filterData)
        payload.backFilter = _clone(getState().ToursReducers.filter)
    } else if (
        !isFilterOpen
        && isRestoreFilter
        && !_isEmpty(getState().ToursReducers.backFilterData)
    ) {
        payload.filterData = _clone(getState().ToursReducers.backFilterData)
        payload.filter = _clone(getState().ToursReducers.backFilter)
    }
    dispatch({
        type: Constant.CHANGED_FILTER_VISIBILITY,
        ...payload,
    })
}

function fetchToursError(err) {
    return {
        type: Constant.FETCH_TOURS_ERROR,
        err,
    }
}

function fetchToursErrorAlgolia(err) {
    return {
        type: Constant.FETCH_TOURS_ERROR_ALGOLIA,
        err,
    }
}
function nearByDataAdapter(data) {
    const result = []
    result.products = []
    let dataTotal = 0
    result.places = []
    data.map(item => {
        const place = {
            id: item.id,
            latitude: item.latitude,
            longitude: item.longitude,
            name: item.name,
            productInd: [], // need to patch tours index
        }
        item.products.map(product => {
            place.productInd.push(result.products.length)
            result.products.push({
                SP: product.specials_price.toString().replace(",", ""),
                I: product.product_id,
                P: product.product_price.toString().replace(",", ""),
                N: product.product_name,
                G: product.product_image,
                M: product.code,
                CO: product.product_code,
                CN: product.comment_number,
                DS: product.description,
                SC: product.start_city,
                EC: product.end_city,
                D: product.duration,
                DT: product.duration_type,
                IC: product.internal_code,
                ET: product.product_entity_type,
                A: product.provider_id,
                S: product.satisfaction,
                SDS: product.small_description,
                SG: product.smile_image,
                ST: !!product.stock_status,
                T: product.template,
                DST: product.distance,
                U: _endsWith(product.url_path, "html")
                    ? product.url_path : product.url_path + ".html",
                L: product.languages ? product.languages : null,
                ga_category_name: product.ga_category_name || "",
            })
        })
        result.places.push(place)
        dataTotal += item.products.length
    })
    result.total_products = dataTotal
    result.noMore = true
    return result
}

export const searchToursNearBy = () => dispatch => {
    dispatch(fetchTours())
    if (typeof navigator !== "undefined" && "geolocation" in navigator) {
        let params
        navigator.geolocation.getCurrentPosition(position => {
            params = {
                lat: position.coords.latitude,
                lon: position.coords.longitude,
                rlon: 1,
                rlat: 1,
                sort_id: 3,
                _v: 2,
            }
            get("/map/getNearByTours", params).then(data => {
                if (data.length) {
                    dispatch(fetchToursSuccess(nearByDataAdapter(data)))
                } else {
                    dispatch(fetchToursError(data))
                }
            }).catch(err => {
                dispatch(fetchToursError(err))
            })
        })
    }
}

const filterOperatorLanguage = operatorLanguage => {
    const tmpOperatorLanguage = []
    Object.keys(operatorLanguage).map(ind => {
        const tmpName = operatorLanguage[ind].name.split(" ")
        const item = {
            id: tmpName[0],
            name: tmpName[0],
            seo_url: toSeoUrl(tmpName[0]),
        }
        _remove(tmpOperatorLanguage, o => o.seo_url === item.seo_url)
        tmpOperatorLanguage.push(item)
    })
    return tmpOperatorLanguage
}

/**
 * SetFilterAccordingUrl update filter data according url
 * @param {String} url       URL for gettig its filter data accordingly
 * @param {[Function]} [cb=null] Retrive filter data from url and pass in callback
 */
export const setFilterAccordingUrl = (url, cb = null) => dispatch => {
    const urlFilter = offlineRetrive(url, true)
    if (urlFilter !== null) {
        if (typeof cb === "function") {
            // remove page No
            delete urlFilter.page
            cb(urlFilter)
        }
    }
    dispatch({
        type: "SET_FILTER_FROM_LOCALSTORAGE",
        url,
    })
}

/**
 * setFilterOnly() is same as like setFilter()
 * it will update only filter data, will not affect any other elements like page, tours
 */
export const setFilterOnly = filter => (dispatch, getState) => {
    const payload = _clone(getState().ToursReducers.filter)
    payload.advancedSearch = typeof payload.advancedSearch === "undefined" ? {} : payload.advancedSearch
    Object.keys(filter).map(key => {
        payload[key] = _clone(filter[key])
    })
    dispatch({
        type: Constant.FILTER_RESET,
        payload,
        isEmptyTour: false,
    })
}

export const setFilter = (filter, url = "", emptyTour = true, isSSR = false, isFullReset = false) => (dispatch, getState) => {
    let payload
    if (isSSR || isFullReset) {
        payload = _clone(initFilterData())
    } else {
        payload = _clone(getState().ToursReducers.filter)
    }
    payload.advancedSearch = typeof payload.advancedSearch === "undefined"
        ? {} : payload.advancedSearch
    let isEmptyTour = emptyTour
    let isNeedToReset = false
    if (isFullReset) {
        filter.advancedSearch = _isEmpty(filter.advancedSearch) ? {} : filter.advancedSearch
        isEmptyTour = true
        isNeedToReset = true
    }
    Object.keys(filter).map(key => {
        if (!_isEqual(payload[key], filter[key])) {
            isNeedToReset = true
            isEmptyTour = true
            payload[key] = _clone(filter[key])
        }
        if (key === "page") {
            isNeedToReset = true
        }
    })
    let isSameUrl = false
    if (typeof window !== "undefined"
        && typeof window.lastLocation !== "undefined"
        && window.lastLocation.length > 2) {
        const lastLocation = window.lastLocation.length - 1
        if (
            !(
                _startsWith(window.lastLocation[lastLocation - 1], "/popular")
                    || _startsWith(window.lastLocation[lastLocation - 1], "/tours")
            )
        ) {
            isSameUrl = _trim(window.lastLocation[lastLocation - 2], "/") === _trim(window.lastLocation[lastLocation], "/")
            const lastTwoUrls = []
            for (let i = window.lastLocation.length - 1; i >= 0; i -= 1) {
                if ((
                    _startsWith(window.lastLocation[i], "/tours")
                        || _startsWith(window.lastLocation[i], "/popular")
                ) && (
                    lastTwoUrls.length < 2
                )) {
                    lastTwoUrls.push(_trim(window.lastLocation[i], "/"))
                }
            }
            if (
                !isSameUrl && lastTwoUrls.length > 1
                    && lastTwoUrls[0] === lastTwoUrls[1]
            ) {
                isSameUrl = true
            }
        }
    }
    if (url !== "" && !isSSR) {
        // Object.assign(payload, { page: 1 })
        // save filter object according url
        offlineStore(url, {
            ...payload,
        }, true)
    }
    if ((!isNeedToReset || isSameUrl) && !isEmptyTour) {
        return
    }
    dispatch({
        type: Constant.FILTER_RESET,
        payload,
        isEmptyTour,
    })
}

/* eslint-disable no-param-reassign */

/**
 * Set all filter in filter element
 *
 * @param {Boolean} isAlgolia  On / Off flag for algolia
 * @param {Object}  filterData Filter object
 * @param {Object}  InitData   Initial state data
 * @param {Integer}  entityType Entity Type ID
 *
 * @author Pratik B <pratikb@gmail.com>
 */

export const setDataInFilter = (
    isAlgolia,
    filterData,
    InitData,
    entityType,
    filterTotalProducts = 0,
    passFilter,
) => {
    const all = {}
    const mainFilter = typeof filterData.mainFilter !== "undefined" ? filterData.mainFilter : filterData.filters
    const filter = filterData.filters
    const footerFilter = {
        prodcutTypes: {
            ...mainFilter.prodcutTypes,
        },
        startCities: {
            ...mainFilter.startCities,
        },
    }
    Object.keys(filter).map(item => {
        if (filter[item] === null) {
            filter[item] = []
        }
    })
    all.locations = [{ id: "", name: "Any" }]
    if (filter.locations !== "undefined" && filter.locations) {
        Object.keys(filter.locations).map(key => {
            const item = filter.locations[key]
            if (item.id === "") {
                return
            }
            all.locations.push(item)
        })
    }
    all.star = (mainFilter.stars !== "undefined" && mainFilter.stars) ? mainFilter.stars : []
    all.duration = [{ id: "", text: t("No preference") }]
    if (mainFilter.duration !== "undefined" && mainFilter.duration) {
        Object.keys(mainFilter.duration).map(key => {
            const item = mainFilter.duration[key]
            delete (item.count)
            if (parseInt(entityType, 10) !== 1 && parseInt(item.id, 10) > 0) {
                all.duration.push(item)
            } else if (parseInt(entityType, 10) === 1 && parseInt(item.id, 10) > 1) {
                all.duration.push(item)
            }
        })
    }
    all.default_price_t4f = {}
    const defaultPriceT4f = (filter.default_price_t4f !== "undefined" && filter.default_price_t4f)
        ? filter.default_price_t4f : mainFilter.default_price_t4f

    if (defaultPriceT4f !== "undefined" && defaultPriceT4f) {
        all.default_price_t4f.max = parseInt(mainFilter.default_price_t4f.max, 10)
        all.default_price_t4f.min = parseInt(mainFilter.default_price_t4f.min, 10)
        all.default_price_t4f_sel = {}
        if (
            typeof passFilter.advancedSearch !== "undefined"
            && typeof passFilter.advancedSearch.price !== "undefined"
        ) {
            const price = passFilter.advancedSearch.price.split("-")
            all.default_price_t4f_sel.min = parseInt(price[0], 10)
            all.default_price_t4f_sel.max = parseInt(price[1], 10)
        } else {
            all.default_price_t4f_sel.max = parseInt(defaultPriceT4f.max, 10)
            all.default_price_t4f_sel.min = parseInt(defaultPriceT4f.min, 10)
        }
    }
    all.pricing = [{ id: "", text: t("No preference") }]
    if (filter.pricing !== "undefined" && filter.pricing) {
        Object.keys(filter.pricing).map(key => {
            const item = filter.pricing[key]
            delete (item.count)
            all.pricing.push(item)
        })
    }
    all.prodcutTypes = [{
        name: t("Any"), product_type_id: "", total: 0,
    }]
    if (filter.prodcutTypes !== "undefined" && filter.prodcutTypes) {
        const productTypes = mainFilter.prodcutTypes
        const getParentNode = productTypeId => {
            let returnValue = false
            if (
                InitData
                && InitData.ParentProductActivity
            ) {
                InitData.ParentProductActivity.map(v => {
                    if (parseInt(productTypeId, 10) === v.id) {
                        InitData.ParentProductActivity.map(pv => {
                            if (pv.id === v.parent_id) {
                                returnValue = {
                                    name: pv.name,
                                    product_type_id: pv.id,
                                    total: 0,
                                }
                            }
                        })
                    }
                })
            }
            return returnValue
        }
        let isAddedType
        if (productTypes) {
            productTypes.map(item => {
                if (item.product_type_id !== "10001") {
                    const parentNode = getParentNode(item.product_type_id, InitData)
                    isAddedType = false
                    all.prodcutTypes.map(v => {
                        if (v.product_type_id === parentNode.product_type_id) {
                            v.total += item.total
                            isAddedType = true
                        }
                    })
                    if (!isAddedType && parentNode) {
                        parentNode.total = item.total
                        all.prodcutTypes.push(parentNode)
                    }
                }
            })
        }
        all.prodcutTypes.sort((a, b) => {
            if (a.product_type_id === "") { // puh Any element to the top of the array
                return -1
            } if (b.product_type_id === "") { // puh Any element to the top of the array
                return 1
            } if (b.total > a.total) {
                return 1
            }
            return (a.total > b.total) ? -1 : 0
        })
    }

    all.startCities = [{
        name: t("Any"),
        seo_url: "",
        tour_city_id: "",
    }]
    if (filter.startCities !== "undefined" && filter.startCities) {
        Object.keys(filter.startCities).map(ind => {
            const item = filter.startCities[ind]
            all.startCities.push(item)
        })
    }
    all.endCities = [{
        name: t("Any"),
        seo_url: "",
        tour_city_id: "",
    }]
    if (filter.endCities !== "undefined" && filter.endCities) {
        Object.keys(filter.endCities).map(ind => {
            all.endCities.push(filter.endCities[ind])
        })
    }
    all.operationMonths = [{
        name: "Any", seo_url: "", tour_city_id: "",
    }]
    if (filter.operationMonths !== "undefined" && filter.operationMonths) {
        const monthArr = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        Object.keys(filter.operationMonths).map(ind => {
            const item = filter.operationMonths[ind]
            const depPassing = item.name.split("-")
            if (depPassing.length === 2) {
                const date = new Date()
                if (parseInt(depPassing[1], 10) === date.getFullYear()) {
                    if ((date.getMonth() + 1) > monthArr.indexOf(depPassing[0])) {
                        return
                    }
                } else if (parseInt(depPassing[1], 10) < date.getFullYear()) {
                    return
                }
            }
            all.operationMonths.push(item)
        })
        all.operationMonths.sort((a, b) => (a.dataInd - b.dataInd))
    }

    all.operatorLanguage = [{
        name: t("Any"), seo_url: "", id: "",
    }]
    if (filter.operatorLanguage !== "undefined" && filter.operatorLanguage) {
        filter.operatorLanguage = filterOperatorLanguage(filter.operatorLanguage)
        // start sorting lang
        const compare = (a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        }
        filter.operatorLanguage = filter.operatorLanguage.sort(compare)
        Object.keys(filter.operatorLanguage).map(ind => {
            const item = filter.operatorLanguage[ind]
            all.operatorLanguage.push(item)
        })
    }

    all.promotions = [{ id: "", text: t("No preference") }]
    if (filter.promotions !== "undefined" && filter.promotions) {
        Object.keys(filter.promotions).map(ind => {
            const item = filter.promotions[ind]
            if (ind === "buy2get1") {
                item.text = t("Buy 2, Get 3rd Discounted")
            } else if (ind === "buy2get2") {
                item.text = t("Buy 2, Get 3rd & 4th Discounted")
            } else if (ind === "no_preference") {
                delete (filter.promotions[ind])
                return
            } else {
                item.text = t(item.text)
            }
            if (item.amount === 0) {
                return
            }
            all.promotions.push({
                id: ind === "sale-no_preference" ? "" : ind,
                text: item.text,
            })
        })
    }

    all.attractions = [{
        name: t("No preference"), seo_url: "", tour_city_id: "",
    }]
    if (filter.attractions !== "undefined" && filter.attractions) {
        Object.keys(filter.attractions).map(ind => {
            const item = filter.attractions[ind]
            item.name = item.name.trim()
            all.attractions.push(item)
        })
    }
    return ({
        type: Constant.FILTER_DATA_SET_IN,
        filterData: {
            all,
            available: filterData.filters,
            footerFilter,
        },
        filterTotalProducts,
    })
}
const setFiltersFromUrlData = (
    urlData,
    filter,
    InitData,
    restrictSearchAttr,
    placeData = "",
    currency,
    isNearBy = false,
) => {
    const algoliaConfing = InitData.configurations.algolia
    const { ParentProductActivity } = InitData
    const configurationAlgolia = _clone(algoliaConfing)
    const algolia = new Algolia(configurationAlgolia)
    algolia.setFilter("global_status", 1, "=")
    if (algoliaConfing.search_index.search("t4fes") >= 0) {
        algolia.setFilter("t4fes_status", 1, "=")
    } else {
        algolia.setFilter("t4f_status", 1, "=")
    }
    algolia.setSearchIndex(configurationAlgolia.search_index)

    if (placeData !== "") {
        if (placeData.setRestrictSearchableAttributes !== "") {
            algolia.setRestrictSearchableAttributes(placeData.setRestrictSearchableAttributes, "", "")
        }
        if (!_isEmpty(urlData.sort_by)) {
            if (placeData.setFilter !== "") {
                const tmpSetFilter = placeData.setFilter.substring(
                    placeData.setFilter.lastIndexOf(" OR mobile-feature-url"),
                    placeData.setFilter.lastIndexOf("'<score=10>") + ("'<score=10>").length,
                )
                placeData.setFilter = tmpSetFilter.replace(tmpSetFilter, "")
                const featureUrlInd = placeData.setRestrictSearchableAttributes.indexOf("mobile-feature-url")
                if (featureUrlInd > -1) {
                    placeData.setRestrictSearchableAttributes.splice(featureUrlInd, 1)
                }
            }
        }
        if (placeData.pageUrl) {
            algolia.setPageUrl(placeData.pageUrl)
        }
        if (placeData.setAdditionalFiltersForQuery2 !== "") {
            algolia.setAdditionalFiltersForQuery2(placeData.setAdditionalFiltersForQuery2, "", "")
        }
        if (placeData.setAlgoliaIndex) {
            algolia.setSearchIndex(placeData.setAlgoliaIndex)
        }
    }
    let filterCount = 0
    const keywords = filter.keywords || urlData.keywords
    let entityType = filter.product_entity_type
    if (typeof urlData.product_entity_type !== "undefined") {
        entityType = urlData.product_entity_type
    }
    let params = `keywords=${urlUnPrettify(keywords)}&pageSize=${filter.pageSize}&page=${filter.page}${(filter.page === 1) ? "&filters=1" : ""}`
    params += `&currency=${currency}`
    let paramsForFilter = `keywords=${urlUnPrettify(keywords)}&pageSize=1&page=1&filters=1`
    paramsForFilter += `&currency=${currency}`
    let productTypeId = ""
    if (!_isEmpty(urlData.attractions)) {
        const attractions = []
        const attractionsString = []
        Object.keys(urlData.attractions).map(ind => {
            let stringInd = isNaN(ind) ? ind : urlData.attractions[ind]
            stringInd = stringInd.replace(/&#039;/g, "'")
            stringInd = stripslashes(stringInd)
            stringInd = addslashes(stringInd)
            attractions.push(stringInd)
            attractionsString.push("visiting_attractions:\"" + stringInd + "\"")
            attractionsString.push("visiting_cities:\"" + stringInd + "\"")
        })
        if (attractionsString.length > 0) {
            algolia.setFilter("(" + attractionsString.join(" OR ") + ")", "", "")
            params += `&attractions=${encodeURIComponent(attractions.join(","))}`
            filterCount += 1
        }
    }
    if (!_isEmpty(urlData.depart_from_city_id)) {
        params += "&depart_from_city_id=" + urlData.depart_from_city_id
        filterCount += 1
    }
    if (!_isEmpty(urlData.depart_from)) {
        algolia.setFilter("departure_city", "\"" + Filter.htmlEntities(urlUnPrettify(decodeURIComponent(urlData.depart_from))) + "\"", ":")
    }

    if (!_isEmpty(urlData.depart_end)) {
        algolia.setFilter("depart_end_cities", "\"" + Filter.htmlEntities(urlUnPrettify(decodeURIComponent(urlData.depart_end))) + "\"", ":")
    }
    if (!_isEmpty(urlData.depart_end_city_id)) {
        params += "&depart_end_city_id=" + urlData.depart_end_city_id
        filterCount += 1
    }
    if (!_isEmpty(urlData.duration)) {
        params += "&duration=" + urlData.duration
        const duration = []
        _split(urlData.duration, "-").map(value => {
            if (parseInt(value, 10) === 1) {
                duration.push("duration_type:'1'")
                duration.push("duration_type:'2'")
                duration.push("duration_name:'0 " + t("Day") + "'")
                duration.push("duration_name:'1 " + t("Day") + "'")
            } else {
                duration.push(`duration_name:'${value} ${t("Days")}'`)
            }
        })
        if (duration.length > 0) {
            algolia.setFilter("(" + duration.join(" OR ") + ")", "", "")
            filterCount += 1
        }
    }
    if (!_isEmpty(urlData.price)) {
        algolia.setFilter("default_price_t4f", urlData.price, "", currency, InitData)
        params += "&price=" + urlData.price
        filterCount += 1
    }
    if (configurationAlgolia.status && !_isEmpty(urlData.departure)) {
        const monthArr = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const depPassing = urlData.departure.split("-")
        if (depPassing.length === 2) {
            depPassing[0] = ("0" + monthArr.indexOf(depPassing[0].charAt(0).toUpperCase()
                    + depPassing[0].slice(1).toLowerCase())).slice(-2)
            params += "&departure=" + depPassing[1] + "-" + depPassing[0]
            algolia.setFilter("operation_months:'" + depPassing[1] + "-" + depPassing[0] + "'", "", "")
        } else {
            depPassing[1] = ("0" + monthArr.indexOf(depPassing[1].charAt(0).toUpperCase()
                    + depPassing[1].slice(1).toLowerCase())).slice(-2)
            if (
                urlData.prepostdate !== "undefined"
                    && urlData.prepostdate
                    && urlData.prepostdate === "yes"
            ) {
                const newDate = new Date(
                    depPassing[2],
                    parseInt(depPassing[1], 10) - 1,
                    depPassing[0],
                )
                newDate.setDate(newDate.getDate() - 3)
                const datesArr = []
                for (let i = 0; i < 7; i += 1) {
                    const newMonth = newDate.getMonth() + 1
                    params += `&operation_dates.${"" + newDate.getFullYear()}.${newMonth}=${newDate.getDate()}`
                    datesArr[i] = `operation_dates.${newDate.getFullYear()}.${("0" + newMonth).slice(-2)}:'${("0" + newDate.getDate()).slice(-2)}'`
                    newDate.setDate(newDate.getDate() + 1)
                }
                if (datesArr.length > 0) {
                    algolia.setFilter("(" + datesArr.join(" OR ") + ")", "", "")
                }
            } else {
                params += `&operation_dates.${depPassing[2]}.${depPassing[1]}=${depPassing[0]}`
                algolia.setFilter(`operation_dates.${depPassing[2]}.${depPassing[1]}:'${depPassing[0]}'`, "", "")
            }
        }
        filterCount += 1
    }
    if (typeof entityType !== "undefined" && entityType !== "" && !isNaN(parseInt(entityType, 10))) {
        params += "&product_entity_type=" + entityType
        paramsForFilter += "&product_entity_type=" + entityType
        algolia.setFilter("product_entity_type_id", entityType, "=")
    }
    if (!_isEmpty(urlData.operate_languages)) {
        const operatorLanguage = []
        const operatorLanguageLA = []
        urlData.operate_languages.map(item => {
            item = Filter.htmlEntities(item)
            operatorLanguage.push(`product_operator_language:'${item} Audio' OR product_operator_language:'${item} Live'`)
            operatorLanguageLA.push(`${item} Audio OR ${item} Live`)
        })
        if (operatorLanguage.length > 0) {
            params += "&operator_language=" + operatorLanguageLA.join(",")
            algolia.setFilter(`(${operatorLanguage.join(" OR ")})`, "", "")
            filterCount += 1
        }
    }
    if (!_isEmpty(urlData.product_type_id)) {
        let productActivityA = []
        ParentProductActivity.map(item => {
            if (parseInt(urlData.product_type_id, 10) === parseInt(item.parent_id, 10)) {
                productActivityA = Filter.arrayAddUniqueRecent(
                    productActivityA,
                    "activity_type_name:'" + Filter.htmlEntities(item.name) + "'",
                )
            }
        })
        params += "&product_type_id=" + urlData.product_type_id
        if (productActivityA.length > 0) {
            algolia.setFilter(productActivityA.join(" OR "), "", "")
        }
        productTypeId = urlData.product_type_id
        filterCount += 1
    }
    if (!_isEmpty(urlData.sale)) {
        params += "&sale=" + urlData.sale
        if (urlData.sale === "deals" || urlData.sale === "hot-deals") {
            const d = new Date()
            d.setHours(0)
            d.setMinutes(0)
            d.setSeconds(0)
            const currentMS = parseInt((d.getTime() / 1000), 10)
            algolia.setFilter("(deal_dates.start_date <= " + currentMS + " OR deal_dates.start_date = 0) AND ( deal_dates.end_date > " + currentMS + " OR deal_dates.end_date = 0)", "", "")
        } else {
            let str = urlData.sale
            // str = str.replace(/-2-/g, "-to-")
            str = str.replace(/-/g, " ")
            algolia.setFilter("special_offer", Filter.htmlEntities(str))
        }
        filterCount += 1
    }

    if (!_isEmpty(urlData.hotel_location_id)) {
        params += "&location_id=" + urlData.hotel_location_id
        filterCount += 1
    }
    if (!_isEmpty(urlData.star)) {
        params += "&stars=" + urlData.star.join(",")
        filterCount += 1
    }
    if (!_isEmpty(urlData.hotel_name)) {
        params += "&hotel_name=" + urlData.hotel_name
        filterCount += 1
    }
    const d = new Date()
    d.setDate(d.getDate() + 3)
    const checkIn = d.getFullYear() + "-" + pad(d.getMonth() + 1, 2) + "-" + pad(d.getDate(), 2)
    d.setDate(d.getDate() + 1)
    const checkOut = d.getFullYear() + "-" + pad(d.getMonth() + 1, 2) + "-" + pad(d.getDate(), 2)
    params += "&start_date=" + checkIn
    params += "&checkout_date=" + checkOut
    params += "&room_adult_total=" + 2
    params += "&room_child_total=" + 0
    if (!_isEmpty(urlData.latitude) && !_isEmpty(urlData.longitude)) {
        params += "&latitude=" + urlData.latitude
        params += "&longitude=" + urlData.longitude
    }
    if (isNearBy && !_isEmpty(filter.geo)) {
        if (
            _isEmpty(filter.geo.lat) || _isEmpty(filter.geo.lon)
            || _isEmpty(filter.geo.lat2) || _isEmpty(filter.geo.lon2)
        ) {
            params += "&lat=" + filter.geo.latitude
            paramsForFilter += "&lat=" + filter.geo.latitude
            params += "&lon=" + filter.geo.longitude
            paramsForFilter += "&lon=" + filter.geo.longitude
            params += "&expand=1"
            paramsForFilter += "&expand=1"
            algolia.setAroundLatLng(filter.geo.latitude, filter.geo.longitude)
        } else {
            params += "&lat=" + filter.geo.lat
            paramsForFilter += "&lat=" + filter.geo.lat
            params += "&lon=" + filter.geo.lon
            paramsForFilter += "&lon=" + filter.geo.lon
            params += "&lat2=" + filter.geo.lat2
            paramsForFilter += "&lat2=" + filter.geo.lat2
            params += "&lon2=" + filter.geo.lon2
            paramsForFilter += "&lon2=" + filter.geo.lon2
            algolia.setRectangularArea(
                filter.geo.lat,
                filter.geo.lon,
                filter.geo.lat2,
                filter.geo.lon2,
            )
        }
        params += "&sort_id=" + 3
        paramsForFilter += "&sort_id=" + 3
        params += "&_v=" + 3
        paramsForFilter += "&_v=" + 3
    }

    // getSortingFilter
    if (!_isEmpty(urlData.sort_by)) {
        params += "&sort_by=" + urlData.sort_by
        const sortFilter = getSortingFilter(entityType, configurationAlgolia.search_index)
        const algoliaInd = _find(sortFilter, item => item.key === urlData.sort_by)
        if (algoliaInd && !_isEmpty(algoliaInd) && algoliaInd) {
            algolia.setSearchIndex(algoliaInd.algoliaInd)
        } else {
            algolia.setSearchIndex(configurationAlgolia.search_index)
        }
    }
    productTypeId = (productTypeId !== "") ? parseInt(productTypeId, 10) : ""
    algolia.setAttributesToHighlight(parseInt(entityType, 10), productTypeId)
    return ([
        "/product/getCityProducts?" + params,
        "/product/getCityProducts?" + paramsForFilter,
        filterCount,
        algolia,
    ])
}

export const getLisingMetaData = (url, totalResults) => dispatch => {
    dispatch({
        type: Constant.FETCH_META_DETAILS,
        payload: "tours",
    })
    return post("/tourCity/getURLData", {}, { url, total_results: totalResults })
        .then(data => {
            dispatch({
                type: Constant.FETCH_META_DETAILS_SUCCESS,
                payload: data.metaInfo,
                filterData: data.data,
                popularDestinations: data.popularDestinations,
            })
            return data.metaInfo
        })
        .catch(error => {
            dispatch({
                type: Constant.FETCH_META_DETAILS_ERROR,
                payload: error,
            })
            return error
        })
}

export const isPlace = (keywords, cb, url = "") => {
    const ff2 = { keywords } // ff2 is abbreviation of filtersForQuery2
    ff2.setFilter = ""
    ff2.setAdditionalFiltersForQuery2 = ""
    ff2.setRestrictSearchableAttributes = []
    let af = ""
    let EName = ""
    let f = ""
    // if (keywords === "tour_tag") {
    //     return cb(ff2)
    // }
    let lastUrl = url
    if (!lastUrl && typeof window !== "undefined" && window.lastLocation) {
        lastUrl = window.lastLocation[window.lastLocation.length - 1]
    }
    lastUrl = _trim(lastUrl, "/")
    ff2.pageUrl = lastUrl

    // build the context for isPlace
    let context = "tours"
    if (/popular\//.test(url)) {
        context = "popular"
    }
    const params = {
        q: toSeoUrl(decodeURIComponent(keywords)),
        full_site: 1,
        ctx: context,
    }

    if (typeof document !== "undefined") {
        const cookie = offlineRetrive("isPlace_" + keywords + context, true)
        if (!_isEmpty(cookie)) {
            const featureTourFilter = cookie.setFilter ? cookie.setFilter : ""
            if (
                featureTourFilter.lastIndexOf("url:'") !== -1
                && featureTourFilter.lastIndexOf("'<sco") !== -1
            ) {
                const oldSetUrl = featureTourFilter.substring(
                    featureTourFilter.lastIndexOf("url:'") + 5,
                    featureTourFilter.lastIndexOf("'<sco"),
                )
                if (oldSetUrl) {
                    cookie.setFilter = featureTourFilter.replace(oldSetUrl, lastUrl)
                    cookie.pageUrl = lastUrl
                }
            } else if (
                cookie.type
                && (
                    cookie.type === "city"
                    || cookie.type === "country"
                    || cookie.type === "zone"
                )
            ) {
                cookie.setFilter += " OR mobile-feature-url:'" + lastUrl + "'<score=10>"
            }
            return cb(cookie)
        }
    }
    return get("/common/isPlace", params).then(response => {
        if (response.ok) {
            if (response.data.algolia_filter !== "") {
                af = response.data.algolia_filter
                ff2.setRestrictSearchableAttributes = _clone(af)
                if (!Array.isArray(ff2.setRestrictSearchableAttributes)) {
                    ff2.setRestrictSearchableAttributes = [ff2.setRestrictSearchableAttributes]
                }
                let featureTourFilter = ""
                if (response.data.type
                    && (
                        response.data.type === "city"
                        || response.data.type === "country"
                        || response.data.type === "zone"
                    )
                ) {
                    ff2.setRestrictSearchableAttributes.push("mobile-feature-url")
                    featureTourFilter = " OR mobile-feature-url:'" + lastUrl + "'<score=10>"
                    ff2.type = response.data.type
                    ff2.featureTourFilter = response.data.featureTourFilter
                }
                EName = Filter.htmlEntities(response.data.name)
                ff2.keywords = EName === "" ? Filter.htmlEntities(keywords) : EName
                if (typeof af !== "string") {
                    f = af.join(":'" + EName + "' OR ")
                    f += ":'" + EName + "'"
                    ff2.setFilter = "(" + f + featureTourFilter + ")"
                    ff2.setAdditionalFiltersForQuery2 = "(" + f + ")"
                } else {
                    ff2.setFilter = af + ": '" + EName + "'" + featureTourFilter
                    ff2.setAdditionalFiltersForQuery2 = af + ": '" + EName + "'"
                }
            }
            if (response.data.algolia_index) {
                ff2.setAlgoliaIndex = response.data.algolia_index
            }
        }
        if (typeof document !== "undefined") {
            offlineStore("isPlace_" + keywords + context, ff2, true)
        }
        return cb(ff2)
    })
}

/**
 * Search tours near by location (lat, long) implement on homepage
 *
 * @param {Float} lat  latitude
 * @param {Float}  long longitude
 *
 * @author Pratik B <pratikb@gmail.com>
 */
export const nearByMeTourQuickSearch = (lat, long) => (dispatch, getState) => {
    const storedQuickSearch = getState().ToursReducers.nearByMeQuickSearch
    const geo = {
        lat: Number.parseFloat(lat).toFixed(6),
        long: Number.parseFloat(long).toFixed(6),
    }
    if (
        storedQuickSearch.geo.lat === geo.lat
        && storedQuickSearch.geo.long === geo.long
        && storedQuickSearch.products.length > 0
    ) {
        return
    }
    const { InitData } = getState()
    const currency = getState().CommonReducers.selectedCurrency.code
    const algoliaConfing = InitData.configurations.algolia
    const configurationAlgolia = _clone(algoliaConfing)
    const algolia = new Algolia(configurationAlgolia)
    algolia.setFilter("global_status", 1, "=")
    if (algoliaConfing.search_index.search("t4fes") >= 0) {
        algolia.setFilter("t4fes_status", 1, "=")
    } else {
        algolia.setFilter("t4f_status", 1, "=")
    }
    algolia.setSearchIndex(configurationAlgolia.search_index)
    algolia.setAroundLatLng(geo.lat, geo.long)
    algolia.setAttributesToHighlight("", "")
    algolia.search(
        "",
        0,
        8,
        data => {
            dispatch({
                type: Constant.FETCH_NEAR_BY_ME_TOUR_QUICK_SEARCH_SUCCESS,
                payload: {
                    geo,
                    products: data.products,
                },
            })
        },
        error => {
            dispatch({
                type: Constant.FETCH_NEAR_BY_ME_TOUR_QUICK_SEARCH_SUCCESS,
                payload: {
                    geo,
                    products: [],
                },
            })
            console.log("Home page- Algolia Error_____", error)
        },
        InitData,
        currency,
        true,
        true,
    )
}
/**
 * getAvailableFilter for getting available filter acording to selected filter
 * @param {Object} filter User selected filter
 * @param {Object} restrictSearchAttr restrict search attributes
 *
 * @author Pratik B < pratikb @gmail.com >
 */
export const getAvailableFilter = (filter, restrictSearchAttr) => (dispatch, getState) => {
    const state = getState()
    const passFilter = _clone(state.ToursReducers.filter)
    passFilter.advancedSearch = filter
    passFilter.pageSize = 1
    passFilter.page = 1
    let isNeedFilter = true
    let mainFilter = {}
    const currency = state.CommonReducers.selectedCurrency.code
    const isAlgolia = state.InitData.configurations.algolia.status
    const urlData = passFilter.advancedSearch
    const isNearBy = typeof document !== "undefined" && passFilter.keywords.toLowerCase() === "near me"
    dispatch({
        type: Constant.SET_FILTER_ADVANCED,
        payload: urlData,
    })

    const getAFilters = placeData => {
        if (typeof document !== "undefined") {
            const sessionFilter = offlineRetrive("filter_"
                + toSeoUrl(placeData.keywords)
                + "_" + passFilter.product_entity_type + isAlgolia, true)
            if (!_isEmpty(sessionFilter)) {
                isNeedFilter = false
                mainFilter = sessionFilter
            }
        }
        passFilter.product_entity_type = urlData.product_entity_type
            || passFilter.product_entity_type
        const getToursFromT4fApi = coustomApiURLs => {
            const callCoustomApiURLs = [getWithAbort(coustomApiURLs[0])]
            if (isNeedFilter) {
                callCoustomApiURLs.push(get(coustomApiURLs[1], {}))
            }
            return Promise.all(callCoustomApiURLs).then(
                data => {
                    if (isNeedFilter) {
                        mainFilter = {
                            ...data[1].filters,
                            entity_stats: data[1].entity_stats,
                        }
                        offlineStore("filter_"
                        + toSeoUrl(placeData.keywords)
                        + "_" + passFilter.product_entity_type + isAlgolia, {
                            ...mainFilter,
                        }, true)
                    }
                    dispatch(setDataInFilter(
                        false,
                        {
                            filters: data[0].filters,
                            mainFilter,
                        },
                        getState().InitData,
                        passFilter.product_entity_type,
                        data[0].total_products,
                        passFilter,
                    ))
                },
                err => dispatch(fetchToursError(err)),
            )
        }

        const getToursFromAlgolia = coustomApiURLs => new Promise(resolve => {
            let pKeywords = urlUnPrettify(
                Filter.htmlEntitiesDecode(decodeURIComponent(placeData.keywords)),
            )
            if (isNearBy) {
                pKeywords = ""
            }
            coustomApiURLs[3].search(
                pKeywords,
                passFilter.page - 1,
                passFilter.pageSize,
                data => {
                    if (isNeedFilter) {
                        mainFilter = data.mainFilter
                        offlineStore("filter_"
                        + toSeoUrl(pKeywords)
                        + "_" + passFilter.product_entity_type + isAlgolia, {
                            ...mainFilter,
                        }, true)
                    }
                    dispatch(setDataInFilter(
                        isAlgolia,
                        data,
                        getState().InitData,
                        passFilter.product_entity_type,
                        data.total_products,
                        passFilter,
                    ))
                    resolve(data)
                },
                error => {
                    dispatch(fetchToursErrorAlgolia(error))
                    if (restrictSearchAttr === "") {
                        getToursFromT4fApi(coustomApiURLs)
                    } else {
                        dispatch(fetchToursError({
                            err: "api will not call because of :-" + restrictSearchAttr,
                        }))
                    }
                    resolve()
                },
                getState().InitData,
                currency,
                isNearBy,
                false,
                passFilter.typoTolerance,
            )
        })
        const coustomApiURLs = setFiltersFromUrlData(
            {
                ...urlData,
                keywords: "",
            },
            passFilter,
            getState().InitData,
            restrictSearchAttr,
            placeData,
            currency,
            isNearBy,
        )
        if (!isAlgolia || passFilter.product_entity_type + "" === "3") {
            return getToursFromT4fApi(coustomApiURLs)
        }
        return getToursFromAlgolia(coustomApiURLs)
    }

    if (isNearBy) {
        getAFilters({
            keywords: passFilter.keywords || filter.keywords,
            setAdditionalFiltersForQuery2: "",
            setFilter: "",
            setRestrictSearchableAttributes: "",
        })
    } else {
        isPlace(
            passFilter.keywords || filter.keywords,
            getAFilters,
        )
    }
}
/* eslint-enable no-param-reassign */
/**
 * Search tours according selectedFilters
 * @param  {[String]} restrictSearchAttr restrictSearchAttr string for algolia
 *
 * @author Pratik B <pratikb@gmail.com>
 */
// eslint-disable-next-line no-unused-vars
export const searchToursByKeyword = (
    restrictSearchAttr,
    url,
    // eslint-disable-next-line no-unused-vars
    loadMore = false,
    ctx,
) => (dispatch, getState) => {
    dispatch({
        type: Constant.INIT_DATA_REQUEST,
    })
    dispatch(fetchTours())
    const currency = getState().CommonReducers.selectedCurrency.code
    const passFilter = _clone(getState().ToursReducers.filter)
    const isNearBy = typeof document !== "undefined" && passFilter.keywords.toLowerCase() === "near me"
    const isSSR = typeof window === "undefined"
    let urlData = passFilter.advancedSearch

    const upFilterWithNewData = coustomApiURLs => {
        /**
             * To fix issue: https://github.com/Tours4Fun/mobile_web_t4f/issues/268
             * when home page reloaded or ssr load it's setting default page = 2
             * prevent that changes added this flag
             * @author Pratik B <pratikb.bipl@gmail.com>
             */
        if (passFilter.page > 1 && getState().ToursReducers.totalProducts === 0) {
            passFilter.page = 1
        } else {
            passFilter.page += 1
        }
        /* @end */
        const payload = passFilter
        const { keywords } = passFilter
        payload.count = coustomApiURLs[2]
        /**
             * @author tony <tonys9204@gmail.com>
             * @date 2018/01/18
             * @start
             * update filters to match url on server side,
             * @todo should use global variable carefully!!!
             */
        if (isSSR) {
            Object.assign(payload, urlData)
            if (
                payload.product_entity_type_name
                    && !urlData.product_entity_type_name
            ) {
                payload.product_entity_type = ""
                delete payload.product_entity_type_name
            }
        }
        // re-formating filter for understanding filter component
        const tmpFiltr = initFilterData()
        const advancedSearch = {}
        Object.keys(payload).map(item => {
            if (typeof tmpFiltr[item] === "undefined") {
                advancedSearch[item] = payload[item]
            } else {
                tmpFiltr[item] = payload[item]
            }
        })
        Object.keys(advancedSearch).map(item => {
            tmpFiltr.advancedSearch[item] = advancedSearch[item]
        })
        /**
         * don't change keyword from Api response (tourCity/getURLData)
         * because tourCity/getURLData will auto correct user search keyword
         * like if we open "tours/yellowstone" it assign keyword as "Yellowstone National Park"
         * and due to that keyword conflicts it's calling tourCity/getURLData multiple time.
         * @author Pratik B <pratikb.bipl@gmail.com>
         */
        tmpFiltr.keywords = keywords
        /* @end */
        dispatch({
            type: Constant.FILTER_RESET,
            payload: tmpFiltr,
            isEmptyTour: false,
        })
    }

    const getToursFromT4fApi = (
        coustomApiURLs,
        placeData,
        isNeedFilter,
        mainFilter,
        isAlgolia,
    ) => {
        const callCoustomApiURLs = [get(coustomApiURLs[0], {})]
        if (isNeedFilter) {
            callCoustomApiURLs.push(get(coustomApiURLs[1], {}, ctx))
        }
        return Promise.all(callCoustomApiURLs).then(
            data => {
                let mf = mainFilter
                if (isNeedFilter) {
                    mf = data[1].filters
                    mf.entity_stats = data[1].entity_stats
                    offlineStore("filter_"
                            + toSeoUrl(placeData.keywords)
                            + "_" + passFilter.product_entity_type + isAlgolia, {
                        ...mf,
                    }, true)
                }
                data[0].mainFilter = mf
                if (passFilter.page === 1) {
                    dispatch(setDataInFilter(
                        false,
                        {
                            filters: data[0].filters,
                            mf,
                        },
                        getState().InitData,
                        passFilter.product_entity_type,
                        data[0].total_products,
                        passFilter,
                    ))
                }
                dispatch(fetchToursSuccess(data[0], false, urlData))
                upFilterWithNewData(coustomApiURLs, urlData)
                return data[0]
            },
            err => dispatch(fetchToursError(err)),
        )
    }

    const processAlgoliaData = (
        data,
        placeData,
        coustomApiURLs,
        isNeedFilter,
        resolve,
        typoTolerance = false,
    ) => {
        if (isNeedFilter) {
            offlineStore("filter_"
                    + toSeoUrl(placeData.keywords)
                    + "_" + passFilter.product_entity_type + true, {
                ...data.mainFilter,
            }, true)
        }
        if (passFilter.page === 1) {
            dispatch(setDataInFilter(
                1,
                data,
                getState().InitData,
                passFilter.product_entity_type,
                data.total_products,
                passFilter,
            ))
        }
        // set typotolerance for subsequant queries in pagination and filters
        passFilter.typoTolerance = typoTolerance
        dispatch(fetchToursSuccess(data, true, urlData))
        upFilterWithNewData(coustomApiURLs, urlData)
        resolve(data)
    }

    const getToursFromAlgolia = (
        coustomApiURLs,
        placeData,
        isNeedFilter,
        mainFilter,
        isAlgolia,
    ) => new Promise((resolve, reject) => {
        let tmpKeyword = isNearBy ? "" : placeData.keywords
        tmpKeyword = Filter.htmlEntitiesDecode(decodeURIComponent(tmpKeyword))
        return coustomApiURLs[3].search(
            tmpKeyword,
            passFilter.page - 1,
            isNearBy ? 8 : 15,
            data => {
                if (ctx && ctx.perf && typeof ctx.perf.lap === "function") {
                    ctx.perf.lap("Algolia data ready")
                }

                // if no results returned, fallback with typo tolerance
                if (
                    passFilter.typoTolerance === false // avoid duplicate
                    && data.total_products === 0
                    && typeof placeData.type === "undefined"
                ) {
                    // do another search
                    coustomApiURLs[3].search(
                        urlUnPrettify(tmpKeyword),
                        passFilter.page - 1,
                        isNearBy ? 8 : 15,
                        data1 => {
                            coustomApiURLs.typoTolerance = true
                            processAlgoliaData(
                                data1,
                                placeData,
                                coustomApiURLs,
                                isNeedFilter,
                                resolve,
                                true,
                            )
                        },
                        error => {
                            dispatch(fetchToursErrorAlgolia(error))
                            if (restrictSearchAttr === "") {
                                resolve(getToursFromT4fApi(
                                    coustomApiURLs,
                                    placeData,
                                    isNeedFilter,
                                    mainFilter,
                                    isAlgolia,
                                ))
                            } else {
                                dispatch(fetchToursError({ err: "api will not call because of :-" + restrictSearchAttr }))
                                reject(new Error(`api will not call because of :-${restrictSearchAttr}`))
                            }
                        },
                        getState().InitData,
                        currency,
                        isNearBy,
                        false,
                        true, // typo tolerance
                    )
                } else {
                    processAlgoliaData(
                        data,
                        placeData,
                        coustomApiURLs,
                        isNeedFilter,
                        resolve,
                        passFilter.typoTolerance,
                    )
                }

                // if (isNeedFilter) {
                //     offlineStore("filter_"
                //             + toSeoUrl(placeData.keywords)
                //             + "_" + passFilter.product_entity_type + isAlgolia, {
                //         ...data.mainFilter,
                //     }, true)
                // }
                // if (passFilter.page === 1) {
                //     dispatch(setDataInFilter(
                //         isAlgolia,
                //         data,
                //         getState().InitData,
                //         passFilter.product_entity_type,
                //         data.total_products,
                //         passFilter,
                //     ))
                // }
                // dispatch(fetchToursSuccess(data, isAlgolia, urlData))
                // upFilterWithNewData(coustomApiURLs, urlData)
                // resolve(data)
            },
            error => {
                dispatch(fetchToursErrorAlgolia(error))
                if (restrictSearchAttr === "") {
                    resolve(getToursFromT4fApi(
                        coustomApiURLs,
                        placeData,
                        isNeedFilter,
                        mainFilter,
                        isAlgolia,
                    ))
                } else {
                    dispatch(fetchToursError({ err: "api will not call because of :-" + restrictSearchAttr }))
                    reject(new Error(`api will not call because of :-${restrictSearchAttr}`))
                }
            },
            getState().InitData,
            currency,
            isNearBy,
            false,
            passFilter.typoTolerance,
        )
    })

    const beginSearch = placeData => {
        const isAlgolia = getState().InitData.configurations.algolia.status
        let isNeedFilter = true
        let mainFilter = {}
        if (typeof document !== "undefined") {
            const sessionFilter = offlineRetrive(
                "filter_"
                    + toSeoUrl(placeData.keywords)
                    + "_" + passFilter.product_entity_type + isAlgolia,
                true,
            )
            if (!_isEmpty(sessionFilter)) {
                isNeedFilter = false
                mainFilter = sessionFilter
            }
        }
        const coustomApiURLs = setFiltersFromUrlData(
            urlData,
            getState().ToursReducers.filter,
            getState().InitData,
            restrictSearchAttr,
            placeData,
            currency,
            isNearBy,
        )
        passFilter.product_entity_type = urlData.product_entity_type
                || passFilter.product_entity_type

        if (!isAlgolia || passFilter.product_entity_type + "" === "3") {
            return getToursFromT4fApi(
                coustomApiURLs,
                placeData,
                isNeedFilter,
                mainFilter,
                isAlgolia,
            )
        }
        return getToursFromAlgolia(
            coustomApiURLs,
            placeData,
            isNeedFilter,
            mainFilter,
            isAlgolia,
        )
    }

    return dispatch(initialData(url)).then(() => {
        const executeSearch = ud => {
            urlData = ud // set urlData outer scope variable
            if (
                passFilter.keywords
                && isNearBy
            ) {
                return beginSearch({
                    keywords: passFilter.keywords,
                    setAdditionalFiltersForQuery2: "",
                    setFilter: "",
                    setRestrictSearchableAttributes: "",
                })
            }
            return isPlace(
                passFilter.keywords || ud.keywords,
                beginSearch,
                url,
            )
        }
        /**
         * @author tony <tonys9204@gmail.com>
         * @date 2018/01/10
         * @start
         * fetch url data on server side
         */
        if (isSSR) {
            // @FIXME why doing /getURLData before isPlace in ssr?
            return post(
                "/tourCity/getURLData",
                {},
                { url },
            )
                .then(res => {
                    let filterUrlData = JSON.stringify(res.data)
                    filterUrlData = Filter.htmlEntitiesDecode(filterUrlData)
                    return executeSearch(JSON.parse(filterUrlData))
                })
                .then(data => dispatch(getLisingMetaData(url, data.total_products)))
                .catch(err => dispatch(fetchToursError(err)))
        }
        /* @end */
        return executeSearch(urlData)
    })
}


export const offServerFlag = () => dispatch => dispatch({ type: Constant.OFF_SREVER_FLAG_TOUR })

/**
 * Toggle product from wishlit
 *
 * @param {int} productId Product ID
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const toggleWishlist = productId => dispatch => {
    let toursWishlist = offlineRetrive("toursWishlist")
    toursWishlist = toursWishlist !== null ? toursWishlist : []
    let isDeleted = false

    // remove
    _remove(toursWishlist, item => {
        if (item.id === productId) {
            isDeleted = true
            return true
        }
        return false
    })


    if (!isDeleted) {
        dispatch({
            type: Constant.TOURS_LIST_PRODUCT_WISHLIST_CHANGE,
            payload: {
                productId,
                isWishlisted: true,
            },
        })
        addToWishList(
            productId,
            dispatch,
            () => {
                toursWishlist.push({ id: productId, time: _now() })
                offlineStore("toursWishlist", toursWishlist)
                // dispatch for product page update
                dispatch({
                    type: Constant.IS_WISHLISTED,
                    payload: {
                        productId,
                        isWishlisted: true,
                    },
                })
            },
            () => {
                // rollback on error
                dispatch({
                    type: Constant.TOURS_LIST_PRODUCT_WISHLIST_CHANGE,
                    payload: {
                        productId,
                        isWishlisted: false,
                    },
                })
            },
        )
    } else {
        dispatch({
            type: Constant.TOURS_LIST_PRODUCT_WISHLIST_CHANGE,
            payload: {
                productId,
                isWishlisted: false,
            },
        })
        removeFromWishList(
            productId,
            dispatch,
            () => {
                offlineStore("toursWishlist", toursWishlist)
                // dispatch for product page update
                dispatch({
                    type: Constant.IS_WISHLISTED,
                    payload: {
                        productId,
                        isWishlisted: false,
                    },
                })
            },
            () => {
                // rollback on error
                dispatch({
                    type: Constant.TOURS_LIST_PRODUCT_WISHLIST_CHANGE,
                    payload: {
                        productId,
                        isWishlisted: true,
                    },
                })
            },
        )
    }
}

const ToursAPI = {
    searchToursNearBy,
    searchToursByKeyword,
    setFilter,
    openSearch,
    setDataInFilter,
    openFilter,
    offServerFlag,
    getLisingMetaData,
    isPlace,
}

export default ToursAPI
