import entries from "object.entries"
import React from "react"
import { hydrate } from "react-dom"
import { Provider } from "react-redux"
import { createBrowserHistory } from "history"
import { AppContainer } from "react-hot-loader"
import { loadableReady } from "@loadable/component"
import configureStore from "./redux/store"
import initHistory from "./router/initHistory"
import App from "./router"

import "./assets/less/style.less"

// object.entries shim for compatibility
if (!Object.entries) {
    entries.shim()
}

const browserHistory = createBrowserHistory()

initHistory(browserHistory)

const store = configureStore(window.REDUX_STATE)
if (process.env.NODE_ENV === "production") {
    loadableReady(() => hydrate(
        <Provider store={store}>
            <App browserHistory={browserHistory} />
        </Provider>,
        document.getElementById("APP"),
    ))
} else {
    const render = Component => loadableReady(() => hydrate(
        <AppContainer>
            <Provider store={store}>
                <div><Component browserHistory={browserHistory} /></div>
            </Provider>
        </AppContainer>,
        document.getElementById("APP"),
    ))
    render(App)
    if (module.hot) {
        module.hot.accept("./router", () => {
            const NextApp = require("./router").default
            render(NextApp)
        })
    }
}
