import Constant from "../constants"
import { get, post } from "../../common/api"
import { _remove, _now, offlineStore, offlineRetrive } from "../../common/tool/util"
// import { get } from "../../common/api"

export const getWishlist = () => dispatch => {
    dispatch({
        type: Constant.GET_WISHLIST,
    })
    let toursWishlist = []
    toursWishlist = offlineRetrive("toursWishlist")
    toursWishlist = toursWishlist !== null ? toursWishlist : []
    get("/myAccount/getWishList")
        .then(data => {
            if (data.ERROR) {
                return dispatch({
                    type: Constant.GET_WISHLIST_ERROR,
                    payload: data,
                })
            }
            toursWishlist = []
            data.map(item => {
                if (item.TTI && item.TTI.indexOf("5") > -1) {
                    item.specialOffer = "Buy 2, Get 3rd & 4th Discounted"
                } else if (item.TTI && item.TTI.indexOf("4") > -1) {
                    item.specialOffer = "Buy 2, Get 3rd Discounted"
                }

                toursWishlist.push({ id: +item.I, time: _now() })
            })
            // console.log("next data for wishlist", toursWishlist)
            offlineStore("toursWishlist", toursWishlist)
            return dispatch({
                type: Constant.GET_WISHLIST_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.log(err)
            return dispatch({
                type: Constant.GET_WISHLIST_ERROR,
                payload: { ERROR: err },
            })
        })
}
export const AddToWishlist = (productId, dispatch, onSuccessCallback, onErrorCallback) => {
    dispatch({
        type: Constant.ADD_WISHLIST,
    })
    post("/myAccount/addToWishList", {}, { product_ids: productId })
        .then(data => {
            // console.log("wishlist response", data)
            if (data.ERROR) {
                if (typeof onErrorCallback === "function") {
                    onErrorCallback()
                }
                return dispatch({
                    type: Constant.ADD_WISHLIST_ERROR,
                    payload: data,
                })
            }
            if (typeof onSuccessCallback === "function") {
                onSuccessCallback()
            }
            return dispatch({
                type: Constant.ADD_WISHLIST_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.log(err)
            if (typeof onErrorCallback === "function") {
                onErrorCallback()
            }
            return dispatch({
                type: Constant.ADD_WISHLIST_ERROR,
                payload: { ERROR: err },
            })
        })
}
export const removeToWishlist = (productId, dispatch, onSuccessCallback, onErrorCallback) => {
    dispatch({
        type: Constant.REMOVE_WISHLIST,
    })
    post("/myAccount/removeFromWishList", {}, { product_id: productId })
        .then(data => {
            // console.log("wishlist response", data)
            if (data.ERROR) {
                if (typeof onErrorCallback === "function") {
                    onErrorCallback()
                }
                return dispatch({
                    type: Constant.REMOVE_WISHLIST_ERROR,
                    payload: data,
                })
            }
            if (typeof onSuccessCallback === "function") {
                onSuccessCallback()
            }
            return dispatch({
                type: Constant.REMOVE_WISHLIST_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.log(err)
            if (typeof onErrorCallback === "function") {
                onErrorCallback()
            }
            return dispatch({
                type: Constant.REMOVE_WISHLIST_ERROR,
                payload: { ERROR: err },
            })
        })
}
export const updateWishlist = (element, index) =>
    (dispatch, getState) => {
        const tours = getState().ToursReducers.tours
        const tourList = getState().WishlistReducer.getWishlistData
        const I = tourList[index].I
        let toursWishlist = offlineRetrive("toursWishlist")
        toursWishlist = toursWishlist !== null ? toursWishlist : []
        let isDeleted = false
        const tmpE = element
        _remove(toursWishlist, item => {
            if (+item.id === +tourList[index].I) {
                isDeleted = true
                return true
            }
            return false
        })
        if (!isDeleted) {
            toursWishlist.push({ id: +tourList[index].I, time: _now() })
            tourList[index].isWishlisted = true
            tmpE.className += " active"
            if (tours !== []) {
                tours.map((item, i) => {
                    if (+item.I === +I) {
                        tours[i].isWishlisted = true
                    }
                })
            }
            // console.log("wishlist true")
            AddToWishlist(tourList[index].I, dispatch)
        } else {
            if (tours !== []) {
                tours.map((item, i) => {
                    if (+item.I === +I) {
                        tours[i].isWishlisted = false
                    }
                })
            }
            // console.log("wishlist false")
            removeToWishlist(tourList[index].I, dispatch, () => {
                tourList.splice(index, 1)
            })
        }
        offlineStore("toursWishlist", toursWishlist)
    }
export const updateRecentWishlist = index => (dispatch, getState) => {
    const RecentwishList = getState().RecentlyReducer.RecentlyData
    let toursWishlist = offlineRetrive("toursWishlist")
    toursWishlist = toursWishlist !== null ? toursWishlist : []
    let isDeleted = false
    _remove(toursWishlist, item => {
        if (item.id === "" + RecentwishList[index].details.I) {
            isDeleted = true
            RecentwishList[index].details.isWishlisted = false
            return true
        }
        return false
    })
    if (!isDeleted) {
        toursWishlist.push({ id: "" + RecentwishList[index].details.I, time: _now() })
        RecentwishList[index].details.isWishlisted = true
        // console.log("wishlist true")
        AddToWishlist(RecentwishList[index].details.I, dispatch)
    } else {
        // console.log("wishlist false")
        removeToWishlist(RecentwishList[index].details.I, dispatch)
    }
    offlineStore("toursWishlist", toursWishlist)
}
export const updateCartWishlist = index => (dispatch, getState) => {
    const cartList = getState().CartReducer.CartData
    const tourList = getState().ToursReducers.tours
    let toursWishlist = offlineRetrive("toursWishlist")
    toursWishlist = toursWishlist !== null ? toursWishlist : []
    let isDeleted = false
    _remove(toursWishlist, item => {
        if (+item.id === +cartList[index].product_id) {
            isDeleted = true
            cartList[index].isWishlisted = false
            return true
        }
        return false
    })
    if (!isDeleted) {
        tourList.map((item, i) => {
            if (+item.I === +cartList[index].product_id) {
                tourList[i].isWishlisted = true
            }
        })
        toursWishlist.push({ id: +cartList[index].product_id, time: _now() })
        cartList[index].isWishlisted = true
        // console.log("wishlist true")
        AddToWishlist(cartList[index].product_id, dispatch)
    } else {
        tourList.map((item, i) => {
            if (+item.I === +cartList[index].product_id) {
                tourList[i].isWishlisted = false
            }
        })
        // console.log("wishlist false")
        removeToWishlist(+cartList[index].product_id, dispatch)
    }
    offlineStore("toursWishlist", toursWishlist)
}
export const InitWishlist = () => dispatch => {
    let Wishlist = offlineRetrive("toursWishlist")
    Wishlist = Wishlist !== null ? Wishlist : []
    let productIds = ""
    Wishlist.map((item, index) => {
        if (Wishlist.length === index + 1) {
            productIds += item.id
        } else {
            productIds += item.id + ","
        }
    })
    if (productIds !== "") {
        dispatch({
            type: Constant.INIT_WISHLIST,
        })
        post("/myAccount/addToWishList", {}, { product_ids: productIds })
            .then(data => {
                if (data.ERROR) {
                    return dispatch({
                        type: Constant.INIT_WISHLIST_ERROR,
                        payload: data,
                    })
                }
                return dispatch({
                    type: Constant.INIT_WISHLIST_SUCCESS,
                    payload: data,
                })
            })
            .catch(err => {
                console.log(err)
                return dispatch({
                    type: Constant.INIT_WISHLIST_ERROR,
                    payload: { ERROR: err },
                })
            })
    }
}

const Wishlist = {
    getWishlist,
    AddToWishlist,
    removeToWishlist,
    updateRecentWishlist,
    updateWishlist,
    updateCartWishlist,
    InitWishlist,
}
export default Wishlist
