import Constant from "../constants"
import { get, post } from "../../common/api"
import { getAuthToken } from "../../common/tool/auth"
import { clearCart } from "./CartAction"
import { InitWishlist } from "./WishlistActions"
import {
    _now, _isEmpty, _isFunction,
    offlineStore, offlineRetrive, removeFromStorage,
} from "../../common/tool/util"
import config, { t } from "../../../config"

/**
 * login hook for OneSignal
 *
 * @param {object} data Data object to publish to dataLayer
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
function oneSignalLoginHook(userData) {
    // Not in browser?
    if (typeof window === "undefined") {
        return
    }

    if (!window.dataLayer) {
        window.dataLayer = []
    }
    window.dataLayer.push({
        event: "customerLoggedIn",
        customerFullName: userData.fullName,
    })


    if (!window.OneSignal) {
        return
    }
    window.OneSignal.push(() => {
        window.OneSignal.getUserId().then(userId => {
            if (userId) {
                post("/common/storeOneSignalPlayerId", { player_id: userId })
            }
        })
    })
}

/**
 * Check User can activate Guest Account
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const canActivateGuestAccount = () => dispatch => {
    dispatch({
        type: Constant.CAN_ACTIVE_GUEST_REQUEST,
    })
    get("/guest/canActivateGuestAccount")
        .then(data => dispatch({
            type: Constant.CAN_ACTIVE_GUEST_SUCCESS,
            payload: data,
        }))
        .catch(err => {
            console.error(err)
        })
}

/**
 * Get User info
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const getProfile = (successCb = "", failureCb = "") => dispatch => {
    dispatch({
        type: Constant.GET_PROFILE_REQUEST,
    })
    get("/myAccount/getProfile")
        .then(data => {
            if (data.ERROR) {
                if (_isFunction(failureCb)) {
                    failureCb()
                }
                return dispatch({
                    type: Constant.GET_PROFILE_ERROR,
                    payload: data,
                })
            }
            if (_isFunction(successCb)) {
                successCb()
            }
            return dispatch({
                type: Constant.GET_PROFILE_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            if (_isFunction(failureCb)) {
                failureCb()
            }
            console.error(err)
        })
}

/**
 * Create user session
 *
 * @param {function}    successCB Success Callback
 * @param {function}    failCB Fail Callback
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const initLogin = (successCB, failCB) => (dispatch, getState) => {
    dispatch({
        type: Constant.INIT_LOGIN_REQUEST,
    })
    let params = {}
    let isToken = false
    const token = getAuthToken()
    if (token) {
        params = { token }
        isToken = true
    }
    get("/guest/loginInit", params)
        .then(data => {
            if (data.ERROR) {
                if (_isFunction(failCB)) {
                    failCB()
                }
                removeFromStorage("userDetails")
                removeFromStorage("auth")
                return dispatch({
                    type: Constant.INIT_LOGIN_ERROR,
                    isToken,
                    payload: data.ERROR,
                    cartSize: data.cart_size,
                })
            }
            const auth = {
                customerType: "registered",
                authSource: "T4F",
                authTime: _now(),
                expires: 1440,
                lastRequestTime: _now(),
                accessToken: data.token,
            }
            const authStore = offlineRetrive("auth") || {}
            offlineStore("auth", Object.assign(authStore, auth))
            if (_isFunction(successCB)) {
                successCB()
            }
            const userDetails = offlineRetrive("userDetails")
            if (!userDetails) {
                if (data.customerType !== "guest") {
                    offlineStore("userDetails", data)
                }
            } else if (data.customerType === "guest") {
                removeFromStorage("userDetails")
            }
            if (_isEmpty(getState().LoginReducer.getProfileData)) {
                dispatch(getProfile())
            }
            return dispatch({
                type: Constant.INIT_LOGIN_SUCCESS,
                isToken,
                payload: data,
            })
        })
        .catch(err => {
            if (_isFunction(failCB)) {
                failCB()
            }
            console.error(err)
        })
}

/**
 * Check for InitLogin expire or not
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const reInitSession = () => dispatch => {
    dispatch({
        type: Constant.CHECK_INIT_LOGIN_REQUEST,
    })
    const auth = offlineRetrive("auth")
    if (auth) {
        const diff = _now() - auth.authTime
        const minutes = Math.floor(((diff / 60000)))
        // It's timeout limit in minits
        const ourtimestamp = 23
        if (minutes > ourtimestamp) {
            // console.log("minutes", minutes)
            // offlineStore("auth", { ...auth, authTime: _now() })
            dispatch(initLogin())
        }
    } else {
        dispatch(initLogin())
    }
}

/**
 * Used for social login(Google/Facebook)
 *
 * @param {string}    email
 * @param {string}    userId
 * @param {string}    expiresIn
 * @param {string}    accessToken
 * @param {string}    type
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const socialUserLogin = (email, userId, expiresIn, accessToken, type) => dispatch => {
    dispatch({
        type: Constant.FETCH_SOCIAL_LOGIN_REQUEST,
    })
    post("/guest/loginFbUser", {}, {
        email, fb_uid: userId, access_token: accessToken, type,
    })
        .then(data => {
            if (!data.ERROR) {
                // console.log("loginFbUser", data)
                const auth = {
                    email,
                    customerType: "registered",
                    authSource: type,
                    authTime: _now(),
                    expires: expiresIn,
                    lastRequestTime: _now(),
                    accessToken,
                }
                if (
                    offlineStore("auth", auth)
                 && offlineStore("userDetails", data)
                ) {
                    dispatch(initLogin())
                    // push full name to GTM DL
                    oneSignalLoginHook({
                        fullName: data.fullname,
                    })
                }
                dispatch(getProfile())
                dispatch(InitWishlist())
                return dispatch({
                    type: Constant.FETCH_SOCIAL_LOGIN_SUCCESS,
                    payload: data,
                })
            }
            return dispatch({
                type: Constant.FETCH_SOCIAL_LOGIN_ERROR,
                payload: data,
            })
        })
        .catch(err => {
            console.error(err)
        })
}

/**
 * Used for social signup(Google/Facebook)
 *
 * @param {string}    email
 * @param {string}    userId
 * @param {string}    expiresIn
 * @param {string}    accessToken
 * @param {string}    type
 * @param {string}    firstName
 * @param {string}    lastName
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const socialUserSignup = (
    email,
    userId,
    accessToken,
    type,
    firstName,
    lastName,
    gender,
    birthday,
) => dispatch => {
    dispatch({
        type: Constant.FETCH_SOCIAL_REGIISTER_REQUEST,
    })
    post(
        "/guest/fbSignup",
        {},
        {
            first_name: firstName,
            last_name: lastName,
            email,
            fb_uid: userId,
            newsletter: 1,
            type,
            access_token: accessToken,
            gender,
            birthday,
        },
    ).then(data => {
        if (!data.ERROR) {
            const auth = {
                email,
                customerType: "registered",
                authSource: "T4F",
                authTime: _now(),
                expires: 1440,
                lastRequestTime: _now(),
                accessToken: data.token,
            }
            if (
                offlineStore("auth", auth)
                    && offlineStore("userDetails", data)
            ) {
                dispatch(initLogin())
            }
            dispatch({
                type: Constant.FETCH_SOCIAL_REGIISTER_SUCCESS,
                payload: data,
            })
            window.fbq("track", "Lead", {})
            alert(t("Thank you for becoming a member of Tours4Fun Family") + ".")
        } else {
            alert(t(data.ERROR))
        }
    })
        .catch(err => {
            console.error(err)
        })
}

/**
 * Check user logged in or not
 *
 * @param {string}    email
 * @param {string}    userId
 * @param {string}    expiresIn
 * @param {string}    accessToken
 * @param {string}    type
 * @param {string}    firstName
 * @param {string}    lastName
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const socialUserLoginCheck = (
    email,
    userId,
    expiresIn,
    accessToken,
    type,
    firstName,
    lastName,
    gender,
    birthday,
) => dispatch => {
    dispatch({
        type: Constant.FETCH_SOCIAL_REGIISTERED_REQUEST,
    })
    post("/guest/isFBCustomerRegistered", {}, {
        email, userId, access_token: accessToken, type, gender, birthday,
    })
        .then(data => {
            dispatch({
                type: Constant.FETCH_SOCIAL_REGIISTERED_SUCCESS,
                payload: data,
                socialResponce: {
                    email,
                    userId,
                    expiresIn,
                    accessToken,
                    type,
                    firstName,
                    lastName,
                },
                authenticated: false,
            })
            if (data.registered) {
                dispatch(socialUserLogin(email, userId, expiresIn, accessToken, type))
            } else if (email) {
                dispatch(socialUserSignup(
                    email,
                    userId,
                    accessToken,
                    type,
                    firstName,
                    lastName,
                    gender,
                    birthday,
                ))
            }
        })
        .catch(err => {
            console.error(err)
        })
}

/**
 * Send forgot password request
 *
 * @param {string}    email
 * @param {function}    successCB
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const forgotPassword = (email, successCB) => dispatch => {
    dispatch({
        type: Constant.FETCH_FORGOT_PASSWORD_REQUEST,
    })
    post(
        "/guest/getPassword",
        {},
        {
            email,
        },
    )
        .then(data => {
            if (data.ERROR) {
                alert(t(data.ERROR))
                return dispatch({
                    type: Constant.FETCH_FORGOT_PASSWORD_ERROR,
                    payload: data.ERROR,
                })
            }

            alert(t(
                "The password reset instructions have sent to {email}",
                { email },
            ))
            if (_isFunction(successCB)) {
                successCB()
            }
            return dispatch({
                type: Constant.FETCH_FORGOT_PASSWORD_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.error(err)
        })
}

/**
 * Customer email login
 *
 * @param {string}    email
 * @param {string}    password
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const localUserLogin = (email, password) => {
    const emailValue = encodeURIComponent(email.trim().toLowerCase())
    return dispatch => {
        dispatch({
            type: Constant.FETCH_LOGIN_REQUEST,
        })
        post("/guest/login", {}, { email: emailValue, password })
            .then(data => {
                if (!data.ERROR) {
                    const auth = {
                        email,
                        customerType: "registered",
                        authSource: "T4F",
                        authTime: _now(),
                        expires: 1440,
                        lastRequestTime: _now(),
                        accessToken: data.token,
                    }
                    if (
                        offlineStore("auth", auth)
                        && offlineStore("userDetails", data)
                    ) {
                        dispatch(initLogin())
                        // push to GTM DL
                        oneSignalLoginHook({
                            fullName: data.fullname,
                        })
                    }
                    dispatch(getProfile())
                    dispatch(InitWishlist())
                    dispatch({ type: Constant.CLEAR_CART_PRODUCT_BOOKINGREDUCERS })
                    dispatch({ type: Constant.CLEAR_CART_PRODUCT_CHECKOUTREDUCERS })
                    return dispatch({
                        type: Constant.FETCH_LOGIN_SUCCESS,
                        payload: data,
                    })
                }
                return dispatch({
                    type: Constant.FETCH_LOGIN_ERROR,
                    payload: data,
                })
            })
            .catch(err => {
                console.error(err)
            })
    }
}

/**
 * Check User authentication
 *
 * @param {string}    requestedPath
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const authenticate = (requestedPath = "/") => dispatch => {
    dispatch({
        type: Constant.CHECK_LOGIN_REQUEST,
        requestedPath,
    })
    // Retrive User data from local storage
    const userDetails = offlineRetrive("userDetails")
    if (userDetails) {
        dispatch({
            type: Constant.CHECK_LOGIN_SUCCESS,
            payload: userDetails,
        })
    } else {
        dispatch({
            type: Constant.CHECK_LOGIN_ERROR,
        })
    }
}

/**
 * Activate logged in guest customer
 *
 * @param {string}    password
 * @param {string}    confirmPassword
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const activateGuestAccount = (
    password,
    confirmPassword,
    successCB,
    failCB,
) => dispatch => {
    dispatch({
        type: Constant.ACTIVE_GUEST_REQUEST,
    })
    post("/guest/activateGuestAccount", {}, { password, confirm_password: confirmPassword })
        .then(data => {
            if (data.ERROR) {
                if (_isFunction(failCB)) {
                    failCB()
                }
                return dispatch({
                    type: Constant.ACTIVE_GUEST_ERROR,
                    payload: data,
                })
            }
            dispatch(initLogin())
            oneSignalLoginHook({
                fullName: data.fullname,
            })
            dispatch(getProfile())
            if (_isFunction(successCB)) {
                successCB()
            }
            return dispatch({
                type: Constant.ACTIVE_GUEST_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.error(err)
        })
}


/**
 * Login as a guest customer. So user can create order.
 * This should be only valid for checkouts.
 * Other areas are not authorized for this type of customer
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const loginAsGuest = () => dispatch => {
    dispatch({
        type: Constant.LOGIN_AS_GUEST_REQUEST,
    })
    get("/guest/loginAsGuest")
        .then(data => {
            if (data.ERROR) {
                return dispatch({
                    type: Constant.LOGIN_AS_GUEST_ERROR,
                    payload: data,
                })
            }
            removeFromStorage("userDetails")
            return dispatch({
                type: Constant.LOGIN_AS_GUEST_SUCCESS,
                payload: data,
            })
        })
        .catch(err => {
            console.error(err)
        })
}

/**
 * Update Requested Path for redux data Store
 *
 * @param {string}  path
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const updateRequestedPath = (path = "/") => dispatch => {
    dispatch({
        type: Constant.UPDATE_REQUESTED_PATH,
        payload: path,
    })
}

/**
 * Clear User Details from redux data Store
 *
 * @author Palak <palakt.bipl@gmail.com>
 */
export const clearUserDetails = () => dispatch => {
    dispatch({
        type: Constant.CLEAR_USER_DETAILS,
    })
}

/**
  * Logout User
  *
  * @author Palak <palakt.bipl@gmail.com>
  */
export const LogoutUser = () => dispatch => {
    if (typeof navigator !== "undefined" && navigator.onLine) {
        dispatch({
            type: Constant.LOGOUT_USER_INIT,
            authenticated: false,
        })
        get("/myAccount/logout")
            .then(data => {
                if (data.ERROR) {
                    alert(t(data.ERROR))
                } else {
                    dispatch(clearCart())
                    // dispatch(resetSignUpData())
                    dispatch({ type: Constant.CLEAR_SIGN_UP_DATA })
                    removeFromStorage("userDetails")
                    removeFromStorage("auth")
                    dispatch({
                        type: Constant.LOGOUT_USER_SUCCESS,
                    })
                    alert(t("You have successfully logged out!"))
                    /*
                        refresh need because when user logout it will clear all product
                        and we need to show product which user added without login
                    */
                    if (!config.isApplication && typeof location !== "undefined") {
                        location.reload(true)
                    }
                }
            })
            .catch(err => {
                console.error(err)
                alert(t("There was an error while logging you out"))
            })
    } else {
        alert(t("There was an error while logging you out"))
    }
}

/**
 * Updates profile picture of user profile
 * @param {form} profilePic
 * @author Akash M <akashm@gmail.com>
 */
export const updateUserProfile = profilePic => (dispatch, getState) => {
    const { getProfileData } = getState().LoginReducer
    const { getProfileError } = getState().LoginReducer
    post("/myAccount/uploadProfilePic", {}, profilePic, true)
        .then(imageData => {
            if (imageData && imageData.photo) {
                getProfileData.avatar = imageData.photo
                dispatch({
                    type: Constant.UPDATE_PROFILE_PICTURE_SUCCESS,
                    payload: getProfileData,
                })
            } else {
                getProfileError.ERROR = "Failed to update image"
                dispatch({
                    type: Constant.UPDATE_PROFILE_PICTURE_FAILURE,
                    payload: getProfileError,
                })
            }
        }).catch(imageErr => {
            getProfileError.ERROR = imageErr
            dispatch({
                type: Constant.UPDATE_PROFILE_PICTURE_FAILURE,
                payload: getProfileError,
            })
        })
}

/**
 * Store auth token to be use in future
 *
 * @param {string} token Authentication token
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const storeAuthToken = token => {
    if (!token) {
        return false
    }

    let auth = offlineRetrive("auth")
    if (!auth) {
        auth = {}
    }
    if (auth.accessToken !== token) {
        auth.accessToken = token
        offlineStore("auth", auth)
    }
    return true
}

const LoginAct = {
    initLogin,
    reInitSession,
    loginAsGuest,
    getProfile,
    updateRequestedPath,
    activateGuestAccount,
    canActivateGuestAccount,
    localUserLogin,
    socialUserLogin,
    socialUserLoginCheck,
    forgotPassword,
    socialUserSignup,
    authenticate,
    clearUserDetails,
    LogoutUser,
    updateUserProfile,
}
export default LoginAct
