import { offlineRetrive } from "./util"

export function getCustomerType() {
    const result = offlineRetrive("auth")
    if (result) {
        return result.customerType
    }
    return "guest"
}
export function getAuthTime() {
    const result = offlineRetrive("auth")
    if (result) {
        return result.authTime
    }
    return 0
}
export function getAuthToken() {
    const result = offlineRetrive("userDetails")
    const auth = offlineRetrive("auth")
    if (result) {
        return result.token
    } else if (auth) {
        if (auth.accessToken) {
            return auth.accessToken
        }
    }
    return 0
}

/**
 * Get auth email of customer
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export function getAuthEmail() {
    const result = offlineRetrive("auth")
    if (result) {
        return result.email
    }
    return ""
}
