import { combineReducers } from "redux"
import fetch from "./fetch"
import ProductReducers from "./ProductReducers"
import MetaReducers from "./MetaReducers"
import HomeReducer from "./HomeReducer"
import ToursReducers from "./ToursReducers"
import RecentlyReducer from "./RecentlyReducers"
import SearchReducer from "./SearchReducer"
import ShareReducers from "./ShareReducers"
import MenuReducers from "./MenuReducers"
import LoginReducer from "./LoginReducers"
import WishlistReducer from "./WishlistReducers"
import SignupReducer from "./SignupReducers"
import CartReducer from "./CartReducers"
import CommonReducers from "./CommonReducers"
import InitData from "./InitData"
import CheckoutReducers from "./CheckoutReducers"
import BookingReducers from "./BookingReducers"
import AccountReducers from "./AccountReducers"

const rootReducer = combineReducers({
    fetch,
    InitData,
    BookingReducers,
    MetaReducers,
    MenuReducers,
    HomeReducer,
    ProductReducers,
    ToursReducers,
    SearchReducer,
    LoginReducer,
    WishlistReducer,
    SignupReducer,
    CartReducer,
    CommonReducers,
    RecentlyReducer,
    ShareReducers,
    CheckoutReducers,
    AccountReducers,
})
export default rootReducer
