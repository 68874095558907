import Constant from "../constants"
import { get } from "../../common/api"
import getWithAbort from "../../common/api/extraFunction"

export const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    return dispatch => {
        dispatch({
            type: Constant.SUGGESTIONS_GET,
        })
        getWithAbort("/product/searchSuggestion", { keyword: inputValue })
            .then(data =>
                dispatch({
                    type: Constant.SUGGESTIONS_GET_SUCCESS,
                    payload: data.data,
                    searchValue: value,
                }))
            .catch(err => {
                console.log(err)
            })
    }
}
export const getPopular = () =>
    (dispatch, getState) => {
        dispatch({
            type: Constant.POPULAR_SEARCH,
        })
        const popularSearch = getState().SearchReducer.popularSearch
        if (popularSearch.length > 0) {
            dispatch({
                type: Constant.POPULAR_SEARCH_SUCCESS,
                payload: popularSearch,
            })
        } else {
            get("/product/popularSearchKeywords")
                .then(data =>
                    dispatch({
                        type: Constant.POPULAR_SEARCH_SUCCESS,
                        payload: data.data,
                    }))
                .catch(err => {
                    console.error(err)
                })
        }
    }


export const clearSuggestions = () =>
    dispatch => dispatch({
        type: Constant.SUGGESTIONS_SEARCH_VALUE_CLEAR,
        payload: [],
    })

export const getSuggestionValue = suggestion => suggestion.name

export const setSuggestionValue = suggestion => dispatch => dispatch({
    type: Constant.SUGGESTIONS_SEARCH_VALUE_SET,
    suggestion,
})

export const homeSearchToogle = value =>
    dispatch => dispatch({
        type: Constant.SHOW_AT_HOME,
        payload: value,
    })

const SearchAct = {
    getSuggestionValue,
    homeSearchToogle,
    getSuggestions,
    clearSuggestions,
    setSuggestionValue,
    getPopular,
}
export default SearchAct
