import algoliasearch from "algoliasearch"
import config, { t } from "../../../config"
import {
    _map,
    _isEmpty,
    _split,
} from "./util"
import { clearhtml, htmlEntitiesDecode } from "./filter"
import * as dateUtils from "./dateUtils"
import * as Formatter from "./Formatter"
import ProductPrice from "./ProductPrice"

function getSpecialOfferText(key) {
    switch (key) {
    case "buy2get1":
        return "Buy 2, Get 3rd Discounted"
    case "buy2get2":
        return "Buy 2, Get 3rd & 4th Discounted"
    default:
        return Formatter.urlUnPrettify(key).toLowerCase()
    }
}
/* eslint-disable no-useless-escape, no-param-reassign, no-underscore-dangle */
function ucwords(str) {
    if (typeof str === "undefined") {
        return str
    }
    let returnStr = str
    returnStr = str.toLowerCase()
    return returnStr.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, s => s.toUpperCase())
}
function getImageUrl(path) {
    if (!path) { return "" }
    const cdn = config.cdn.url
    const pattern1 = /^http[s]?:\/\/dn\-tffimg\.qbox\.me/
    if (path.match(pattern1)) {
        path = path.replace(pattern1, cdn)
    } else if (path.match(/^\/\w+/)) {
        path = cdn + path
    } else if (path.match(/^(?!http[s]?:\/\/).*/)) {
        path = config.cdn.sslUrl + "/images/" + path
    }
    return path
}

function getFeatureMatchKeys() {
    return {
        "feature-all": "feature-all-icon", "feature-multi": "feature-multi-icon", "feature-ttd": "feature-ttd-icon", "feature-bus": "feature-bus-icon",
    }
}

function isFullyHighlighted(highlights) {
    let returnVal = false
    _map(highlights, value => {
        if (value.fullyHighlighted && value.fullyHighlighted === true) {
            returnVal = value
        }
    })
    return returnVal
}

function getFullMatchHighlightForFeature(highlightResult) {
    if (typeof highlightResult !== "object") {
        return false
    }
    const keys = getFeatureMatchKeys()
    let returnVal = false
    _map(highlightResult, (highlight, index) => {
        _map(keys, (key => {
            if (key === index) {
                const highlighted = isFullyHighlighted(highlightResult[highlight])
                if (highlighted) {
                    returnVal = { key, value: highlighted.value, fullResponse: highlighted }
                }
            }
        }))
    })
    return returnVal
}

function getFeaturedIconId(highlights, matchedKey) {
    const keys = getFeatureMatchKeys()
    const setFix = keys[matchedKey.key]
    let returnVal
    if (!setFix) {
        returnVal = ""
    } else if (highlights[setFix]) {
        for (let i = 0; i < highlights[setFix].length; i += 1) {
            const row = highlights[setFix][i]
            const vals = row.value.split("##")
            if (vals[0].toLowerCase() === matchedKey.value.toLowerCase()) {
                returnVal = vals[1]
            }
        }
    }
    return returnVal
}

function getFeaturedIcon(v, hasDiscount) {
    if (hasDiscount) {
        return "sale"
    }

    if (!v._highlightResult) {
        return ""
    }

    const featureKey = getFullMatchHighlightForFeature(v._highlightResult)
    if (!featureKey) {
        return ""
    }

    const featuredIcon = getFeaturedIconId(v._highlightResult, featureKey)
    switch (parseInt(featuredIcon, 10)) {
    case 1:
        return "staff-pick"
    case 2:
        return "new"
    default:
        if (hasDiscount) {
            return "sale"
        }
        return ""
    }
}

function canInstantConfirm(v) {
    if (v.free_sale_duration > -1) {
        return true
    }

    const today = new Date()

    if (v.auto_confirm) {
        for (let i = 0; i < v.auto_confirm.length; i += 1) {
            const ac = v.auto_confirm[i]
            const endDate = dateUtils.stringToDate(ac.end_date)
            if (endDate > today) {
                return true
            }
        }
    }
    return false
}

// @FIXME country and departure city may not be same, If there are multiple countries
// Eg. Niagara falls tours has "United States" and "Canada" as countries.
// This cannot fix only using Algolia data
function gaCategoryName(v) {
    const departureCity = v.departure_city.join(", ")
    const country = v.country[0] ? v.country[0] : "undefined"
    const activityName = v.activity_type_name.split("/")
    const duration = v.duration_type !== 0 ? 1 : v.duration
    return `${v.region_name}/${departureCity}, ${country}/${v.product_entity_type}/${activityName[0]}/${duration}D`
}

/**
 * get FeatureTourIcon
 * @param {Object} tourObj Algolia tour Object Response
 *
 * @author Pratik B < pratikb.bipl @gmail.com >
 */
function getFeatureTourIconType(tourObj, pageUrl) {
    if (
        _isEmpty(tourObj._highlightResult)
        || _isEmpty(pageUrl)
        || _isEmpty(tourObj._highlightResult["mobile-feature-url"])
    ) {
        return 0
    }
    let returnIconType = 0
    const mobileFeatureUrl = tourObj._highlightResult["mobile-feature-url"]
    mobileFeatureUrl.map((item, keys) => {
        const chkFeaturedPath = clearhtml(item.value).toLowerCase()
        if (
            item.fullyHighlighted
            || chkFeaturedPath === pageUrl
        ) {
            const iconType = _split(
                tourObj._highlightResult["mobile-feature-url-icon"][keys].value,
                "##",
                2,
            )
            if (iconType.length > 1) {
                returnIconType = iconType[1]
            }
        }
    })
    return Number(returnIconType)
}

function mapProduct(v, InitData, currency = "USD", pageUrl = "") {
    const A = v.product_id.substr(0, v.product_id.indexOf("-"))
    // if currency_code is not USD use real_default_price_t4f as default_price_t4f
    if (v.currency_code !== "USD") {
        v.default_price_t4f = v.real_default_price_t4f
    }
    const productPrice = new ProductPrice()
    const price = productPrice.getPrice(v, currency, false, InitData)
    const defaultPrice = price.targetDefaultPrice
    const specialPrice = price.targetSpecialPrice
        ? price.targetSpecialPrice
        : defaultPrice
    const discount = productPrice.getPriceOffT4fAPI(v, currency, InitData)
    return {
        I: v.new_product_id ? v.new_product_id : v.objectID,
        N: v.product_name,
        P: defaultPrice,
        ET: v.product_entity_type,
        ETI: v.product_entity_type_id,
        G: getImageUrl(v.image_url),
        A,
        AC: canInstantConfirm(v),
        D: v.duration,
        DT: v.duration_type,
        DN: v.duration_name,
        M: v.product_code,
        CO: v.product_id,
        IC: v.product_code,
        S: v.rating_stars * 10,
        CN: v.review_count,
        SP: specialPrice,
        SG: v.rating_stars,
        DS: v.highlights,
        SDS: v.highlights,
        SC: ucwords(v.departure_city[0]),
        DC: v.departure_city.join(", "),
        EC: ucwords(v.depart_end_cities[0]),
        OP: null,
        T: null,
        PG: [v.image_url],
        TTI: v.special_offer,
        promo_notice: v.promo_notice,
        U: config.host + "/" + v.url_path + ".html",
        L: v.product_operator_language,
        PKG: false,
        ST: v.soldout,
        small_group_size: v.small_group_size,
        featured: getFeaturedIcon(v, defaultPrice !== specialPrice),
        tour_type_icons: v.special_offer,
        ga_category_name: gaCategoryName(v),
        departure_city_geo: v.departure_city_geo,
        featureTourIconType: getFeatureTourIconType(v, pageUrl),
        discount,
    }
}

function getAvailablePricingFilters(defaultPriceT4f) {
    const prices = Object.keys(defaultPriceT4f)
    prices.sort((a, b) => a - b)
    const min = prices[0]
    const max = prices[prices.length - 1]
    const allPriceFilters = [
        {
            id: "",
            text: "All Prices",
        }, {
            id: "0-100",
            text: t("Under", { no: 100 }),
        }, {
            id: "101-500",
            text: t("Range", { start: 100, end: 500 }),
        }, {
            id: "501-1000",
            text: t("Range", { start: 2000, end: 2000 }),
        }, {
            id: "1001-2000",
            text: t("Range", { start: 2000, end: 2000 }),

        }, {
            id: "2001-3000",
            text: t("Range", { start: 2000, end: 2000 }),

        }, {
            id: "3001-" + max,
            text: t("Above", { no: 3000 }),
        },
    ]
    let lower = 0
    let upper = 0
    // for lower mark
    if (min <= 100) {
        lower = 1
    } else if (min <= 500) {
        lower = 2
    } else if (min <= 1000) {
        lower = 3
    } else if (min <= 2000) {
        lower = 4
    } else if (min <= 3000) {
        lower = 5
    } else if (min > 3000) {
        lower = 6
    }
    // for uppper limit
    if (max <= 100) {
        upper = 2
    } else if (max <= 500) {
        upper = 3
    } else if (max <= 1000) {
        upper = 4
    } else if (max <= 2000) {
        upper = 5
    } else if (max <= 3000) {
        upper = 6
    } else if (max > 3000) {
        upper = 7
    }
    const filters = []
    for (let i = lower; i < upper; i += 1) {
        filters.push(allPriceFilters[i])
    }
    return filters
}

function getAvailablePromotionFilters(promotions, startDate, endDate) {
    const filters = {}
    if (promotions !== "undefined" && promotions) {
        Object.keys(promotions).forEach(promotion => {
            if (promotion.toLowerCase() !== "special offer") {
                filters[Formatter.urlPrettify(promotion)] = {
                    text: promotion,
                    amount: promotions[promotion],
                }
            }
        })
    }
    const currentMs = parseInt(((new Date()).getTime() / 1000), 10)
    let startDateCount = 0
    let endDateCount = 0
    if (startDate !== "undefined" && startDate) {
        Object.keys(startDate).forEach(item => {
            if (item === 0 || item < currentMs) {
                startDateCount += startDate[item]
            }
        })
    }
    if (endDate !== "undefined" && endDate) {
        Object.keys(endDate).forEach(item => {
            if (item === 0 || item > currentMs) {
                endDateCount += endDate[item]
            }
        })
    }
    if (startDateCount > 0 && endDateCount > 0) {
        filters.deals = {
            text: "Deals",
            amount: startDateCount + endDateCount,
        }
    }
    return filters
}

function getAvailableActivityTypeFilters(activityTypes) {
    const filters = []
    Object.keys(activityTypes).forEach(activityType => {
        const elements = activityType.split("##")
        filters.push({
            name: elements[1],
            product_type_id: elements[0],
            total: activityTypes[activityType],
        })
    })
    filters.sort((a, b) => {
        let returnVal = 0
        if (a.total > b.total) {
            returnVal = -1
        }
        if (a.total < b.total) {
            returnVal = 1
        }
        return returnVal
    })
    return filters
}

function getAvailableDurationFilters(durations) {
    const filters = []
    let oneDay = false
    Object.keys(durations).map(type => {
        const time = type.split("##")
        _map(time, (item, key) => { time[key] = parseInt(item, 10) })
        if ((time[0] === 0 || time[0] === 1 || time[1] > 0) && oneDay) {
            return true
        }
        if (time[0] === 0 || time[0] === 1 || time[1] > 0) {
            oneDay = true
        }
        filters.push({
            id: time[0] === 0 || time[1] > 0 ? 1 : time[0],
            text: time[0] === 0 || time[0] === 1 || time[1] > 0 ? "1 " + t("Day") : time[0] + " " + t("Days"),
            count: durations[type],
        })
        return true
    })
    filters.sort((a, b) => a.id - b.id)
    return filters
}

function getAvailableFilters(facets, currency, InitData) {
    const availableFilters = {}
    availableFilters.entity_stats = facets.product_entity_type_id
    if (facets.departure_city) {
        availableFilters.startCities = []
        Object.keys(facets.departure_city).forEach(city => {
            availableFilters.startCities.push({
                name: city,
                seo_url: Formatter.urlPrettify(city),
                tour_city_id: encodeURIComponent(encodeURIComponent(city)),
            })
        })
    }
    if (facets.depart_end_cities) {
        availableFilters.endCities = []
        Object.keys(facets.depart_end_cities).forEach(city => {
            availableFilters.endCities.push({
                name: city,
                seo_url: Formatter.urlPrettify(city),
                tour_city_id: encodeURIComponent(encodeURIComponent(city)),
            })
        })
    }
    if (facets.visiting_attractions) {
        availableFilters.attractions = []
        Object.keys(facets.visiting_attractions).forEach(city => {
            availableFilters.attractions.push({
                name: city,
                seo_url: Formatter.urlPrettify(city),
                tour_city_id: encodeURIComponent(city),
            })
        })
    }
    if (facets.product_operator_language) {
        availableFilters.operatorLanguage = []
        Object.keys(facets.product_operator_language).forEach(city => {
            const newCity = city.split(" ")[0]
            let isDuplicate = false
            availableFilters.operatorLanguage.map(item => {
                if (item.name === newCity) {
                    isDuplicate = true
                }
            })
            if (!isDuplicate) {
                availableFilters.operatorLanguage.push({
                    name: city,
                    seo_url: Formatter.urlPrettify(city),
                    id: city,
                })
            }
        })
    }
    if (facets.default_price_t4f) {
        const price = Object.keys(facets.default_price_t4f)
        // console.log(productPrice.getPrice(facets, currency, false, InitData))
        availableFilters.default_price_t4f = {
            min: Formatter.convertCurrency("USD", currency, (Math.min(...price)), 2, InitData) || 0,
            max: Formatter.convertCurrency("USD", currency, (Math.max(...price)), 2, InitData) || 0,
        }
        availableFilters.pricing = getAvailablePricingFilters(facets.default_price_t4f)
    }
    if (facets.activity_type_name) {
        availableFilters.prodcutTypes = getAvailableActivityTypeFilters(facets.activity_type)
    }
    if (facets.special_offer) {
        availableFilters.promotions = getAvailablePromotionFilters(facets.special_offer, facets["deal_dates.start_date"], facets["deal_dates.end_date"])
    }

    const om = []
    if (facets.operation_months) {
        const keyArr = Object.keys(facets.operation_months)
        const monthArr = ["", t("Jan"), t("Feb"), t("Mar"), t("Apr"), t("May"), t("Jun"), t("Jul"), t("Aug"), t("Sep"), t("Oct"), t("Nov"), t("Dec")]
        let tmpArr = []
        let tdataInd = 0
        keyArr.map(index => {
            tmpArr = index.split("-")
            const currentTime = new Date()
            if (!(tmpArr[0] < currentTime.getFullYear()
                || (currentTime.getFullYear() === tmpArr[0]
                && parseInt(tmpArr[1], 10) < (currentTime.getMonth() + 1))
            )) {
                tdataInd = (parseInt(tmpArr[0], 10) * 100) + parseInt(tmpArr[1], 10)
                tmpArr[0] = monthArr[parseInt(tmpArr[1], 10)] + "-" + tmpArr[0]
                om.push({
                    name: tmpArr[0],
                    seo_url: Formatter.urlPrettify(tmpArr[0]),
                    tour_city_id: Formatter.urlPrettify(tmpArr[0]),
                    dataInd: tdataInd,
                })
            }
        })
    }
    availableFilters.operationMonths = om
    if (facets.duration_identifier) {
        availableFilters.duration = getAvailableDurationFilters(facets.duration_identifier)
    }
    return availableFilters
}
/* eslint-enable no-useless-escape, no-param-reassign */
class Algolia {
    constructor(configs) {
        this.algoliaConf = configs
        this.filters = []
        this.client = algoliasearch(this.algoliaConf.app_id, this.algoliaConf.search_key)
        this.attributesToHighlight = []
        this.restrictSearchableAttributes = []
        this.filtersForQuery2 = []
        this.entityTypeId = ""
        this.boundingBox = {}
        this.aroundLatLng = ""
        this.pageUrl = ""
    }

    setSearchIndex(index) {
        this.algoliaConf.search_index = index
    }

    // eslint-disable-next-line no-unused-vars
    setAttributesToHighlight(entityTypeId, productTypeId) {
        this.attributesToHighlight = ["mobile-feature-url", "mobile-feature-url-icon"]
        // this.entityTypeId = isNaN(entityTypeId) ? "" : entityTypeId
        // if ((entityTypeId === 0 || entityTypeId === 10001) && productTypeId === 1) {
        //     this.attributesToHighlight = ["feature-bus", "feature-bus-icon"]
        // } else if (entityTypeId === 0 && productTypeId === "") {
        //     this.attributesToHighlight = ["feature-ttd", "feature-ttd-icon"]
        // } else if (entityTypeId === 10001) {
        //     this.attributesToHighlight = ["feature-all", "feature-all-icon"]
        // } else if (entityTypeId === 1) {
        //     this.attributesToHighlight = ["feature-multi", "feature-multi-icon"]
        // } else {
        //     this.attributesToHighlight = ["none"]
        // }
    }

    setRestrictSearchableAttributes(restrictSearchAttr) { // tour_tag
        this.restrictSearchableAttributes = restrictSearchAttr
    }

    setAdditionalFiltersForQuery2(key, value, operator) {
        this.filtersForQuery2.push(key + operator + value)
    }

    setPageUrl(pageUrl) {
        this.pageUrl = pageUrl.toLowerCase()
    }

    search(
        query,
        page,
        limit,
        successF,
        failedF,
        InitData,
        currency,
        isGEO = false,
        isHomePage = false,
        allowTypoTolerance = false,
    ) {
        const self = this
        const filterString = self.filters.join(" AND ")
        // get index name
        let indexName = this.algoliaConf.search_index
        if (isGEO) {
            if (config.language.id === "2") {
                indexName = "t4fes_billing_mob"
            } else {
                indexName = "t4f_billing_mob"
            }
        }
        // request facets only for first page

        let algoliaQuery = []
        const params = {
            attributesToRetrieve: "*",
            attributesToHighlight: self.attributesToHighlight,
            hitsPerPage: limit,
            page,
            filters: filterString,
        }
        if (page === 0) {
            params.facets = "*"
        }
        if (self.restrictSearchableAttributes.length > 0) {
            params.restrictSearchableAttributes = self.restrictSearchableAttributes
        }
        const typoToleranceParams = {}
        if (allowTypoTolerance) {
            typoToleranceParams.typoTolerance = true
            typoToleranceParams.allowTyposOnNumericTokens = true
            typoToleranceParams.minWordSizefor1Typo = 3
            typoToleranceParams.minWordSizefor2Typos = 6
            typoToleranceParams.ignorePlurals = true
            typoToleranceParams.removeWordsIfNoResults = "allOptional"
            typoToleranceParams.enableRules = false
            typoToleranceParams.restrictSearchableAttributes = []
            Object.assign(params, typoToleranceParams)
        }
        const query0 = {
            indexName,
            query,
            params,
        }
        // search inside a position
        if (isGEO) {
            // exclude soldout
            query0.params.filters += " AND soldout=1"
            if (!_isEmpty(this.boundingBox)) {
                query0.params.insideBoundingBox = [this.boundingBox]
            } else if (!_isEmpty(this.aroundLatLng)) {
                query0.params.aroundLatLng = this.aroundLatLng
                query0.params.aroundRadius = "all"
            }
        }

        const isNeedFilter = (page === 0) && !isHomePage
        let mainFilter = {}
        // if (typeof document !== "undefined") {
        //     const sessionFilter = offlineRetrive("filter_"
        //         + Formatter.toSeoUrl(query)
        //         + "_" + this.entityTypeId + true, true)
        //     if (!_isEmpty(sessionFilter)) {
        //         isNeedFilter = false
        //         mainFilter = sessionFilter
        //     }
        // }
        let useQuery2 = false
        if (isNeedFilter) {
            let storeStatus
            if (indexName.search("t4fes") >= 0) {
                storeStatus = "t4fes_status"
            } else {
                storeStatus = "t4f_status"
            }
            const query1 = {
                indexName,
                query,
                params: {
                    attributesToRetrieve: ["objectId"],
                    attributesToHighlight: ["none"],
                    hitsPerPage: 1,
                    page: 0,
                    facets: "*",
                    filters: "global_status=1 AND " + storeStatus + "=1",
                    ...typoToleranceParams,
                },
            }

            // search inside a position
            if (isGEO) {
                // only oneday tours for geosearch
                // exclude soldout products
                query1.params.filters += " AND soldout=1"
                if (!_isEmpty(this.boundingBox)) {
                    query1.params.insideBoundingBox = [this.boundingBox]
                } else if (!_isEmpty(this.aroundLatLng)) {
                    query1.params.aroundLatLng = this.aroundLatLng
                    query1.params.aroundRadius = "all"
                }
            }

            // another query to get other entity types data with only primary filters
            const query2 = {
                ...query1,
                params: {
                    ...query1.params,
                    facets: ["product_entity_type_id"],
                    ...typoToleranceParams,
                },
            }

            // include product entity type in filter query
            self.filters.map(filtr => {
                if (/product_entity_type_id/.test(filtr)) {
                    useQuery2 = true
                    query1.params.filters += ` AND ${filtr}`
                }
            })

            algoliaQuery = [query0, query1]
            if (useQuery2) {
                algoliaQuery.push(query2)
            }
        } else {
            algoliaQuery = [query0]
        }
        return self.client.search(algoliaQuery, (err, content) => {
            if (err) {
                failedF("Algolia Api calling error" + err)
            } else {
                const products = []
                const response = content.results[0]
                for (let i = 0; i < response.hits.length; i += 1) {
                    products.push(mapProduct(response.hits[i], InitData, currency, self.pageUrl))
                }
                const data = {
                    products,
                    total_products: response.nbHits,
                }
                if (page === 0) {
                    data.filters = getAvailableFilters(
                        content.results[0].facets,
                        currency,
                        InitData,
                    )
                    data.entity_stats = content.results[0].facets.product_entity_type_id
                }
                if (isNeedFilter) {
                    mainFilter = getAvailableFilters(
                        content.results[1].facets,
                        currency,
                        InitData,
                    )
                    if (useQuery2) {
                        // use entity stats with 3rd query, because 2nd query is entity specific
                        mainFilter.entity_stats = content.results[2].facets.product_entity_type_id
                    }
                }
                data.mainFilter = mainFilter
                successF(data)
            }
        })
    }

    getProductById(ids, successF, failedF, InitData, currency) {
        const self = this
        const index = self.client.initIndex(this.algoliaConf.search_index)
        return index.getObjects(ids, ["*"], (err, content) => {
            if (err) {
                failedF("Algolia Api calling error: " + err)
            } else {
                const products = []
                const response = content.results
                response.map(item => {
                    if (item) {
                        products.push(mapProduct(item, InitData, currency))
                    }
                })
                successF(products)
            }
        })
    }

    setFilter(key, value, operator, currency = "USD", InitData) {
        if (key === "default_price_t4f") {
            const minmax = value.split("-")
            this.filters.push(key + ">=" + Formatter.convertCurrency(currency, "USD", minmax[0], 2, InitData))
            this.filters.push(key + "<=" + Formatter.convertCurrency(currency, "USD", minmax[1], 2, InitData))
            return
        } if (key === "special_offer") {
            const text = getSpecialOfferText(value)
            if (!text) {
                return
            }
            this.filters.push(`${key}: "${text}"`)
            return
        }
        this.filters.push(htmlEntitiesDecode(key + operator + value))
    }

    /**
     * Set geo search parameters for Rectangular Area
     *
     * @param {numeric} p1Lat    Latitiude
     * @param {numeric} p1Lng    Longitude
     * @param {numeric} p2Lat    2-Latitiude
     * @param {numeric} p2Lng    2-Longitude
     *
     * @author Pratik B <pratikb.bipl@gmail.com>
     */
    setRectangularArea(p1Lat, p1Lng, p2Lat, p2Lng) {
        this.boundingBox = [
            parseFloat(p1Lat),
            parseFloat(p1Lng),
            parseFloat(p2Lat),
            parseFloat(p2Lng),
        ]
    }

    /**
     * Set geo search parameters for radius search
     *
     * @param {numeric} Latitiude    Latitiude
     * @param {numeric} Longitude    Longitude
     *
     * @author Pratik B <pratikb.bipl@gmail.com>
     */
    setAroundLatLng(p1Lat, p1Lng) {
        this.aroundLatLng = p1Lat + ", " + p1Lng
    }

    clearCache() {
        this.client.clearCache()
    }
}
export default Algolia
