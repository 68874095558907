import { asyncScriptLoad } from "../common/tool/util"
import { getAuthEmail } from "../common/tool/auth"
import {
    load, setSessionId, setUserId, trackPageview,
} from "../common/tool/siftscience"
import config, { isProd, isT4F } from "../../config"

export default browserHistory => {
    /* eslint-disable */
    // patch to hide errors from window.ga absance
    window["GoogleAnalyticsObject"]="ga";window["ga"]=window["ga"]||function(){(window["ga"].q=window["ga"].q||[]).push(arguments)},window["ga"].l=1*new Date();
    // patch to hide error from absanse of window.dataLayer
    window.dataLayer = window.dataLayer || []
    // Patch to hide errors from absense of window.criteo_q
    window.criteo_q = window.criteo_q || []
    // Add custome history queue
    window.historyQueue = window.historyQueue || []
    window.historyQueueProcessing = false
    /* eslint-enable */

    /**
     * Initialize History
     *
     * @param {*} history
     *
     * @author Palak S <palakt.bipl@gmail.com>
     */
    const initHistory = history => {
        // Store History
        history.listen(location => {
            if (window.lastLocation) {
                window.lastLocation.push(location.pathname)
            } else {
                window.lastLocation = [location.pathname]
            }
        })
        /* eslint-enable */
    }

    /**
     * Initialize Facebook Pixel
     *
     * @param {*} history
     *
     * @author Gihan S <gihanshp@gmail.com>
     */
    const initFBP = () => {
        /* eslint-disable */
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js')

        fbq("init", config.facebook.pixelId)
        fbq('track', 'PageView')
        /* eslint-enable */
    }

    // /**
    //  * Initialize Google Analytics
    //  *
    //  * @param {*} history
    //  *
    //  * @author Gihan S <gihanshp@gmail.com>
    //  */
    // const initGA = history => {
    //     asyncScriptLoad("https://www.google-analytics.com/analytics.js", "ga")

    //     window.ga("create", config.google.analytics.id, "auto", { allowLinker: true })
    //     window.ga("require", "linker")
    //     window.ga("require", "displayfeatures")
    //     window.ga("require", "ecommerce", "ecommerce.js")
    //     // send current page
    //     window.ga("send", "pageview")
    //     // listen for future changes
    //     history.listen(location => {
    //         window.historyQueue.push({
    //             type: "function",
    //             args: [location],
    //             exec: loc => {
    //                 window.ga("send", "pageview", loc.pathname + loc.search + loc.hash)
    //             },
    //             info: "GA",
    //         })
    //     })
    // }

    /**
     * Initialize Google Tag Manager
     *
     * @author Gihan S <gihanshp@gmail.com>
     */
    const initGTM = history => {
        const {
            protocol,
            hostname,
            port,
            pathname,
            search,
        } = location

        // remove port if it's common port
        let p = ":" + port
        if (port === 80 || port === 443) {
            p = ""
        }
        // Add reference to landing page to avoid "Rogue Referrals"
        // @see https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
        window.dataLayer.push({
            originalLocation: `${protocol}//${hostname}${p}${pathname}${search}`,
        })
        window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
        asyncScriptLoad(`https://www.googletagmanager.com/gtm.js?id=${config.google.tagManager.id}`, "gtm")
        // send current page
        window.dataLayer.push({
            event: "VirtualPageView",
            virtualPageUrl: location.pathname,
        })
        window.dataLayer.push("config", config.google.analytics.id, {
            // eslint-disable-next-line camelcase
            optimize_id: config.google.optimize.id,
        })
        // listen for future changes
        history.listen(location => {
            window.historyQueue.push({
                type: "function",
                args: [location],
                exec: loc => {
                    if (window.dataLayer) {
                        // send GA pageview using GTM dataLayer
                        window.dataLayer.push({
                            event: "VirtualPageView",
                            virtualPageUrl: loc.pathname + loc.search + loc.hash,
                        })
                    }
                },
                info: "GTM",
            })
        })
    }

    /**
     * Initialize Criteo tracking
     *
     * @author Gihan S <gihanshp@gmail.com>
     */
    const initCriteo = () => {
        asyncScriptLoad("https://static.criteo.net/js/ld/ld.js", "criteo-js")
    }

    // /**
    //  * Initialize Pingdom tracking
    //  *
    //  * @param {*} history
    //  *
    //  * @author Gihan S <gihanshp@gmail.com>
    //  */
    // const initPingdom = () => {
    //     /* eslint-disable no-underscore-dangle */
    // eslint-disable-next-line max-len
    //     window._prum = [["id", "5719f5d0abe53d6831e98e99"], ["mark", "firstbyte", (new Date()).getTime()]]
    //     /* eslint-enable */
    //     asyncScriptLoad("https://rum-static.pingdom.net/prum.min.js", "pingdom-js")
    // }

    /**
     * Initialize Siftscicne tracking
     *
     * @param {*} history
     *
     * @author Gihan S <gihanshp@gmail.com>
     */
    const initSiftScience = history => {
        const setUser = () => {
            const email = getAuthEmail()
            if (email && email !== "" && !email.match("@tours4fun")) {
                setUserId(email)
            }
        }

        load()
        setSessionId(config.siftscience.account, window.sessionToken)
        setUser()
        trackPageview()
        // listen for future changes
        history.listen(() => {
            // allow to settle down data fetching
            window.historyQueue.push({
                type: "function",
                args: [trackPageview],
                exec: cb => {
                    window.setTimeout(cb, 400)
                },
                info: "Sift",
            })
        })
    }

    initHistory(browserHistory)
    initGTM(browserHistory)

    initFBP(browserHistory)
    if (isT4F) {
        // initialize google analytics only for .com store
        // initGA(browserHistory)
        initSiftScience(browserHistory)
    }

    if (isProd) {
        // initialize criteo tracking
        initCriteo()

        // following are only for .com store
        // if (isT4F) {
        //     // initialize pingdom tracking for .com
        //     // initPingdom()
        // }
    }
}
