/**
 * animation.js
 * @author Tony <tonys9204@gmail.com>
 *
 * required params
 * @param {number} number start value
 * @param {number} to stop value
 *
 * option params
 * @param {number, string} duration animation during,
 * default is 300ms, suggest use milliSeconds such as 500, also can suport 500ms or 0.5s
 * @param {string} easing animation algorithm
 * algorithm is come from tween.js,
 * you can visit "http://easings.net/en" to get effect
 * @param {function} callback animation callback,
 * there're two @params(value, isEnding), value is cuurent value
 * calculated by animation algorithm
 */
import { getObjType } from "./util"
import tween from "./tween"

const animation = function(from, to, duration, easing, callback) {
    // requestAnimationFrame compatibility
    const rAF = window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function(callBack) { window.setTimeout(callBack, 1000 / 60) }

    const isFunction = function(obj) {
        return getObjType(obj) === "Function"
    }
    const isNumber = function(obj) {
        return getObjType(obj) === "Number"
    }
    const isString = function(obj) {
        return getObjType(obj) === "String"
    }

    // transfer time to ms
    const toMillisecond = function(obj) {
        if (isNumber(obj)) {
            return obj
        } else if (isString(obj)) {
            if (/\d+m?s$/.test(obj)) {
                if (/ms/.test(obj)) {
                    return 1 * obj.replace("ms", "")
                }
                return 1000 * obj.replace("s", "")
            } else if (/^\d+$/.test(obj)) {
                return +obj
            }
        }
        return false
    }

    const options = {
        duration: 300,
        easing: "Linear",
        callback() {}
    }
    // fix parameters
    const setOptions = function(obj) {
        if (isFunction(obj)) {
            options.callback = obj
        } else if (toMillisecond(obj)) {
            options.duration = toMillisecond(obj)
        } else if (isString(obj)) {
            options.easing = obj
        }
    }
    setOptions(duration)
    setOptions(easing)
    setOptions(callback)

    let start = 0
    let fnGetValue
    const during = Math.ceil(options.duration / 17)

    // current animation algorithm
    options.easing = options.easing.slice(0, 1).toUpperCase() + options.easing.slice(1)
    const arrKeyTween = options.easing.split(".")

    if (arrKeyTween.length === 1) {
        fnGetValue = tween[arrKeyTween[0]]
    } else if (arrKeyTween.length === 2) {
        fnGetValue = tween[arrKeyTween[0]] && tween[arrKeyTween[0]][arrKeyTween[1]]
    }

    // animation step
    const step = function() {
        const value = fnGetValue(start, from, to - from, during)
        start += 1
        if (start <= during) {
            options.callback(value)
            rAF(step)
        } else {
            options.callback(to, true)
        }
    }
    // start animation
    step()
}

export default animation
