import fetch from "isomorphic-fetch"
import configs, { isProd } from "../../../config/index"
import {
    offlineStore,
    offlineRetrive,
} from "../tool/util"
import { sendTiming } from "../tool/gtm"

/**
 * Set backward headers from api to mobile web server
 *
 * @param {Headers} headers Headers object
 * @param {object} ctx Context object
 */
const setBackwardHeaders = (headers, ctx) => {
    // Headers.getAll is obsolete, But only that works in my tests, nodejs 12.11.1 on mac OS 10.14.4
    // Headers.get only returns the first value
    // @see https://developer.mozilla.org/en-US/docs/Web/API/Headers/getAll
    let cookies = {}
    if (headers.getAll && typeof headers.getAll === "function") {
        cookies = headers.getAll("set-cookie")
    } else {
        cookies = headers.get("set-cookie")
    }
    ctx.set({
        "set-cookie": cookies,
    })
}

/**
 * Get Headers instance
 *
 * @param {object} headers Header strings and valus to include in HTTP Headers.
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
const getHeadersInstance = (headers = {}) => {
    const instance = new Headers()
    instance.append("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8;")
    Object.keys(headers).map(key => {
        instance.append(key, headers[key])
    })
    return instance
}

// add search params
export const handleParams = params => {
    if (!params) {
        return ""
    }

    const paramsArr = Object.entries(params)

    for (let i = 0; i < paramsArr.length; i += 1) {
        paramsArr[i] = paramsArr[i].join("=")
    }
    return paramsArr.join("&")
}

/**
 * Generate url
 */
export const getUrl = (path, params) => {
    const queryString = handleParams(params)
    let url = configs.mobileAPI.url + path
    // use for api host not suport ssl
    if (typeof window === "undefined") {
        url = url.replace(/^https:\/\//, "http://")
    }
    if (queryString) {
        url += "?" + queryString
    }
    return url
}

/**
 * Perform GET request to mobile api
 *
 * @param {string} endpoint Api endpoint
 * @param {object} params   Query params
 * @param {object} headers  Headers to include in HTTP request
 *
 * @returns Promise
 */
export const get = (endpoint, params = {}, headers = {}, ctx = null) => {
    let ssKey = false
    if (typeof params.isBrowserCache !== "undefined" && params.isBrowserCache) {
        delete params.isBrowserCache
        // created a session store key by api url
        const urlWithParams = endpoint + "_" + handleParams(params)
        ssKey = urlWithParams.replace(/[:/\-.?=&]/g, "_")
        const offlineData = offlineRetrive(ssKey, true)
        if (offlineData) {
            return Promise.resolve(offlineData)
        }
    }
    const options = {
        method: "GET",
        headers: getHeadersInstance(headers),
        credentials: "include",
    }
    // performance profiling for api GET request
    const start = new Date().getTime() // mark profiling start
    const url = getUrl(endpoint, params)
    return fetch(url, options)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            if (ctx) {
                setBackwardHeaders(response.headers, ctx)
            }
            return response.json()
        })
        // catch error response and extract the error message
        .catch(async response => {
            let error = "Unable to trace error"
            if (typeof response.text === "function") {
                error = await response.text().then(text => {
                    if (text) {
                        return text
                    }
                    return response.statusText
                })
            } else if (typeof response.toString === "function") {
                error = response.toString()
            }
            return Promise.reject(error)
        })
        .then(data => {
            const end = new Date().getTime() // mark profiling end
            if (!isProd) {
                console.log("GET OK", (end - start) + "ms", url)
            }
            sendTiming("api.OK", url.replace(configs.mobileAPI.url, ""), (end - start))
            if (ssKey) {
                offlineStore(ssKey, data, true)
            }
            return data
        })
        .catch(err => {
            const end = new Date().getTime() // mark profiling end
            if (!isProd) {
                console.error("GET ERROR", (end - start) + "ms", url)
            }
            sendTiming("api.ERROR", url.replace(configs.mobileAPI.url, ""), (end - start), err.toString())
            return Promise.reject(err)
        })
}

/**
 * get all data of multi request
 *
 * @param {endpoint} string
 * @param {items} array
 *
 * @return {Promise}
 */

export const getAll = (endpoint, items, params = {}) => {
    const promises = items.map(item => get(endpoint, { productID: item.id, ...params }))
    return Promise.all(promises).then(data => Promise.resolve(data))
}

/**
 * Perform POST request to mobile api
 *
 * @param {string}  endpoint   API request path
 * @param {object}  payload    Request body
 * @param {object}  params     Query params
 * @param {boolean} isFormData Is payload contain FormData?
 * @param {object}  headers    Headers to set in post request
 *
 * @return Promise
 */
export const post = (
    endpoint,
    params = {},
    payload = {},
    isFormData = false,
    headers = {},
) => {
    let options
    // If formadata have a file then use first header
    if (isFormData) {
        options = {
            method: "POST",
            body: payload,
            credentials: "include",
            contentType: false,
            processData: false,
        }
    } else {
        options = {
            method: "POST",
            body: handleParams(payload),
            headers: getHeadersInstance(headers),
            credentials: "include",
        }
    }

    const url = getUrl(endpoint, params)
    // performance profiling for api POST request
    const start = new Date().getTime() // mark profiling start
    return fetch(url, options)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            return response.json()
        })
        // catch error response and extract the error message
        .catch(async response => {
            let error = "Unable to trace error"
            if (typeof response.text === "function") {
                error = await response.text().then(text => {
                    if (text) {
                        return text
                    }
                    return response.statusText
                })
            } else if (typeof response.toString === "function") {
                error = response.toString()
            }
            return Promise.reject(error)
        })
        .then(data => {
            const end = new Date().getTime() // mark profiling end
            if (!isProd) {
                console.log("POST OK", (end - start) + "ms", url)
            }
            sendTiming("api.OK", url.replace(configs.mobileAPI.url, ""), (end - start))
            return data
        })
        .catch(err => {
            const end = new Date().getTime() // mark profiling end
            if (!isProd) {
                console.error("POST ERROR", (end - start) + "ms", url)
            }
            sendTiming("api.ERROR", url.replace(configs.mobileAPI.url, ""), (end - start), err.toString())
            return Promise.reject(err)
        })
}

/**
 * Get forward headers from koa context
 *
 * @param {object} context Context object from koa
 *
 * @author Gihan S <gihanshp@gmail.com>
 */
export const getForwardHeadersFromContext = context => {
    const headers = {}
    if (context.header) {
        // collect cookies
        if (context.header.cookie) {
            headers.cookie = context.header.cookie
        }
    }
    return headers
}
